import styled from '@emotion/styled';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const ResultsContent = styled('div')(() => ({
  position: 'relative',
  zIndex: 1,
  marginBlock: '40px',
  padding: '50px 20px',
  backgroundColor: '#fff',
  borderRadius: '10px',
  border: '1px solid #EAEAEA',
  textAlign: 'center',
  color: '#848484',
  fontSize: '16px',
  lineHeight: 1.6,
}));

const ResultsContentH2 = styled('h2')(() => ({
  fontSize: '40px',
  fontWeight: '700',
  lineHeight: '1.1',
  marginBottom: '10px',
  color: '#848484',
  '@media (max-width: 1199px)': {
    fontSize: '32px',
  },
}));

const ResultsContentP = styled(Link)(() => ({
  textDecoration: 'underline',
  color: '#00D6BC',
  ':hover': {
    textDecoration: 'none',
  },
}));

export const ResultsNotFound = ({
  trans,
  lang,
}: {
  trans: any;
  lang: string;
}) => {
  return (
    <ResultsContent>
      <ResultsContentH2>{parse(trans.sorry_msg_show_1)}</ResultsContentH2>
      <p>
        {parse(trans.sorry_msg_show_2)}{' '}
        <ResultsContentP to={`/${lang}`}>{trans.home}.</ResultsContentP>
      </p>
    </ResultsContent>
  );
};
