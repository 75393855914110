import { useLocation, useNavigate } from 'react-router-dom';
import {
  MainHead,
  MainHeadH1,
  MainHeadP,
} from '../../../../common/styles/CommonStyles';
import { useContext, useState } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { CarDetailField } from './CarDetailsField';
import { TitleDivider } from './TitleDivider';
import MetaTag from '../../../../common/meta/MetaTag';
import {
  StyledButton,
  StyledCommonDiv,
  StyledDiv1,
  StyledDiv2,
  StyledDivContainer,
  StyledH2,
  StyledH4,
  StyledSlider,
  StyledTextField,
} from './SellYouCarSecondPageStyledComponents';
import { usePriceValuation } from '../../../UsedCars/hooks';
import PageLoader from '../../../Other/PageLoader/PageLoaderComponent';

export const SellYourCarSecondPage = () => {
  const { trans, lang } = useContext(AppContext);
  const location = useLocation();
  const vinData = location.state;
  console.log(vinData);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    mileage: vinData?.mileage || 0,
  });

  const { mutateAsync: priceValuation, isLoading: isPriceValuationLoading } =
    usePriceValuation();

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setFormData(prevData => ({
      ...prevData,
      mileage: Array.isArray(newValue) ? newValue[0] * 1000 : newValue * 1000,
    }));
  };

  const handleSliderInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, '');
    setFormData(prevData => ({
      ...prevData,
      mileage: numericValue === '' ? '' : Number(numericValue),
    }));
  };

  const handleConfirm = async () => {
    try {
      const data = await priceValuation({
        market: 'QA',
        vin: vinData?.vin,
        km: formData.mileage,
      });
      navigate(`/${lang}/sell-your-car/step-three`, {
        state: {
          ...location.state,
          formData: formData,
          priceValuation: data,
        },
      });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <MetaTag href={`/${lang}/sell-your-car/step-two`} metaKey="sellYourCar" />
      <main id="main" className="sell_your_car_data">
        <MainHead className="sell-car-head ">
          <MainHeadH1 className="sell-car-h1">{trans.sell_car}</MainHeadH1>
          <MainHeadP className="sell-car-p">
            {trans.sell_car} {trans.with} ONE AUTOCAR
          </MainHeadP>
        </MainHead>
        <div className="container">
          {isPriceValuationLoading && (
            <PageLoader loadSectionOnly style={{ height: '100%' }} />
          )}
          <StyledDivContainer>
            <div className="sell-car-first-page-box">
              <StyledH2>{trans.yourCarDetails}</StyledH2>
              <TitleDivider header={trans.mainDetails} />
              <StyledCommonDiv>
                <CarDetailField
                  header={trans.carBrand}
                  value={vinData?.model?.brand?.name || '---'}
                />
                <CarDetailField
                  header={trans.model}
                  value={vinData?.model?.name || '---'}
                />
              </StyledCommonDiv>
              <StyledCommonDiv>
                <CarDetailField
                  header={trans.type}
                  value={vinData?.type?.name || '---'}
                />
                <CarDetailField
                  header={trans.year}
                  value={vinData?.year || '---'}
                />
              </StyledCommonDiv>
              <TitleDivider header={trans.carSpecification} />
              <StyledCommonDiv>
                <CarDetailField
                  header={trans.doors}
                  value={vinData?.doors || '---'}
                />
                <CarDetailField
                  header={trans.car_condition}
                  value={vinData?.condition || '---'}
                />
              </StyledCommonDiv>
              <StyledCommonDiv>
                <CarDetailField
                  header={trans.engine_type}
                  value={vinData?.engine_type?.name || '---'}
                />
                <CarDetailField
                  header={trans.engine_size}
                  value={vinData?.engine_size?.name || '---'}
                />
              </StyledCommonDiv>
              <StyledCommonDiv>
                <CarDetailField
                  header={trans.cylinders}
                  value={vinData?.cylinder?.name || '---'}
                />
                <CarDetailField
                  header={trans.transmission}
                  value={vinData?.transmission?.name || '---'}
                />
              </StyledCommonDiv>
              <StyledCommonDiv>
                <CarDetailField
                  header={trans.battery_capacity}
                  value={vinData?.battery_capacity?.name || '---'}
                />
                <CarDetailField
                  header={trans.battery_range}
                  value={vinData?.battery_range?.name || '---'}
                />
              </StyledCommonDiv>
            </div>
            <div className="sell-car-first-page-box">
              <StyledH2>{trans.yourCarMilage}</StyledH2>
              <StyledSlider
                marks={[
                  { value: 10, label: '10,000' },
                  { value: 40, label: '40,000' },
                  { value: 80, label: '80,000' },
                  { value: 120, label: '120,000' },
                  { value: 160, label: '160,000' },
                  { value: 200, label: '200,000' },
                ]}
                min={0}
                max={200}
                value={formData.mileage / 1000}
                onChange={handleSliderChange}
                defaultValue={0}
                step={10}
              />
              <StyledDiv1>
                <StyledH4>{trans.carMileage}</StyledH4>
                <StyledDiv2>
                  <StyledTextField
                    value={`${formData.mileage} km`}
                    onChange={handleSliderInputChange}
                    inputProps={{
                      step: 10,
                      min: 10,
                      max: 200,
                      type: 'text',
                    }}
                  />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={handleConfirm}
                    disabled={isPriceValuationLoading}
                  >
                    {trans.sell_confirm}
                  </StyledButton>
                </StyledDiv2>
              </StyledDiv1>
            </div>
          </StyledDivContainer>
        </div>
      </main>
    </>
  );
};
