import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import LazyImage from '../../Other/LazyImageComponent';
import { useUserDeleteFavorite } from '../../../common/hooks/user.hook';
import {
  AutoImgBox,
  CarInfoColumn,
  CarInfoColumnBtn,
  CarInfoColumnImgHolder,
  CarInfoColumnLogoImg,
  CarInfoColumnPriceHolder,
  CarInfoColumnPriceHolderPrice,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
} from '../../../common/styles/CommonStyles';
import {
  CarInfoColumnDl,
  CarInfoColumnDlDd,
  CarInfoColumnDlDt,
} from '../../UsedCars/components/UsedCarStyles';
import './Card-styles.css';

const Card = ({ favourite }: any) => {
  const { trans, lang, darkImageLoadingPlaceholder } = useContext(AppContext);

  const { mutateAsync: deleteFavorite } = useUserDeleteFavorite();

  const favorite = {
    id: Number(favourite.id),
    type: String(favourite.type),
  };

  const removeFavorite = async () => {
    await deleteFavorite(favorite);
  };

  return (
    <CarInfoColumn className="wishlist-section">
      <CarInfoColumnImgHolder>
        <AutoImgBox>
          <div onClick={removeFavorite}>
            <a href="#" className="wishlist-icon">
              <i className="fa fa-trash-o"></i>
            </a>
          </div>
          <img
            src={
              favourite && favourite.images
                ? favourite.images[0]
                : darkImageLoadingPlaceholder
            }
            alt="img"
          />
        </AutoImgBox>
      </CarInfoColumnImgHolder>
      <CarInfoColumnTextHolder>
        <CarInfoColumnTextBox>
          <CarInfoColumnLogoImg>
            <LazyImage
              src={
                favourite.model?.brand?.original_logo ||
                favourite.bike_model?.brand?.original_logo
              }
              defaultSrc={darkImageLoadingPlaceholder}
              class="lazy"
              alt="img"
            />
          </CarInfoColumnLogoImg>
          <CarInfoColumnTitleCar>
            {favourite.model?.brand.name ?? ''}
          </CarInfoColumnTitleCar>
          <CarInfoColumnTitleCategory>
            {favourite?.model.name ?? ''}
          </CarInfoColumnTitleCategory>
          <CarInfoColumnDl>
            <CarInfoColumnDlDt>{trans.year}:</CarInfoColumnDlDt>
            <CarInfoColumnDlDd>{favourite?.year ?? ''}</CarInfoColumnDlDd>
            <CarInfoColumnDlDt>{trans.mileage}:</CarInfoColumnDlDt>
            <CarInfoColumnDlDd>
              {favourite?.mileage ?? ''} KMs
            </CarInfoColumnDlDd>
            <CarInfoColumnDlDt>{trans.seller}:</CarInfoColumnDlDt>
            <CarInfoColumnDlDd>
              {favourite?.dealer_id ? favourite?.dealer?.name : trans.private}
            </CarInfoColumnDlDd>
          </CarInfoColumnDl>
        </CarInfoColumnTextBox>
        <CarInfoColumnPriceHolder>
          <CarInfoColumnPriceHolderPrice>
            {trans.qar} {favourite?.price}
          </CarInfoColumnPriceHolderPrice>
          <CarInfoColumnBtn
            to={`/${lang}/used-cars/${favourite.model?.brand?.slug}/${favourite.model?.slug}/${favourite.year}/${favourite.id}`}
            className="btn"
          >
            {trans.view_car}
          </CarInfoColumnBtn>
        </CarInfoColumnPriceHolder>
      </CarInfoColumnTextHolder>
    </CarInfoColumn>
  );
};

export default Card;
