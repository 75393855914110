import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ListingDetailsImageGallery from './ListingDetailsImageGallery/ListingDetailsImageGallery';
import { AppContext } from '../../../contexts/AppContext';
import ListingDetailsFeaturesList from './ListingDetailsFeaturesList/ListingDetailsFeaturesList';
import Banner from '../../Banners/BannerComponent';
import SignIn from '../../User/SignIn/SignInComponent';
import { IUsedCar } from '../../../types/cars/used_car';
import OptionalFeatureCard from './OptionalFeatureCard';
import { BikeListingDetailsSkeleton } from '../../UsedBikes/components/BikeDetailsSkeletons';
import '../../../styles/css/common.css';
import { Row, SliderSectionStyle } from '../../../common/styles/CommonStyles';
import './ListingDetailsFeaturesList/ListingDetailsFeaturesList-styles.css';
import { useUsedCarsPageView } from '../../UsedCars/hooks';
import { useInView } from 'react-intersection-observer';

type CarListingDetailsProps = {
  car: IUsedCar;
  onChatStart: () => void;
  signInModalInfo: {
    isVisible: boolean;
    setVisibility: (isVisible: boolean) => void;
  };
  handleOpen: () => void;
  setImageSrc: (imageSrc: string) => void;
  getUsedDealClick: (car: IUsedCar) => void;
  isLoading: boolean;
  responsiveFeatured: any;
};

const CarListingDetails = ({
  car,
  onChatStart,
  signInModalInfo,
  setImageSrc,
  handleOpen,
  getUsedDealClick,
  isLoading,
  responsiveFeatured,
}: CarListingDetailsProps) => {
  const { trans, darkImageLoadingPlaceholder } = useContext(AppContext);
  const location = useLocation();
  const [coverImageSrc, setCoverImageSrc] = useState(
    car.images && car.images[0] && car.images[0]
      ? car.images[0]
      : darkImageLoadingPlaceholder,
  );

  const setActive = (currentElement: any) => {
    document
      .getElementsByClassName('slick-current')[0]
      ?.classList.remove('slick-current');
    currentElement.target.parentNode.parentNode.classList.add('slick-current');
    setCoverImageSrc(currentElement.target.src);
    setImageSrc(currentElement.target.src);
  };

  const { mutateAsync: usedCarsPageView } = useUsedCarsPageView();

  const { ref } = useInView({
    threshold: 0.01,
    onChange(inView) {
      if (inView) {
        usedCarsPageView(car.id);
      }
    },
    triggerOnce: true,
  });

  if (isLoading) {
    return (
      <BikeListingDetailsSkeleton
        trans={trans}
        responsiveFeatured={responsiveFeatured}
      />
    );
  }

  return (
    <main id="main" ref={ref}>
      <div className="container usedetailcar">
        <Row>
          <SliderSectionStyle className="get-deals">
            <div className="used-detail slider-holder get-deals">
              <ListingDetailsImageGallery
                images={car.images}
                coverImage={coverImageSrc}
                onImageClick={setActive}
                handleOpen={handleOpen}
                setImageSrc={setImageSrc}
                isLoading={isLoading}
                car={car}
              />

              {car.optional_features && car.optional_features.length > 0 && (
                <div className="features-holder">
                  <h2 className="comment-header-heading">{trans.features}</h2>
                  <br />
                  <div className="optional-features-cards-container">
                    {car.optional_features.map(feature => (
                      <OptionalFeatureCard name={feature.name} />
                    ))}
                  </div>
                </div>
              )}

              {car.description && (
                <div className="description-holder">
                  <h2 className="comment-header-heading">
                    {trans.description}
                  </h2>
                  <br />
                  <div className="comment-box">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: car.description,
                      }}
                    ></p>
                  </div>
                </div>
              )}
            </div>

            <ListingDetailsFeaturesList
              car={car}
              onChatNow={onChatStart}
              getDealClick={getUsedDealClick}
              isLoading={isLoading}
            />
          </SliderSectionStyle>
        </Row>
      </div>
      <div className="container ">
        <Banner section_name="C3" className="default-image-banner C3-margin" />
      </div>
      {signInModalInfo.isVisible && (
        <SignIn
          toggleSignInDialog={signInModalInfo.setVisibility}
          location={location.pathname}
        />
      )}
    </main>
  );
};

export default CarListingDetails;
