import { IUsedBike } from '../../../types/bikes';
import { formatPrice } from '../../../helpers/formatHelper';
import { IUsedBikeResponse } from '../../UsedBikes';
import { DealUsedBikeCardSkeleton } from './DealerCardSkeleton';
import { ModelCardImage } from '../../NewCars/components/NewCarDetailComponents/ModelCardImage';
import {
  AutoImgBox,
  BrowseBrandsCarInfoColumn,
  CarInfoColumnBtn,
  CarInfoColumnImgHolder,
  CarInfoColumnLogoImg,
  CarInfoColumnPriceHolder,
  CarInfoColumnPriceHolderPrice,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
  MainDealerIcon,
  SponsoredBadge,
} from '../../../common/styles/CommonStyles';
import { BrowseBrands } from './DealerStyledComponent';
import {
  CarInfoColumnDl,
  CarInfoColumnDlDd,
  CarInfoColumnDlDt,
} from '../../UsedCars/components/UsedCarStyles';
import { DealerCardLogo } from './DealerCardLogo';

export const UsedBikeBrandCardShowroomComponent = ({
  dealerUsedCars,
  trans,
  darkImageLoadingPlaceholder,
  lang,
  isLoading,
}: {
  dealerUsedCars: IUsedBikeResponse | undefined;
  trans: any;
  darkImageLoadingPlaceholder: string;
  lang: string;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return <DealUsedBikeCardSkeleton />;
  }

  return (
    <BrowseBrands>
      {dealerUsedCars?.data?.map((car: IUsedBike) => (
        <BrowseBrandsCarInfoColumn key={car.id}>
          <CarInfoColumnImgHolder>
            <AutoImgBox>
              {car?.is_featured === true && (
                <SponsoredBadge className="badge">
                  {trans.featured}
                </SponsoredBadge>
              )}
              {car.bike_dealer?.is_active === true && (
                <MainDealerIcon
                  style={{
                    background: `url(/images/main-dealer.png) no-repeat center`,
                  }}
                >
                  {trans.main_dealer}
                </MainDealerIcon>
              )}
              <ModelCardImage
                src={car.images[0]}
                defaultSrc={darkImageLoadingPlaceholder}
                alt={`${car.bike_model?.slug}-image`}
              />
            </AutoImgBox>
          </CarInfoColumnImgHolder>
          <CarInfoColumnTextHolder>
            <CarInfoColumnTextBox>
              <CarInfoColumnLogoImg>
                <DealerCardLogo
                  src={car.bike_model?.brand?.original_logo}
                  alt={`${car.bike_model?.slug}-logo`}
                />
              </CarInfoColumnLogoImg>
              <CarInfoColumnTitleCar>
                {car.bike_model?.brand?.name}
              </CarInfoColumnTitleCar>
              <CarInfoColumnTitleCategory>
                {car.bike_model?.name}
              </CarInfoColumnTitleCategory>
              <CarInfoColumnDl>
                <CarInfoColumnDlDt>{trans.year}:</CarInfoColumnDlDt>
                <CarInfoColumnDlDd>{car.year}</CarInfoColumnDlDd>
                <CarInfoColumnDlDt>{trans.km_driven}:</CarInfoColumnDlDt>
                <CarInfoColumnDlDd>
                  {car.mileage} {trans.km}
                </CarInfoColumnDlDd>
                <CarInfoColumnDlDt>{trans.dealer}:</CarInfoColumnDlDt>
                <CarInfoColumnDlDd>{car?.bike_dealer?.name}</CarInfoColumnDlDd>
              </CarInfoColumnDl>
            </CarInfoColumnTextBox>
            <CarInfoColumnPriceHolder>
              <CarInfoColumnPriceHolderPrice>
                <span className="qar">{trans.qar}</span>{' '}
                <span>{formatPrice(car.price)}</span>
              </CarInfoColumnPriceHolderPrice>
              <CarInfoColumnBtn
                to={`/${lang}/used-bikes/${car.bike_model?.brand?.slug}/${car.bike_model?.slug}/${car.year}/${car.id}`}
                className="btn"
              >
                {trans.view_car}
              </CarInfoColumnBtn>
            </CarInfoColumnPriceHolder>
          </CarInfoColumnTextHolder>
        </BrowseBrandsCarInfoColumn>
      ))}
    </BrowseBrands>
  );
};
