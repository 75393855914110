import { useContext } from 'react';
import Carousel from 'react-multi-carousel';
import { AppContext } from '../../../contexts/AppContext';
import CarGridCard from './CarGridCard/CarGridCard';
import { IUsedCar } from '../../../types/cars/used_car';
import { CarGridCarouselSkeleton } from '../../UsedCars/UsedCarsSkeletons';

type FeaturedCarsCarouselProps = {
  featuredCars: Array<IUsedCar>;
  favorite: Array<number>;
  onFavouriteClick: (e: any, carId: number) => void;
  isLoading: boolean;
};

const FeaturedCarsCarousel = ({
  favorite,
  featuredCars,
  isLoading,
  onFavouriteClick,
}: FeaturedCarsCarouselProps) => {
  const { lang } = useContext(AppContext);

  const rtl = lang == 'ar' ? true : false;

  const responsiveFeatured = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const generateCarRedirectionLink = (usedCar: IUsedCar) =>
    `/${lang}/used-cars/${usedCar.model?.brand.slug}/${usedCar.model?.slug}/${usedCar.year}/${usedCar.id}`;

  if (isLoading && !featuredCars) {
    return (
      <CarGridCarouselSkeleton responsive={responsiveFeatured} rtl={rtl} />
    );
  }

  return (
    <Carousel
      autoPlay
      responsive={responsiveFeatured}
      swipeable={true}
      draggable={true}
      infinite={true}
      rtl={rtl}
      customLeftArrow={<div className="swiper-button-prev"></div>}
      customRightArrow={<div className="swiper-button-next"></div>}
    >
      {featuredCars.map((featuredCar: IUsedCar) => (
        <CarGridCard
          key={featuredCar.id}
          car={featuredCar}
          isFavorite={
            favorite && favorite.find(f => f === featuredCar.id) !== undefined
          }
          onFavoriteClick={onFavouriteClick}
          redirectionLink={generateCarRedirectionLink(featuredCar)}
          isLoading={isLoading}
        />
      ))}
    </Carousel>
  );
};

export default FeaturedCarsCarousel;
