import { useContext, useMemo, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import {
  useBrandModelsWithDeals,
  useBrandWithDeals,
  useModelDeals,
  useModelPageView,
} from '../hooks';
import {
  FeaturedDetails,
  Header,
  OtherModels,
  SliderSection,
} from './NewCarDetailComponents';

import {
  useBrandId,
  useFilteredNewModels,
  useGetDealClick,
  useSelectedModel,
  useSetActive,
} from './NewCarDetailComponents/hooks/useNewCarDetail';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import NewCarInquiryForm from './NewCarInquiryForm';
import { NewCarsDetailsPageSkeleton } from './NewCarsCardsSkeletons';
import { Row, SliderSectionStyle } from '../../../common/styles/CommonStyles';
import { useInView } from 'react-intersection-observer';

const NewCarDetail = () => {
  const { lang, trans, darkImageLoadingPlaceholder } = useContext(AppContext);
  const params = useParams();
  const brandSlug = params.brand;
  const modelSlug = params.model;
  const navigate = useNavigate();
  const [showDealForm, setShowDealForm] = useState(false);

  const [coverImageSrc, setCoverImageSrc] = useState<string>(
    darkImageLoadingPlaceholder,
  );

  const [images, setImages] = useState<string[]>(
    Array(5).fill(darkImageLoadingPlaceholder),
  );
  const { data: brands, isLoading: isLoadingBrands } = useBrandWithDeals();

  const { brandId } = useBrandId(brands, brandSlug);

  const { data: brandModelsWithDealsData, isLoading: isLoadingBrandModels } =
    useBrandModelsWithDeals({ brandId });

  const { selectedModel } = useSelectedModel(
    brandModelsWithDealsData,
    modelSlug,
  );

  const { filteredNewModels } = useFilteredNewModels(
    brandModelsWithDealsData,
    selectedModel,
  );

  const { data: modelDealsData, isLoading: isLoadingModelDeals } =
    useModelDeals({ modelId: selectedModel?.id || 0 });

  const selectedDeal = useMemo(() => modelDealsData?.[0], [modelDealsData]);
  const isLoading =
    isLoadingModelDeals || isLoadingBrands || isLoadingBrandModels;

  useEffect(() => {
    if (!brandId && !isLoading) {
      navigate(`/${lang}/new-cars`);
      return;
    }
  }, [brandId, isLoading]);

  const { mutateAsync: modelView } = useModelPageView();

  const { ref } = useInView({
    threshold: 0.01,
    onChange(inView) {
      if (inView) {
        modelView(Number(selectedModel?.id));
      }
    },
    triggerOnce: true,
  });

  useEffect(() => {
    if (selectedModel && selectedModel.id) {
      setCoverImageSrc(selectedModel.image?.[0]);
    }
  }, [selectedModel]);

  const { setActive } = useSetActive(setCoverImageSrc);

  const closeDealForm = () => {
    setShowDealForm(false);
  };

  const { getDealClick } = useGetDealClick(setShowDealForm);

  const handleTestDriveClick = (testDriveUrl: string) => {
    window.open(testDriveUrl, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    if (selectedModel) {
      setImages(selectedModel.image);
    }
  }, [selectedModel]);

  if (isLoading) {
    return (
      <>
        <MetaTag
          metaKey="newCarsBrandModel"
          href={`/${lang}/new-cars/${params.brand}/${params.model}`}
          params={{
            '%MODEL%': selectedModel?.name,
            '%BRAND%': selectedModel?.brand.name,
          }}
        />
        <NewCarsDetailsPageSkeleton trans={trans} />
      </>
    );
  }

  return (
    <>
      <MetaTag
        metaKey="newCarsBrandModel"
        href={`/${lang}/new-cars/${params.brand}/${params.model}`}
        params={{
          '%MODEL%': selectedModel?.name,
          '%BRAND%': selectedModel?.brand.name,
        }}
      />
      <main id="main" ref={ref}>
        {selectedModel && selectedModel.id && (
          <div className="container details">
            <Row className="d-flex">
              <Header selectedModel={selectedModel} isLoading={isLoading} />
              <SliderSectionStyle className="new-car-detail-slider-section">
                <SliderSection
                  images={images}
                  coverImageSrc={coverImageSrc}
                  setActive={setActive}
                  darkImageLoadingPlaceholder={darkImageLoadingPlaceholder}
                  style={{ paddingTop: '35px' }}
                  isLoading={isLoading}
                  selectedModel={selectedModel}
                />
                <FeaturedDetails
                  handleTestDriveClick={handleTestDriveClick}
                  selectedModel={selectedModel}
                  deal={selectedDeal}
                  getDealClick={getDealClick}
                  isLoading={isLoading}
                />
              </SliderSectionStyle>
              <OtherModels
                filteredNewModels={filteredNewModels}
                darkImageLoadingPlaceholder={darkImageLoadingPlaceholder}
                isLoading={isLoading}
              />
            </Row>
          </div>
        )}
      </main>
      {showDealForm && selectedDeal && (
        <NewCarInquiryForm
          carId={selectedDeal.id}
          closeDealForm={closeDealForm}
        />
      )}
    </>
  );
};

export default NewCarDetail;
