import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useParams } from 'react-router-dom';
import { useDealers } from '../hooks/useDealersApis';
import { IDealer } from '../../../types/resources';
import { useBikeBrands } from '../../../common/hooks/bikes';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import BrandCard from '../../Listings/brands/components/BrandCard';
import { IBrand } from '../../../types/resources/cars';
import NewBikeDealerImageShowroom from './NewBikeDealerImageShowroom';
import { NewBikeDealerShowroomSkeleton } from './DealerCardSkeleton';
import {
  ColLg12,
  FeaturedDetailsPage,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  MainHeadP,
  Row,
} from '../../../common/styles/CommonStyles';
import { BrowseBrands, CarsFormHolder } from './DealerStyledComponent';
import { ResultsNotFound } from '../../common';

const ShowroomNewBikesComponent = () => {
  const { lang, trans, darkImageLoadingPlaceholder } = useContext(AppContext);
  const params = useParams();
  const dealerSlug = params.name;

  const { data: dealersData } = useDealers();

  const selectedDealer = dealersData?.find(
    (dealer: IDealer) => dealer.slug === dealerSlug,
  );

  const dealerId = selectedDealer?.id;

  const { data: dealerBrandsData, isLoading } = useBikeBrands({
    params: {
      type: 'new',
      dealer: dealerId,
    },
    enabled: !!dealerId,
  });

  if (isLoading || !selectedDealer)
    return (
      <>
        <MetaTag
          metaKey="dealerNewBikes"
          href={`/${lang}/dealer/${selectedDealer?.slug}/new-bikes`}
          params={{
            '%NAME%': selectedDealer?.name,
          }}
        />
        <NewBikeDealerShowroomSkeleton trans={trans} />
      </>
    );

  return (
    <>
      <MetaTag
        metaKey="dealerNewBikes"
        href={`/${lang}/dealer/${selectedDealer?.slug}/new-bikes`}
        params={{
          '%NAME%': selectedDealer?.name,
        }}
      />
      <main id="main" className="online_showroom used_cars">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH1>{trans.online_showroom}</MainHeadH1>
                <MainHeadP>{trans.new_bikes}</MainHeadP>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        <FeaturedDetailsPage className="container">
          <Row className="featured-logo">
            <ColLg12>
              {selectedDealer?.logo && (
                <NewBikeDealerImageShowroom
                  src={selectedDealer?.logo}
                  defaultSrc={darkImageLoadingPlaceholder}
                  alt={`${selectedDealer?.slug}-logo`}
                  class="lazy"
                />
              )}
            </ColLg12>
          </Row>
          <Row>
            <ColLg12>
              <CarsFormHolder className="cars-form-holder">
                <BrowseBrands className="dealers">
                  {dealerBrandsData?.map((brand: IBrand) => (
                    <div
                      key={brand.id}
                      style={{ width: '150px', height: '150px', margin: 0 }}
                    >
                      <BrandCard
                        key={brand.id}
                        redirectionUrl={`/${lang}/new-bikes/${brand.slug}`}
                        brand={brand}
                      />
                    </div>
                  ))}
                </BrowseBrands>
              </CarsFormHolder>
            </ColLg12>
          </Row>
          {dealerBrandsData?.length === 0 && (
            <ResultsNotFound lang={lang} trans={trans} />
          )}
        </FeaturedDetailsPage>
      </main>
    </>
  );
};

export default ShowroomNewBikesComponent;
