import { useContext, useEffect, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { useGetRentalCars, useFeaturedRentals } from '../hooks';
import RentalsAdvancedFilter from '../components/RentalsAdvancedFilter';
import { IRentalCar } from '../../../types/cars/rental_car';
import RentalsFilter, {
  TRenatlCarsFilter,
  TRentalFilters,
} from '../components/RentalsFilter';
import { TOrder, TSortBy, useSortCars } from '../../UsedCars/hooks/useUsedCars';
import { RentalCarBodyComponent } from '../components/RentalCarBodyComponent';

const PAGE_SIZE = 16;
const AD_INTERVAL = 4;

const RentalCarsLayout = () => {
  const responsiveFeatured = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };
  // #region States
  const { lang, trans } = useContext(AppContext);
  const [searchParams] = useSearchParams();

  const [isGrid, setIsGrid] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0,
  );
  const [pagesCount, setPagesCount] = useState(0);

  const [rentedCarsWithBanners, setRentedCarsWithBanners] = useState<
    Array<IRentalCar | string>
  >([]);

  const [rentalsFilter, setRentalsFilter] = useState<TRentalFilters>({
    brandIds: searchParams.get('brand')
      ? [Number(searchParams.get('brand'))]
      : [],
    modelIds: searchParams.get('model')
      ? [Number(searchParams.get('model'))]
      : [],
    types: [],
    transmissions: -1,
    companies: [],
    largeBaggageSizes: [],
    smallBaggageSizes: [],
    seats: [],
    maxPrice: -1,
    minPrice: -1,
  });

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const [usedCarQueryParams, setUsedCarQueryParams] = useState<
    TRenatlCarsFilter | undefined
  >({
    brandIds: searchParams.get('brand')
      ? [Number(searchParams.get('brand'))]
      : undefined,
    modelIds: searchParams.get('model')
      ? [Number(searchParams.get('model'))]
      : undefined,
  });

  const [noResultsFound, setNoResultsFound] = useState(false);
  const [sort, setSort] = useState<{ sortBy: TSortBy; order: TOrder }>();

  // #endregion

  // Original State
  const { data: rentedCarsData, isLoading: showPageLoader } = useGetRentalCars({
    query: {
      ...usedCarQueryParams,
      isFeatured: false,
      page: currentPage + 1 || 1,
      perPage: PAGE_SIZE,
      sortBy: sort?.sortBy,
      sortDirection: sort?.order,
    },
    enabled: true,
    // Required
  });

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'SET_FILTER': {
        if (action.payload === trans.featured) {
          return {
            isFeatured: true,
          };
        }

        return { isFeatured: false };
      }
      default:
        return filters;
    }
  };

  const [filters] = useReducer(reducer, {
    isFeatured: true,
  });

  const { data: featuredRentals, isLoading: carouselLoader } =
    useFeaturedRentals({
      searchQueryParams: {
        isFeatured: filters.isFeatured,
      },
    });

  useEffect(() => {
    if (rentedCarsData) {
      const { data, meta } = rentedCarsData;
      const rentedCarsWithBanners: Array<IRentalCar | string> = [];

      let bannerCount = 0;

      data.forEach((car: IRentalCar, index: number) => {
        if (
          index > 0 &&
          index % AD_INTERVAL === 0 &&
          index < data.length - 1 &&
          index !== 0
        ) {
          if (isGrid) {
            if (bannerCount < 1) {
              rentedCarsWithBanners.push('banner');
              bannerCount++;
            }
          } else {
            rentedCarsWithBanners.push('banner');
          }
        }
        rentedCarsWithBanners.push(car);
      });

      setRentedCarsWithBanners(rentedCarsWithBanners);
      setPagesCount(meta.lastPage);
      setNoResultsFound(data.length === 0);
    }
  }, [rentedCarsData, isGrid, AD_INTERVAL]);

  const isLoading = showPageLoader || carouselLoader;

  const handleReset = () => {
    setRentalsFilter({
      brandIds: [],
      modelIds: [],
      types: [],
      companies: [],
      largeBaggageSizes: [],
      seats: [],
      smallBaggageSizes: [],
      transmissions: -1,
      maxPrice: -1,
      minPrice: -1,
    });
    setUsedCarQueryParams(undefined);
  };

  const { sortCars } = useSortCars(setSort);

  const handleSearch = () => {
    const filteredFilters = Object.keys(rentalsFilter).reduce((acc, key) => {
      if (rentalsFilter[key as keyof TRentalFilters] !== -1) {
        acc[key] = rentalsFilter[key as keyof TRentalFilters];
      }

      return acc;
    }, {});
    setUsedCarQueryParams(filteredFilters);
    setShowAdvancedSearch(false);
  };

  const toggleAdvancedSearch = (show: boolean) => {
    setShowAdvancedSearch(show);
  };

  return (
    <>
      <main id="main" className="container usedcars">
        <section>
          <RentalsFilter
            filters={rentalsFilter}
            setFilters={setRentalsFilter}
            handleOnSearch={handleSearch}
            handleReset={handleReset}
            setShowAdvancedFilter={setShowAdvancedSearch}
          />
        </section>
        <RentalCarBodyComponent
          PAGE_SIZE={PAGE_SIZE}
          carouselLoader={carouselLoader}
          currentPage={currentPage}
          featuredRentals={featuredRentals?.data}
          isGrid={isGrid}
          isLoading={isLoading}
          lang={lang}
          noResultsFound={noResultsFound}
          pagesCount={pagesCount}
          rentedCarsWithBanners={rentedCarsWithBanners}
          responsiveFeatured={responsiveFeatured}
          setCurrentPage={setCurrentPage}
          setIsGrid={setIsGrid}
          sortCars={sortCars}
          trans={trans}
        />
      </main>
      {/* ADVANCED FILTER */}
      {showAdvancedSearch && (
        <RentalsAdvancedFilter
          showAdvancedSearch={showAdvancedSearch}
          toggleAdvancedSearch={toggleAdvancedSearch}
          handleSearch={handleSearch}
          handleClear={handleReset}
          filters={rentalsFilter}
          setFilters={setRentalsFilter}
        />
      )}
    </>
  );
};

export default RentalCarsLayout;
