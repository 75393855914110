import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import LazyImage from '../../Other/LazyImageComponent';
import './signin-styles.css';

export default function SignIn(props: any) {
  const { toggleSignInDialog, sellCar, location } = props;
  const { lang, trans } = useContext(AppContext);
  const previousUrl = sellCar ? 'sell-your-car' : window.location.pathname;
  const [currentLoc, setCurrentLocation] = useState(null);
  useEffect(() => {
    if (currentLoc) {
      if (location !== currentLoc) {
        toggleSignInDialog(false);
      }
    } else {
      setCurrentLocation(location);
    }
  }, [location]);

  return (
    <>
      <div className="modal fade modal-center advance-search-modal loginpopup show">
        <div className="modal-dialog modal-dialog-signup modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  onClick={() => toggleSignInDialog(false)}
                >
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="login-signup">
                <p>{trans.sign_in_first}</p>
                <div className="login-img">
                  <LazyImage
                    src="/images/login-pop-icon.webp"
                    alt="login icon"
                    defaultSrc=""
                    style={{
                      height: '151px',
                    }}
                  />
                </div>
                <Link
                  state={{ previousUrl, sellCar }}
                  to={`/${lang}/login`}
                  className="btn btn-primary"
                  onClick={() => toggleSignInDialog(false)}
                >
                  {trans.sign_in}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
