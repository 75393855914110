import { useContext } from 'react';
import { IModel } from '../../../types/resources/cars';
import { AppContext } from '../../../contexts/AppContext';
import { NewCarModelCardSkeleton } from '../../NewCars/components/NewCarsCardsSkeletons';
import { Link } from 'react-router-dom';
import {
  AutoImgBox,
  BrowseBrandsCarInfoColumn,
  CarInfoColumnImgHolder,
  CarInfoColumnLogoImg,
  CarInfoColumnLogoImgImg,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
} from '../../../common/styles/CommonStyles';
import { ModelCardImage } from '../../NewCars/components/NewCarDetailComponents/ModelCardImage';

interface IModelCardProps {
  model: IModel;
  isLoading?: boolean;
}

export const BikeModelCard = ({ model, isLoading }: IModelCardProps) => {
  const { lang, darkImageLoadingPlaceholder } = useContext(AppContext);

  if (isLoading) {
    return <NewCarModelCardSkeleton />;
  }
  return (
    <BrowseBrandsCarInfoColumn key={model.id}>
      <Link to={`/${lang}/new-bikes/${model.brand.slug}/${model.slug}`}>
        <CarInfoColumnImgHolder>
          <AutoImgBox>
            <ModelCardImage
              src={model.cover}
              defaultSrc={darkImageLoadingPlaceholder}
              alt="img"
            />
          </AutoImgBox>
        </CarInfoColumnImgHolder>
        <CarInfoColumnTextHolder>
          <CarInfoColumnTextBox>
            <CarInfoColumnLogoImg>
              <CarInfoColumnLogoImgImg
                src={model.brand.original_logo}
                alt="img"
              />
            </CarInfoColumnLogoImg>
            <CarInfoColumnTitleCar>{model.brand.name}</CarInfoColumnTitleCar>
            <CarInfoColumnTitleCategory>
              {model.name}
            </CarInfoColumnTitleCategory>
          </CarInfoColumnTextBox>
        </CarInfoColumnTextHolder>
      </Link>
    </BrowseBrandsCarInfoColumn>
  );
};
