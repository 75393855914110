import { useContext } from 'react';
import { INewBike } from '../../../types/bikes';
import { IModel } from '../../../types/resources/cars';
import { AppContext } from '../../../contexts/AppContext';
import { NewCarsDetailsPageFeaturedDetailsSkeleton } from '../../NewCars/components/NewCarsCardsSkeletons';
import {
  FeaturedDetailsDetail,
  FeaturedDetailsH2,
  FeaturedDetailsH3,
  FeaturedDetailsP,
  FeaturedDetailsStrong,
  FeaturedDetailsStyled,
  FeaturedFrame,
  FeaturedFrameHolder,
  FeautredDetailsHolder,
} from '../../../common/styles/CommonStyles';
import {
  FeaturedDetailsDealerBrandImg,
  FeaturedFrameButtonContainer,
  PriceContainer,
  PriceInfo,
  PriceWrapper,
} from '../../NewCars/components/NewCarsStyled';
import { NewCarDetailPageDealerLogo } from '../../NewCars/components/NewCarDetailComponents/NewCarDetailPageDealerLogo';
import { formatPrice } from '../../../helpers/formatHelper';
import { Button } from '@mui/material';
import { GiSteeringWheel } from 'react-icons/gi';

interface BikeFeaturedDetailsProps {
  deal: INewBike | undefined;
  getDealClick: (deal: INewBike) => void;
  handleTestDriveClick: (testDriveUrl: string) => void;
  selectedModel: IModel;
  isLoading: boolean;
}

export const BikeFeaturedDetails = ({
  deal,
  getDealClick,
  handleTestDriveClick,
  isLoading,
  selectedModel,
}: BikeFeaturedDetailsProps) => {
  const { lang, trans } = useContext(AppContext);
  if (isLoading) {
    return <NewCarsDetailsPageFeaturedDetailsSkeleton />;
  }
  return (
    <FeaturedDetailsStyled
      className="d-flex flex-column justify-content-between align-items-end"
      style={{
        width: '60%',
      }}
    >
      {deal?.bike_dealers?.logo && (
        <FeaturedDetailsDealerBrandImg>
          <NewCarDetailPageDealerLogo
            src={deal.bike_dealers.logo}
            class="lazy"
            alt="img"
            style={{
              width: '250px',
              margin: 0,
              alignItems: 'end',
              height: '60px',
              objectFit: 'contain',
            }}
          />
        </FeaturedDetailsDealerBrandImg>
      )}
      <FeautredDetailsHolder>
        <FeaturedDetailsH2 style={{ margin: 0 }}>
          {lang === 'en' ? selectedModel.name : selectedModel.name}
        </FeaturedDetailsH2>
        <FeaturedDetailsDetail>
          {
            <FeaturedDetailsP
              dangerouslySetInnerHTML={{
                __html: selectedModel.description,
              }}
            />
          }
        </FeaturedDetailsDetail>
        <FeaturedFrame>
          <FeaturedFrameHolder style={{ width: '100%' }}>
            <PriceWrapper className="d-flex justify-content-between align-items-center">
              <PriceInfo>
                {deal?.price && (
                  <FeaturedDetailsH3>{trans.price}</FeaturedDetailsH3>
                )}
                <PriceContainer className="d-flex align-items-center">
                  <FeaturedDetailsP className="p">
                    {deal?.price && (
                      <FeaturedDetailsStrong className="ml-1 mr-1">
                        {trans.starting_from +
                          ' ' +
                          formatPrice(deal.price) +
                          ' ' +
                          trans.qar}
                      </FeaturedDetailsStrong>
                    )}
                  </FeaturedDetailsP>
                </PriceContainer>
              </PriceInfo>
              {deal ? (
                <FeaturedFrameButtonContainer
                  style={{
                    justifyContent: deal.test_drive_url ? 'center' : 'flex-end',
                  }}
                >
                  <Button
                    onClick={() => getDealClick(deal)}
                    variant="contained"
                    style={{
                      height: '40px',
                      width: lang === 'ar' ? '150px' : '130px',
                      backgroundColor: '#00d6bc',
                      fontSize: lang === 'ar' ? '1.2rem' : '0.9rem',
                      textTransform: 'capitalize',
                      textAlign: 'center',
                    }}
                    className="btn btn-secondary inquiry"
                  >
                    {deal.price ? trans.get_deal : trans.get_quote}
                  </Button>
                  {deal.test_drive_url && (
                    <Button
                      onClick={() => {
                        if (deal.test_drive_url) {
                          return handleTestDriveClick(deal?.test_drive_url);
                        }
                      }}
                      style={{
                        height: '40px',
                        width: lang === 'ar' ? '150px' : '130px',
                        color: '#fff',
                        borderColor: 'orange',
                        backgroundColor: 'orange',
                        fontSize: lang === 'ar' ? '1.2rem' : '0.9rem',
                        textTransform: 'capitalize',
                        textAlign: 'center',
                        textJustify: 'initial',
                        gap: lang === 'ar' ? '5px' : '0px',
                      }}
                      variant="contained"
                      startIcon={lang === 'en' && <GiSteeringWheel />}
                      endIcon={lang === 'ar' && <GiSteeringWheel />}
                    >
                      {trans.test_drive}
                    </Button>
                  )}
                </FeaturedFrameButtonContainer>
              ) : (
                trans.no_deals_available
              )}
            </PriceWrapper>
          </FeaturedFrameHolder>
        </FeaturedFrame>
      </FeautredDetailsHolder>
    </FeaturedDetailsStyled>
  );
};
