import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { addFavorite, deleteFavorite } from '../../services/ApiService';
import SignIn from '../User/SignIn/SignInComponent';
import CarCarouselWithTypeFilters from '../Listings/common/CarCarouselWithTypeFilters';
import { useHandleFavouriteCarClick } from '../UsedCars/hooks/useUsedCars';
import { useGetFavoriteUsedCars, useGetUsedCars } from '../UsedCars/hooks';
import { ICarType } from '../../types/resources/cars';
import { IUsedCar } from '../../types/cars/used_car';

interface IHomeUsedCarsProps {
  types: ICarType[];
}

export const generateRedirectionLink = (car: IUsedCar, lang: string) =>
  `/${lang}/used-cars/${car.model?.brand?.slug}/${car.model?.slug}/${car.year}/${car.id}`;

export default function HomeUsedCars({ types }: IHomeUsedCarsProps) {
  const { lang, trans, userData } = useContext(AppContext);
  const [showSignIn, setShowSignIn] = useState(false);
  const [selectedSection, setSelectedSection] = useState<any>(trans.featured);
  const [selectedSectionId, setSelectedSectionId] = useState<any>(null);
  const toggleSignInDialog = (show: any) => {
    setShowSignIn(show);
  };

  const location = useLocation();

  const { data: usedCars, isLoading: isLoadingUsedCars } = useGetUsedCars({
    query: {
      types: selectedSection !== trans.featured ? selectedSectionId : undefined,
      is_featured: selectedSection === trans.featured ? true : undefined,
      page: 1,
      perPage: 20,
      status: 'approved',
    },
    enabled: true,
  });

  const { data: favoriteCars, isLoading: isLoadingFavoriteCars } =
    useGetFavoriteUsedCars({
      enabled: !!userData,
      userData,
    });

  const setActiveInfoTab = (section: any) => {
    setSelectedSection(section);
    setSelectedSectionId(types.find(type => type.name === section)?.id);
  };

  const { handleFavouriteCarClick } = useHandleFavouriteCarClick(
    userData,
    addFavorite,
    deleteFavorite,
    setShowSignIn,
  );

  return (
    <>
      <CarCarouselWithTypeFilters
        cars={usedCars?.data ?? []}
        carouselTitle={trans.used_cars}
        goToAllCarsTitle={trans.view_all_used_cars}
        allCarsPath={`/${lang}/used-cars`}
        selectedFilter={selectedSection}
        setSelectedFilter={setActiveInfoTab}
        isLoading={isLoadingUsedCars || isLoadingFavoriteCars}
        favorites={favoriteCars || []}
        onFavoriteClick={handleFavouriteCarClick}
        generateRedirectionLink={generateRedirectionLink}
      />
      {showSignIn && (
        <SignIn
          toggleSignInDialog={toggleSignInDialog}
          location={location.pathname}
        />
      )}
    </>
  );
}
