import { Skeleton, styled } from '@mui/material';

export const NewCarImageStyled = styled('div')(() => ({
  width: '460px',
  position: 'relative',
  left: '0',
  top: '0',
  zIndex: 998,
  transition: 'opacity 0ms ease 0s',
  '@media (max-width: 1399px)': {
    width: '411px',
  },
  '@media (max-width: 993px)': {
    width: '255px',
  },
  '@media (max-width: 767px)': {
    width: '100vw',
  },
}));

export const NewCarImageSlideContent = styled('div')(() => ({
  background: '#fff',
  borderRadius: '10px 0 0 10px',
  overflow: 'hidden',
  position: 'relative',
  border: '1px solid #ddd',
  borderRight: 0,
  height: '18.7rem',
  '.lang-au &': {
    borderRadius: '0 10px 10px 0',
  },
}));

export const FeaturedDetailsDealerBrandImg = styled('div')(() => ({
  '@media (max-width: 767px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  '@media (min-width: 768px)': {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
}));

export const PriceWrapper = styled('div')(() => ({
  '@media (max-width: 991px)': {
    flexDirection: 'column',
    marginBottom: '10px',
  },
}));

export const PriceInfo = styled('div')(() => ({
  '@media (max-width: 991px)': {
    width: '100%',
  },
}));

export const PriceContainer = styled('div')(() => ({
  gap: '5px',
}));

export const FeaturedFrameButtonContainer = styled('div')(() => ({
  display: 'flex',
  width: '300px',
  gap: '8px',
}));

export const NewCarsDetailsPageSliderImagesSkeletonStyled = styled(Skeleton)({
  margin: '0',
  height: '100%',
  width: '82px',
  maxWidth: '85px',
  maxHeight: '82px',
  borderRadius: '8px',
  '@media (max-width: 1024px)': {
    width: '65px',
  },
  '@media (max-width: 912px)': {
    width: '47px',
  },
  '@media (max-width: 540px)': {
    width: '82px',
  },
  '@media (max-width: 430px)': {
    width: '76px',
  },
  '@media (max-width: 414px)': {
    width: '73px',
  },
  '@media (max-width: 390px)': {
    width: '68px',
  },
  '@media (max-width: 375px)': {
    width: '65px',
  },
  '@media (max-width: 360px)': {
    width: '62px',
  },
  '@media (max-width: 280px)': {
    width: '54px',
  },
});
