import { useReducer, useContext } from 'react';
import { useGetRentalCars } from '../hooks';
import { CarouselWithTypeFilter } from './CarouselWithTypeFilter';
import { AppContext } from '../../../contexts/AppContext';
import { ICarType } from '../../../types/resources/cars';

interface IHomePageRentalsProps {
  types: ICarType[];
}

const PAGESIZE = 10;

export const HomePageRentals = ({ types }: IHomePageRentalsProps) => {
  const { lang, trans } = useContext(AppContext);
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'SET_FILTER': {
        if (action.payload === trans.featured) {
          return {
            isFeatured: true,
            type: {
              created_at: null,
              id: null,
              slug: '',
              updated_at: null,
              name: lang === 'ar' ? 'Featured' : 'مميزة',
            },
          };
        }

        let filter = types.find(obj => {
          return obj.name === action.payload;
        });

        return { isFeatured: false, type: filter };
      }
      default:
        return filters;
    }
  };

  const [filters, dispatch] = useReducer(reducer, {
    type: {
      created_at: null,
      id: null,
      slug: '',
      updated_at: null,
      name: '',
    },
    isFeatured: true,
  });

  const { data: rentedCarsData, isLoading: isLoadingRentals } =
    useGetRentalCars({
      query: {
        isFeatured: filters.isFeatured,
        types: !filters.isFeatured ? filters.type?.id : undefined,
        perPage: PAGESIZE,
      },
      enabled: true,
    });

  const handleFilterChange = (e: any) => {
    dispatch({ type: 'SET_FILTER', payload: e });
  };

  return (
    <>
      <CarouselWithTypeFilter
        carouselTitle={trans.rental_cars_title}
        isLoading={isLoadingRentals}
        rentals={rentedCarsData?.data}
        allRentalsPageUrl={`/${lang}/rent-cars`}
        goToRentalsTitle={trans.view_all_rentals}
        setSelectedFilter={handleFilterChange}
        selectedFilter={
          !filters?.isFeatured ? filters.type?.name : trans.featured
        }
      />
    </>
  );
};
