import { useContext, useEffect } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useParams } from 'react-router-dom';
import PageLoader from '../../Other/PageLoader/PageLoaderComponent';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { IDealer } from '../../../types/resources';
import { useDealers } from '../hooks/useDealersApis';
import {
  ChooseBlock,
  ChooseBlockBtnSecondary,
  ChooseBlockChoosing,
  ChooseBlockChoosingImg,
  ChooseCategory,
  ChooseCategoryH2,
  ShowroomHeader,
  ShowroomHeaderH1,
  ShowroomHeaderImgBox,
  ShowroomHeaderImgBoxImg,
} from './DealerStyledComponent';
import { ColLg12, Row } from '../../../common/styles/CommonStyles';
import BikeIcon from '../../../common/images/dealerBike.svg';
import { styled } from '@mui/material';

const Styled = styled('div')(() => ({
  '@media (max-width: 1399px)': {
    gap: '20px',
  },
}));

export default function DealerDetail() {
  const { lang, trans } = useContext(AppContext);
  const params = useParams();

  const { data: dealers, isLoading: dealersIsLoading } = useDealers();

  const selectedDealer = dealers?.find(
    (dealer: IDealer) => dealer.slug === params.name,
  );

  const isUsedCars = dealers?.find(
    (dealer: IDealer) =>
      dealer.slug === params.name && dealer._count?.used_cars > 0,
  );

  const isNewCars = dealers?.find(
    (dealer: IDealer) =>
      dealer.slug === params.name && dealer._count?.new_cars > 0,
  );

  const isNewBikes = dealers?.find(
    (dealer: IDealer) =>
      dealer.slug === params.name && dealer._count?.new_bikes > 0,
  );

  const isUsedBikes = dealers?.find(
    (dealer: IDealer) =>
      dealer.slug === params.name && dealer._count?.used_bikes > 0,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaTag
        metaKey="dealer"
        href={`/${params?.lang}/dealer/${params.name}/`}
        params={{ '%NAME%': selectedDealer?.name }}
      />
      <main id="main">
        {dealersIsLoading && <PageLoader />}
        <ShowroomHeader>
          <div className="container">
            <Row>
              <ColLg12>
                <ShowroomHeaderImgBox>
                  <ShowroomHeaderImgBoxImg
                    src={selectedDealer?.logo}
                    alt={`${selectedDealer?.slug}-logo`}
                  />
                </ShowroomHeaderImgBox>
                <ShowroomHeaderH1>
                  {selectedDealer?.name} {trans.showroom}
                </ShowroomHeaderH1>
              </ColLg12>
            </Row>
          </div>
        </ShowroomHeader>
        <ChooseCategory className="container">
          <ChooseCategoryH2>{trans.choose_a_category}</ChooseCategoryH2>
          <Row>
            <ColLg12>
              <Styled className="d-flex justify-content-center flex-wrap">
                {isNewCars && (
                  <ChooseBlock to={`/${lang}/dealer/${params.name}/new-cars`}>
                    <div className="box">
                      <ChooseBlockChoosing>
                        <ChooseBlockChoosingImg
                          src="/images/icon-newcars.png"
                          alt="icon"
                        />
                      </ChooseBlockChoosing>
                      <ChooseBlockBtnSecondary className="btn btn-secondary">
                        {trans.new}
                      </ChooseBlockBtnSecondary>
                    </div>
                  </ChooseBlock>
                )}
                {isUsedCars && (
                  <ChooseBlock to={`/${lang}/dealer/${params.name}/used-cars`}>
                    <div className="box">
                      <ChooseBlockChoosing>
                        <ChooseBlockChoosingImg
                          src="/images/icon-usedcar.png"
                          alt="icon"
                        />
                      </ChooseBlockChoosing>
                      <ChooseBlockBtnSecondary className="btn btn-secondary">
                        {trans.used}
                      </ChooseBlockBtnSecondary>
                    </div>
                  </ChooseBlock>
                )}
                {isNewBikes && (
                  <ChooseBlock to={`/${lang}/dealer/${params.name}/new-bikes`}>
                    <div className="box">
                      <ChooseBlockChoosing>
                        <ChooseBlockChoosingImg src={BikeIcon} alt="icon" />
                      </ChooseBlockChoosing>
                      <ChooseBlockBtnSecondary className="btn btn-secondary">
                        {trans.new}
                      </ChooseBlockBtnSecondary>
                    </div>
                  </ChooseBlock>
                )}
                {isUsedBikes && (
                  <ChooseBlock to={`/${lang}/dealer/${params.name}/used-bikes`}>
                    <div className="box">
                      <ChooseBlockChoosing className="choosimg">
                        <ChooseBlockChoosingImg
                          src={BikeIcon}
                          alt="icon"
                          style={{ color: 'black' }}
                        />
                      </ChooseBlockChoosing>
                      <ChooseBlockBtnSecondary className="btn btn-secondary">
                        {trans.used}
                      </ChooseBlockBtnSecondary>
                    </div>
                  </ChooseBlock>
                )}
              </Styled>
            </ColLg12>
          </Row>
        </ChooseCategory>
      </main>
    </>
  );
}
