import axios from 'axios';
import { logOutUser } from '../services/ApiService';
import { processTranslations } from '../helpers/formatHelper';
import { User } from '../types';
import Qs, { IStringifyOptions } from 'qs';

const authenticatedApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 50000,
  paramsSerializer: {
    serialize: (params: any) => {
      const options: IStringifyOptions = {
        arrayFormat: 'repeat',
      };
      return Qs.stringify(params, options);
    },
  },
});

// Function to refresh the access token
export const refreshAccessToken = async () => {
  const currentRefreshToken =
    localStorage.getItem('refreshToken') ||
    sessionStorage.getItem('refreshToken');

  if (!currentRefreshToken) {
    throw new Error('No refresh token found');
  }

  // check expiry date
  const refreshTokenData = JSON.parse(atob(currentRefreshToken.split('.')[1]));
  const refreshTokenExpiryDate = refreshTokenData.exp * 1000;
  const currentTime = new Date().getTime();

  if (refreshTokenExpiryDate < currentTime) {
    throw new Error('Refresh token expired');
  }

  // Get a new access token
  const newAccessToken = await getNewToken(currentRefreshToken);

  // Set the new access token in the Axios instance
  authenticatedApi.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${newAccessToken}`;

  return newAccessToken;
};

export const getNewToken = async (refreshToken: string) => {
  const tokenUrl = '/v2/auth/refresh-token';

  const response = await authenticatedApi.post(tokenUrl, {
    refresh_token: refreshToken,
  });

  return response.data.access_token;
};

authenticatedApi.interceptors.request.use(async (request: any) => {
  const accessToken = localStorage.getItem('accessToken');
  const lang = window.location.pathname.split('/')[1] || 'en';
  request.params = { ...request.params, lang };

  if (accessToken) {
    // get UserData from payload
    const userData = JSON.parse(atob(accessToken.split('.')[1]));
    if (userData) {
      const mobileVerified = userData?.mobile_verified_at;
      if (
        !mobileVerified &&
        window.location.pathname !== `/${lang}/verify-phone` &&
        window.location.pathname !== `/${lang}/account-details`
      ) {
        return (window.location.href = `/${lang}/verify-phone`);
      }
      request.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }
  request.headers['lang'] = lang;
  return request;
});

authenticatedApi.interceptors.response.use(
  function (successRes) {
    return processTranslations(successRes);
  },
  async function (error) {
    if (error.response.status === 401) {
      try {
        if (error.config.url === '/v2/auth/refresh-token')
          throw new Error('Refresh token expired');
        const newAccessToken = await refreshAccessToken();
        // Retry the original request with the new access token
        error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
        const userDataFromToken: User = JSON.parse(
          atob(newAccessToken.split('.')[1]),
        );
        localStorage.setItem('accessToken', newAccessToken);
        localStorage.setItem('userData', JSON.stringify(userDataFromToken));
        return authenticatedApi.request(error.config);
      } catch (refreshError) {
        // Refresh token has expired. User must authenticate
        logOutUser();
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userData');
        window.location.href = `/`;
      }
    }
    if (error.response?.status === 400) {
      throw error;
    } else {
      throw error;
    }
  },
);

export default authenticatedApi;
