import { colors } from '@mui/material';

export default function TransAr() {
  return {
    with: 'مع',
    free_valuation: 'تقييم مجاني',
    dream_it: 'احلم بها.  اشتريها.  قدها.',
    already_account: 'لديك حساب؟‎',
    confirm_email: 'تأكيد البريد الالكتروني',
    confirm_password: ' تأكيد كلمة المرور',
    username: 'اسم االمستخدم',
    inquiry_call_heading: 'أنت على بعد خطوة واحدة من امتلاك سيارة أحلامك!',
    bank_details: ' تفاصيل الشركة',
    sponsored: ' مميز',
    deactivate: 'تعطيل الحساب',
    deactivateAccountConfirmationMessage: 'هل أنت متأكد أنك تريد تعطيل حسابك',
    more_deals_from: 'المزيد من صفقات',
    Car_models: 'طرازات السيارات',
    and_your_phone_number: 'وما رقم هاتفك؟',
    lets_start_with_your_full_name: 'لنبدأ باسمك الكامل',
    no: 'كلا',
    type_your_answer_here: 'اكتب إجابتك هنا',
    'what_is_your_email_address?': 'ما عنوان بريدك الإلكتروني؟',
    publish: 'ينشر',
    next: 'تابع',
    'done!': 'تم!',
    login: 'تسجيل دخول',
    account: 'الحساب',
    notifications: 'الإشعارات',
    wishlist: 'قائمة الأمنيات',
    chat: 'المحادثات',
    my_listings: 'إعلاناتي',
    support: 'الدعم',
    get_in_touch_with_us: 'تواصل معنا',
    name: 'الاسم',
    message: 'الرسالة',
    send: 'أرسل',
    sign_out: 'تسجيل خروج',
    account_details: 'تفاصيل الحساب',
    full_name: 'الاسم الكامل',
    first_name: ' الاسم الاول',
    last_name: 'الاسم الاخير',
    contact_information: 'معلومات شخصية',
    email_address: 'البريد الإلكتروني',
    mobile_number: 'رقم الهاتف',
    whatsapp_number: 'رقم الواتساب',
    email: 'البريد الإلكتروني',
    city: 'المدينة',
    country: 'البلد',
    latest_notifications: 'آحدث الإشعارات',
    you_donot_have_any_notifications: 'ليس لديك أي إشعار',
    back_to_home_page: 'الرجوع إلى الصفحة الرئيسية',
    there_are_0_cars_in_your_wishlist: 'ليس لديك أي سيارة في قائمة الأمنيات',
    you_have_0_messages_in_your_inbox: 'ليس لديك أي رسالة في صندوق الوارد',
    view_listing: 'عرض الإعلان',
    password: 'كلمة المرور',
    member_since: ' عضو منذ',
    sign_up: 'أنشئ حسابًا',
    forgot_password: 'هل نسيت كلمات المرور؟',
    scan_qr_code_to_login: 'امسح رمز QR لتسجيل الدخول',
    'need_an_account?': 'ليس لديك حساب؟',
    'your_information_was_sent_perfectly.': 'تم إرسال معلوماتك بنجاح.',
    accountCreatedSuccessfully: 'تم إنشاء حسابك بنجاح!',
    back_to_home: 'الصفحة الرئيسية',
    back_to_results: 'العودة إلى النتائج',
    and_your_last_name: 'واسم عائلتك؟',
    'and_your_phone_number?': 'وما رقم هاتفك؟',
    soultions_in_qatar: 'حلول في قطر',
    banks_in_qatar: 'بنك في قطر',
    why_we_rock: 'سبب تألّقنا',
    yes: 'متوفر',
    listings: 'متوفر',
    remove: 'إلغاء',
    add_listing: 'إضافة إعلان',
    you_have_0_listings: 'ليس لديك أي إعلان',
    hello: 'مرحبا',
    photos_videos: 'الصور ومقاطع الفيديو',
    phone_number: 'رقم الهاتف',
    battery_range: 'قدرة البطارية (كم)',
    battery_capacity: 'قدرة البطارية (كيلوواط ساعة)',
    no_dealers_found: 'لم يتم العثور على تجار',
    no_deals_found: 'لم يتم العثور على أي صفقة',
    in_qatar: 'في قطر',
    list_of_dealers: 'قائمة التجار',
    any_dealer: 'أي تاجر',
    used: 'مستعمل',
    new: 'جديد',
    choose_a_category: 'اختر فئة:',
    w_a_reply: 'اكتب ردًا',
    shop: 'تسوّق',
    deals: 'صفقات',
    drive_it: 'قدها',
    drivetrain: 'نظام الدفع',
    buy_it: 'اشتريها',
    w_a_message: 'اكتب تعليقًا',
    location: 'الدوحة، الدحيل، قطر',
    p_of: 'من ',
    n_a: 'غير متوفر',
    clear: 'مسح',
    cancel: 'إلغاء',
    engine_type: 'نوع المحرك',
    seat_material: 'فرش المقاعد',
    interior_color: 'اللون الداخلي',
    exterior_color: 'اللون الخارجي',
    transmission: 'ناقل الحركة',
    cylinders: 'سلندرات',
    engine_size: 'حجم المحرك (ل)',
    car_condition: 'الحالة',
    chat_now: 'الدردشة',
    visit_online_showroom: 'زيارة صالة العرض الرقمية',
    back: 'رجوع',
    reference_no: 'رقم المرجع',
    any: 'غير محدد',
    min: 'من ',
    max: 'إلى',
    from: 'من',
    to: 'إلى ',
    all: 'الكل ',
    newest: 'أحدث قائمة',
    oldest: 'أقدم قائمة',
    lowp: 'أقل سعر',
    highp: 'أعلى سعر',
    lowm: 'أقل كيلومترات مقطوعة',
    higm: 'أعلى كيلومترات مقطوعة',
    latestm: 'أحدث طراز العام',
    oldm: 'أقدم طراز العام',
    download_app: 'حمل التطبيق',
    sell_car: 'بع سيارتك',
    a1_banner_title: ' Ad',
    scan_qr: 'امسح رمز QR أدناه',
    to_download: 'لتحميل',
    sign_in: 'سجّل دخول',
    home: 'الصفحة الرئيسية',
    cars: 'سيارات',
    used_cars_for_sale: 'سيارات مستعملة للبيع',
    new_cars: 'سيارات جديدة',
    used_cars: 'سيارات مستعملة',
    used_cars1: 'السيارات المستعملة‎',
    dealers: 'وكلاء',
    financing: 'تمويل',
    insurance: 'تأمين',
    companies_in_qatar: 'شركات في قطر',
    list_of_insurance_companies: 'قائمة شركات التأمين',
    articles: 'مقالات',
    find_car: 'ابحث عن سيارتك',
    looking_for: 'عن ماذا تبحث؟',
    select_brand: 'اختر العلامة التجارية',
    select_model: 'اختر الطراز',
    select_type: 'اختر النوع',
    select_trim: 'اختر الفئة',
    search: 'بحث',
    advanced_search: 'بحث متقدم',
    car_details: 'تفاصيل السيارة',
    watch_this: 'شاهد',
    our_story: 'قصتنا',
    our_aim: 'هدفنا',
    featured_cars: 'سيارات مميزة',
    featured: 'مميزة',
    view_deal: 'تفاصيل الصفقة',
    find_car_you_looking: 'احصل على السيارة التي تبحث عنها!',
    dream_it1: 'احلم بها',
    find_it: 'ابحث عنها',
    browse_top_brands: 'تصفح بحسب أفضل العلامات التجارية',
    view_all: 'عرض الكل‎',
    view_all1: 'عرض كل',
    sell_your_car_with_oneautocar: 'بيع سيارتك مع وان اوتوكار',
    free_listing: 'إعلان مجاني',
    sell_it: 'قم ببيعها',
    view_all_used_cars: 'عرض كل السيارات المستعملة',
    year: 'السنة',
    km_driven: 'الكيلومترات',
    km: 'كم',
    kwh: 'كيلووات ساعة',
    kms: 'كم',
    dealer: 'الوكيل',
    dealer1: 'وكيل',
    seller: 'البائع',
    private: 'خاص',
    about_us: 'من نحن',
    qar: 'ر.ق.',
    qarPerMonth: 'ريال قطري / شهر',
    view_car: 'عرض التفاصيل',
    overview: 'نظرة عامة',
    contact_us: ' اتصل بنا',
    contact: ' اتصل بنا',
    advertise: 'أعلن معنا',
    feedback: 'تقييم',
    privacy: 'سياسة الخصوصية',
    terms: 'الشروط والأحكام',
    browse_cars_by_brands: 'تصفح السيارات حسب العلامات التجارية',
    browse_bikes_by_brands: 'تصفح الدراجات النارية حسب العلامات التجارية',
    view_deals: 'تفاصيل الصفقات',
    interior_comfort: 'راحة في التصميم الداخلي',
    seamless_connectivity: 'اتصال سلس',
    intelligent_mobility: 'تنقل ذكي',
    '1_year_warranty': 'ضمان لسنة واحدة',
    free_registration: 'تسجيل مجاني',
    downpayment: '0٪ دفعة أولى',
    litres: 'لتر',
    automatic: 'نظام أوتوماتيكي',
    petrol: 'بنزين',
    doors: 'أبواب',
    leather: 'جلد',
    brand: 'العلامة التجارية',
    model: 'الطراز',
    type: 'النوع',
    mileage: 'الكيلومترات',
    price: 'السعر',
    bond: 'يجمعكم أينما ذهبتم. إن',
    equipped:
      'Nissan X-Terra 2021 الجديد مصمم ومجهز ليناسب مغامرتكم العائلية التالية.',
    starting_from: 'ابتداءً من',
    month: 'شهر',
    save: 'حفظ',
    benefits: 'مزايا',
    in_house_financing: 'تمويل داخلي',
    roadside_assistance: 'مساعدة على الطريق',
    get_deal: 'اعقد الصفقة',
    visit_website: 'قم بزيارة الموقع',
    get_quote: 'احصل على سعر',
    more_deals: 'عروض أخرى من شركة صالح الحمد المانع',
    saleh: 'شركة صالح الحمد المانع',
    online_showroom: 'صالة العرض الرقمية',
    showroom: 'صالة العرض',
    visit_now: 'قم بزيارتها الآن',
    delete_listings_msg_show_1:
      'هل أنت متأكّد من أنك تريد حذف هذا الإعلان من وان أوتوكار؟',
    other_models: 'طرازات أخرى',
    models: 'الطرازات',
    other: 'أخرى',
    wwr_p1:
      'وصل أوتو إلى الأرض من مجرّة بعيدة جدًا بحثًا عن إجابات لمعاناتنا. مع أكثر من ٣٠٠ عام من الخبرة في بيع السيارات، ابتكر فكرة إنشاء ONE AUTOCAR! منصة ثورية خاصة بالسيارات تربط المشتري بالبائع. يهدف أوتو جعل منصته فريدة لا تشبه غيرها من المنصات. <br/> بناءً على ذلك، بدأ في تطوير المنصة مستخدماً العديد من التقنيات الحديثة التي استحوذ عليها من المجرّة التي وصل منها. أيضاً، متسلحاً بالذكاء الاصطناعي المتقدم، قام ببناء ONE AUTOCAR، منصة تفوق جميع توقعاتك من كل النواحي. تتمثّل مهمة أوتو في تسهيل عمليّة الشراء وتحسينها ومساعدتك على اتخاذ القرارات الأمثل في شراء سيارتك. <br/>بالإضافة إلى ذلك، يمكن لأصحاب السيارات بسهولة عرض سياراتهم الجديدة أو المستعملة للبيع في خطوات قليلة. حتى يومنا هذا، يحافظ أوتو على علاقةٍ وثيقةٍ مع مستخدميه ويضمن تحديث جميع السيارات المدرجة على المنصة والعمل باستمرار على تحسينها لحصول روّاد المنصة على أفضل تجربة على الإطلاق.',
    wwr_p2:
      'صدقنا عندما نقول، الأحلام تتحقق! وأوتو هنا لتحويل أحلامك إلى حقيقة ، من خلال عرض أفضل الصفقات. <br/>يقدم ONE AUTOCAR لجميع المستخدمين طريقة سريعة وسهلة لشراء وبيع وتمويل وتأمين سياراتهم ، سواء كانت قديمة أو جديدة. بالإضافة إلى الموقع الكتروني، تم أيضا تطوير تطبيق محمول المتوفر على iOS و Android. ماذا تنتظر؟ <br/>اشترك الآن وانضم إلى عائلتنا!',
    usedcar_search:
      '<span class="txt">ابحث </span> <span class="for">عن </span> <strong>سيارتك</strong>',
    brand_details:
      'اكتشف أفضل صفقات %modelName% مع أفضل وكلاء %brandName% المعتمدين والموثوقين في قطر. \
تفحّص جميع الصفقات في مكان واحد – قارن بين الأسعار والمزايا والخصائص والفوائد. \
تمتّع بسهولة الوصول إلى جميع المعلومات التي تحتاجها لمساعدتك على اختيار السيارة المثالية لك. \
مع وان أوتوكار، نقرة زر واحدة تفصلك عن إيجاد سيارة أحلامك. \
اضغط على "احصل على صفقة" لملء النموذج وسيتم التواصل معك مباشرةً.كل الصفقات، في مكان واحد – وان أوتوكار',

    sorry_msg_show_1: 'عذراً ، لم نتمكن من العثور على أي <br> مركبات لبحثك.',
    sorry_msg_show_2: 'حاول مجددًا أو ارجع إلى الصفحة الرئيسية.',
    sorry_msg_show_3: 'عذراً، لم نتمكن من العثور على أي مراجعات لبحثك.',
    what_is_your_email_address: 'ما عنوان بريدك الإلكتروني؟',
    location1: 'الموقع',
    solutions_in_qatar: 'حلول في قطر',
    first_name1: 'الاسم الاول',
    last_name1: 'الكنية',
    email_address1: 'عنوان البريد الالكترونى',
    our_app: 'تطبيقنا',
    why_we_rock_img: 'images/why-we-rock-ar.png',
    why_we_rock_app_img: 'images/download-app-ar.png',
    submit: 'قبول',
    insurance_details: 'تفاصيل التأمين',
    by: 'بواسطة',
    call_us_on: 'اتصل بنا على',
    more_articles: 'المزيد من المقالات',
    error: 'خطأ',
    page_doesnt_exist: 'لم يتم العثور على هذه الصفحة',
    sign_in_first: 'سجّل دخول',
    year_ago: 'منذ عام',
    years_ago: 'منذ سنوات',
    month_ago: 'منذ شهر',
    months_ago: 'منذ أشهر',
    day_ago: 'منذ يوم',
    days_ago: 'منذ أيام',
    hour_ago: 'منذ ساعة',
    hours_ago: 'منذ ساعات',
    minute_ago: 'منذ دقيقة',
    minutes_ago: 'منذ دقائق',
    second_ago: 'منذ ثانية',
    seconds_ago: 'منذ ثانية',
    enter_code: 'أدخل الرمز الذي أُرسل إلى هاتفك',
    resend_code: 'إعادة إرسال الرمز',
    send_code_confirmation_msg: 'سنرسل لك رمزًا لتأكيد أن هذه الحسابات ملكك',
    mobile_or_email: 'رقم الهاتف أو البريد الإلكتروني',
    type_password: 'اكتب كلمة مرورك الجديدة',
    mobile_required: 'رقم الهاتف مطلوب',
    code_required: 'الرمز مطلوب',
    code_length: 'يجب أن تتكون الرمز من 6 أحرف',
    password_required: 'كلمة المرور مطلوبة',
    password_length: 'يجب أن تكون كلمة المرور 8 أحرف على الأقل',
    confirm_password_required: 'تأكيد كلمة المرور مطلوب',
    passwords_no_match: 'كلمات السر لا تتطابق',
    main_dealer: 'وكيل رئيسي',
    approved: 'موافق عليه',
    pending: 'قيد الانتظار',
    rejected: 'مرفوض',
    no_results: 'لا نتائج',
    more: 'أكثر',
    less: 'أقل',
    email_required: 'البريد الإلكتروني مطلوب',
    firstname_required: 'الاسم الاول مطلوب',
    lastname_required: 'الاسم الاخير مطلوب',
    username_required: 'اسم االمستخدم مطلوب',
    confirmemail_required: 'تأكيد البريد الالكتروني مطلوب',
    confirmpassword_required: 'تأكيد كلمة المرور مطلوب',
    country_required: 'البلد مطلوب',
    city_required: 'المدينة مطلوبة',
    email_invalid: 'البريد الإلكتروني غير صالح',
    emails_dont_match: 'لا يتطابق البريدين الإلكترونين',
    passwords_dont_match: 'لا يتطابق كلمتا المرور',
    name_required: 'الاسم مطلوب',
    brand_required: 'العلامة التجارية مطلوبة',
    model_required: 'الطراز مطلوب',
    type_required: 'النوع مطلوب',
    year_required: 'السنة مطلوبة',
    transmission_required: 'ناقل الحركة مطلوب',
    enginesize_required: 'حجم المحرك مطلوب',
    cylinders_required: 'سلندرات مطلوبة',
    batterycapacity_required: 'قدرة البطارية مطلوب',
    batteryrange_required: 'نطاق البطارية مطلوب',
    exteriorcolor_required: 'اللون الخارجي مطلوب',
    interiorcolor_required: 'اللون الداخلي مطلوب',
    seatmaterial_required: 'فرش المقاعد مطلوب',
    price_required: 'السعر مطلوب',
    mileage_required: 'الكيلومترات المقطوعة مطلوبة',
    cardetails_required: 'تفاصيل السيارة مطلوبة',
    enginetype_required: 'نوع المحرك مطلوب',
    business_inquiry: 'هذا استفسار عمل',
    industry: 'قطاع',
    attachment: 'مرفق',
    message_required: 'الرسالة مطلوبة',
    support_message_sent_successfully: 'تم إرسال الرسالة بنجاح',
    support_message: 'رسالة دعم',
    you_have_zero_rejected_cars: 'ليس لديك أي سيارة مرفوضة',
    you_have_zero_accepted_cars: 'ليس لديك أي سيارة موافق عليها',
    you_have_zero_pending_cars: 'ليس لديك أي سيارة معروضة للبيع',
    remove_listing: 'هل أنت متأكد من إزالة هذا الإعلان؟',
    confirm: 'نعم',
    are_you_sure: 'هل أنت متأكد من أنك تريد',
    block_this_person: 'حظر هذا المستخدم؟',
    report_this_person: 'التبليغ عن هذا المستخدم؟',
    delete_this_chat: 'حذف هذا المحادثة؟',
    block: 'حظر',
    report: 'تبليغ',
    delete: 'حذف',
    report_sent: 'تم التبليغ عن المستخدم',
    submitted_for_review: 'وصل تبليغكم للمستخدم إلى فريقنا',
    done: 'تم',
    edit: 'تعديل',
    redirect: 'إعادة التوجيه',
    at_least_1_photo_video_required: 'مطلوب صورة / فيديو واحد على الأقل',
    at_most_8_photo_video_allowed: 'يُسمح بـ 15 صور / فيديو بحد أقصى',
    convertible: 'كشف',
    coupe: 'كوبيه',
    hatchback: 'هاتشباك',
    suv: 'اس يو في',
    sedan: 'سيدان',
    description: 'التفاصيل',
    not_available: 'غير متاح',
    no_notifications: 'ليس لديك إشعارات',
    all_deals: '.كل الصفقات',
    one_place: '.في مكان واحد',
    arabic: 'العربية',
    english: 'الإنجليزية',
    en: 'الإنجليزية',
    ar: 'العربية',
    landing_page_top_section_description_1:
      'سواء كنت تريد بيع سيارتك أم إيجاد السيارة المناسبة لك، يساعدك تطبيق One Autocar في تحقيق جميع متطلباتك بسهولة ويقدّم أفضل خيارات السيارات لك.',
    landing_page_top_section_description_2:
      'حمّل تطبيقنا اليوم من App Store أو Play Store .',
    phone_at_least_8: 'يجب أن يتكون رقم الهاتف من 8 أرقام على الأقل',
    phone_should_be_valid: 'يجب عليك إدخال رقم هاتف صالح',
    show_number: 'إظهار الرقم',
    number_copied_to_clipboard: 'تم نسخ الرقم إلى الحافظة',
    no_deals_available: 'لا توجد صفقات متاحة',
    test_drive: 'تجربة سواقة',
    rent: 'استئجار',
    rent_now: 'استئجار الآن',
    rental_cars: 'سيارات للإيجار',
    rental_cars_title: 'سيارات للإيجار',
    seat: 'مقعد',
    suitcase: 'حقائب',
    price_per_day: 'السعر لكل يوم',
    price_per_week: 'السعر لكل أسبوع',
    price_per_month: 'السعر لكل شهر',
    price_per_year: 'السعر لكل سنة',
    per_day: 'لكل يوم',
    company: 'الشركة',
    gims_qatar: 'معرض جنيف للسيارات',
    large_baggage_capacity: 'سعة حمولة كبيرة',
    small_baggage_capacity: 'سعة حمولة صغيرة',
    submit_form: 'إرسال النموذج',
    select_rent_date: 'اختر <b>تاريخ الاستئجار</b>',
    select_location: 'اختر <b>الموقع</b>',
    pick_up_location: 'موقع الاستلام',
    drop_off_location: 'موقع التسليم',
    go_to_whatsapp: 'الذهاب إلى WhatsApp',
    same_as_pickup_location: 'نفس موقع الاستلام',
    start: 'بداية',
    end: 'نهاية',
    no_rentals_available: 'لا توجد سيارات للإيجار متاحة',
    view_all_rentals: 'عرض جميع السيارات المتاحة للإيجار',
    Get_One_Autocar_App: 'احصل على تطبيق وان أوتوكار',
    Download_Now: '!حمله الآن',
    mercedes_eqs: 'مرسيدس بنز EQS',
    west_walk_ev: 'ويست ووك EV',
    seats: 'المقاعد',
    loading: 'جار التحميل...',
    reviews: 'التقييمات',
    more_reviews: 'المزيد من التقييمات',
    brands: 'العلامات التجارية',
    yearsMinMax: 'السنوات (الحد الأدنى - الحد الأقصى)',
    dealers1: 'وكلاء',
    car_dealers: 'وكلاء السيارات',
    features: 'المزايا',
    oneautocar: 'وان أوتوكار',
    qatar_custom_show: 'قطر كاستوم شو',
    language: 'اللغة',
    submit_inquiry: 'تسجيل اهتمام ',
    ramadan_offers: 'عروض رمضان',
    get_offer: 'اعقد الصفقة',
    used_bikes: 'دراجات نارية مستعملة',
    used_bikes_for_sale: 'دراجات نارية مستعملة للبيع',
    view_bike: 'عرض التفاصيل',
    colors: 'الألوان',
    color: 'اللون',
    cylinder: 'سلندر',
    sellYourBike: 'بيع دراجتك النارية',
    bike_details: 'تفاصيل الدراجة النارية',
    color_required: 'اللون مطلوب',
    bikeDetails_required: 'تفاصيل الدراجة النارية مطلوبة',
    sell_your_vehicle: 'بيع مركبتك',
    new_bikes: 'دراجات نارية جديدة',
    bikes: 'دراجات نارية',
    sell_now: 'بيع الآن',
    coming_soon: 'قريباً',
    first: 'الأول',
    Scan_your_car_id_or_type_it_manually:
      'امسح رمز السيارة الخاص بك أو اكتبه يدويًا',
    Scan_your_car_description:
      'يمكنك مسح رقم الهوية الخاص بك أو كتابته أدناه حتى نتمكن من الحصول على تفاصيل سيارتك بشكل أسرع',
    Type_your_vin_Number: 'أدخل رقم الهيكل / رقم VIN الخاص بك',
    Wrong_car_id: 'رقم السيارة غير صحيح',
    sell_confirm: 'تأكيد',
    Or_you_can_select_your_car_details: 'أو يمكنك اختيار تفاصيل سيارتك',
    invalid_vin_scan_error:
      'لا يمكننا العثور على سيارتك! تحقق من رقم الهيكل الخاص بك',
    Confirm_car_id_Number: 'تأكيد رقم هوية السيارة',
    Upload_Vin_Card: 'تحميل بطاقة VIN',
    upload: 'تحميل',
    carBrand: 'العلامة التجارية',
    mainDetails: 'التفاصيل الرئيسية',
    carSpecification: 'مواصفات السيارة',
    yourCarDetails: 'تفاصيل سيارتك',
    yourCarMilage: 'كيلومترات سيارتك (كم)',
    carMileage: 'كيلومترات السيارة',
    youCarPrice: 'سعر سيارتك (ر.ق)',
    car_price: 'سعر السيارة',
    upload_photos: 'تحميل الصور',
    addit_info: 'معلومات إضافية',
    addit_info_p:
      'الرجاء كتابة أي معلومات إضافية ترغب في إخبار المشتري عن سيارتك',
    priceValuation: 'لقد قمنا بتقدير السعر لك!',
    dropImage: 'اسحب صورتك هنا، أو:',
    browse: 'تصفح',
    Supports: 'يدعم: PNG, JPG, JPEG, WEBP',
  };
}
