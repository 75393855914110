import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import PageLoader from '../Other/PageLoader/PageLoaderComponent';
import CarListingDetails from '../Listings/common/CarListingDetails';
import { useGetUsedCarById, useGetUsedDealClick } from './hooks';
import MetaTag from '../../common/meta/MetaTag';
import { UsedCarsInquiryForm } from './UsedCarsInquiryForm';
import { EChatConversationType } from '../../types/chats/chat_conversation';
import { BikeListingDetailsSkeleton } from '../UsedBikes/components/BikeDetailsSkeletons';
import '../../styles/css/swiper-bundle.css';

export default function UsedCarDetail({ setImageSrc, handleOpen }) {
  const responsiveFeatured = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };
  const { lang, userData, trans } = useContext(AppContext);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showDealForm, setShowDealForm] = useState(false);

  const params = useParams();
  const { data: carDetails, isLoading } = useGetUsedCarById(params.id);

  const handleChatNow = async () => {
    if (!userData) {
      setShowModal(true);
    }

    if (userData && carDetails && carDetails.user_id) {
      navigate(`/${lang}/account-chat/${EChatConversationType.Car}/new`, {
        state: {
          new: true,
          car: carDetails,
          loggedInUser: userData,
          chat_conversation_type: EChatConversationType.Car,
        },
      });
    }
  };

  const { getDealClick } = useGetUsedDealClick(setShowDealForm);

  if (isLoading) {
    return (
      <BikeListingDetailsSkeleton
        trans={trans}
        responsiveFeatured={responsiveFeatured}
      />
    );
  }

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <MetaTag
            metaKey="usedCarsDetails"
            href={`/${lang}/used-cars/${carDetails?.model?.brand.slug}/${carDetails?.model?.slug}/${carDetails?.year}/${carDetails?.id}`}
            params={{
              '%BRAND%': carDetails?.model?.brand?.name,
              '%MODEL%': carDetails?.model?.name,
              '%YEAR%': carDetails?.year,
            }}
          />
          {carDetails && carDetails.id && (
            <CarListingDetails
              car={carDetails}
              onChatStart={handleChatNow}
              signInModalInfo={{
                isVisible: showModal,
                setVisibility: setShowModal,
              }}
              setImageSrc={setImageSrc}
              handleOpen={handleOpen}
              getUsedDealClick={getDealClick}
              isLoading={isLoading}
              responsiveFeatured={responsiveFeatured}
            />
          )}
          {showDealForm && carDetails && (
            <UsedCarsInquiryForm
              carId={carDetails.id}
              closeDealForm={() => setShowDealForm(false)}
            />
          )}
        </>
      )}
    </>
  );
}
