import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import './ThankYouPage-styles.css';

const ThankYouPage = () => {
  const { lang, trans } = useContext(AppContext);
  const navigate = useNavigate();
  const { sourcePage } = useParams();
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(true);
  const [thankYouMessage, setThankYouMessage] = useState(
    trans['your_information_was_sent_perfectly.'],
  );

  useEffect(() => {
    setIsBackButtonVisible(sourcePage != 'signup');
    setThankYouMessage(
      sourcePage != 'signup'
        ? trans['your_information_was_sent_perfectly.']
        : trans.accountCreatedSuccessfully,
    );
  }, [sourcePage]);

  const goHome = () => navigate(`../${lang}`, { replace: true });
  const goBack = () => navigate(-1);

  return (
    <div
      className="w-100 h-100 d-flex justify-content-center align-items-center"
      style={{ paddingBottom: '110px' }}
    >
      <div
        className="thank-you-page d-flex flex-column justify-content-center align-items-start"
        style={{ gap: '30px' }}
      >
        <h2 className="steps-title">{trans['done!']}</h2>
        <p style={{ fontSize: '34px', fontWeight: '400' }}>{thankYouMessage}</p>
        <div className="buttons-box">
          {isBackButtonVisible && (
            <button type="button" onClick={goBack} className="btn btn-primary">
              {trans.back_to_results}
            </button>
          )}
          <button onClick={goHome} className="btn btn-primary back">
            {trans.back_to_home}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
