import { BorderColor } from '@mui/icons-material';
import { Button, FormControl, TextField, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const InfoBlock = styled('div')(() => ({
  padding: '17px 26px 10px 22px',
  margin: '0 0 10px',
  background: '#fff',
  borderRadius: '3px',
  ':after': {
    content: '" "',
    display: 'block',
    clear: 'both',
  },
  '&.style': {
    margin: '20px 0 40px',
  },
  '&.tabs': {
    '.tab-content': {
      contentVisibility: 'hidden',
    },
    '.tab-content.active': {
      contentVisibility: 'visible',
    },
  },
}));

export const HeaderHolder = styled('div')(() => ({
  overflow: 'hidden',
  margin: '0 0 14px',
  fontSize: '18px',
  lineHeight: '22px',
  color: '#606161',
  fontWeight: 400,
  paddingRight: '80px',
  '&.style': {
    padding: '0 80px 14px 0',
    margin: '0 0 21px',
    borderBottom: '1px solid #606161',
  },
  '@media screen and (max-width: 991px)': {
    fontSize: '14px',
  },
  '.lang-au &': {
    paddingLeft: '80px',
    paddingRight: '0',
    textAlign: 'right',
  },
}));

export const HeaderHolderP = styled('p')(() => ({
  margin: 0,
}));

export const InfoBlockH2 = styled('h2')(() => ({
  margin: 0,
  fontSize: '26px',
  lineHeight: '32px',
  fontWeight: 900,
  color: '#1c2628',
  textTransform: 'uppercase',
  '@media screen and (max-width: 991px)': {
    fontSize: '22px',
  },
}));

export const InfoBlockBtnViewHolder = styled('div')(() => ({
  height: '38px',
  display: ['-webkit-box', '-ms-flexbox', 'flex'],
  '-webkit-box-align': 'center',
  '-ms-flex-align': 'center',
  alignItems: 'center',
  padding: '0 22px',
  borderTop: '1px solid #606161',
  width: '100%',
}));

export const BtnViewHolderView = styled(Link)(() => ({
  display: 'inline-block',
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: 400,
  color: '#606161',
}));

export const InfoBlockRow = styled('div')(() => ({
  '@media screen and (max-width: 991px)': {
    '.row .mb-md-5, .row .my-md-5': {
      marginBottom: '2rem',
    },
  },
}));

export const TabContent = styled('div')(() => ({
  position: 'relative',
}));

export const ViewCarsBox = styled('div')(() => ({
  right: 40,
  position: 'absolute',
  width: '22.5%',
  height: '344px',
  zIndex: 999,
  display: ['-webkit-box', '-ms-flexbox', 'flex'],
  '-webkit-box-align': 'center',
  alignItems: 'center',
  '-webkit-box-pack': 'center',
  '-ms-flex-pack': 'center',
  justifyContent: 'center',
  borderRadius: '5px',
  background: '#fff',
  textAlign: 'center',
  boxShadow: '0 5px 4px 0 rgba(0 0 0 / 15%)',
  '@media screen and (max-width: 1199px)': {
    '.lang-au &': {
      right: 'auto',
      left: '100px',
    },
    height: '332px',
  },
  '@media screen and (max-width: 991px)': {
    right: '130px',
    width: '100px',
    height: '75px',
    marginTop: '-103px',
    '.lang-au &': {
      marginLeft: '100px',
    },
  },
  '@media screen and (max-width: 767px)': {
    display: 'none',
  },
  '@media only screen and (max-width: 821px) and (min-width: 481px)': {
    display: 'none',
  },
  '.lang-au &': {
    right: 'auto',
    left: 40,
  },
}));

export const ViewCarsBoxWrap = styled('div')(() => ({
  position: 'relative',
}));

export const ViewCarsBoxTitle = styled('strong')(() => ({
  margin: '0 0 50px',
  display: 'block',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 900,
  color: '#4e4e4e',
  '@media screen and (max-width: 991px)': {
    margin: '0 0 5px',
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

export const ViewCarsBoxBtn = styled(Link)(() => ({
  display: ['-webkit-inline-box', '-ms-inline-flexbox', 'inline-flex'],
  width: '123px',
  height: '123px',
  '-webkit-box-align': 'center',
  '-ms-flex-align': 'center',
  alignItems: 'center',
  '-webkit-box-pack': 'center',
  '-ms-flex-pack': 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  padding: '10px',
  background: '#00d6bc',
  ':active, :focus, :hover': {
    background: '#04e4cc',
  },
  '@media screen and (max-width: 991px)': {
    width: '33px',
    height: '33px',
    padding: '5px',
  },
}));

export const ViewCarsBoxBtnImg = styled('img')(() => ({
  display: ['-webkit-box', '-ms-flexbox', 'flex'],
  height: 'auto',
  maxWidth: '100%',
  '.lang-au &': {
    transform: 'rotate(180deg)',
  },
}));

export const CarsSliderBlock = styled('div')(() => ({
  margin: '0 -8px',
  position: 'relative',
  ':after': {
    content: '""',
    display: 'block',
    clear: 'both',
  },
  '&.style': {
    margin: '0 -21px',
  },
  '& .slide-holder': {
    padding: '0 8px',
  },
  '& .slide-holder.style': {
    padding: '0 21px',
  },
  '@media screen and (max-width: 1199px)': {
    '&.style': {
      margin: '0 -8px',
    },
    '& .slide-holder.style': {
      padding: '0 8px',
    },
  },
}));

export const HomeUsedCars = styled('div')(() => ({
  boxSizing: 'border-box',
  paddingRight: '25.2%',
  '.lang-au &': {
    paddingRight: '0',
    paddingLeft: '25.2%',
  },
  '@media screen and (max-width: 991px)': {
    paddingRight: '0',
    '.lang-au &': {
      paddingLeft: '0',
    },
  },
}));

export const TabTitlesLi = styled('ul')(() => ({
  '.lang-au &': {
    margin: ' 0 0 0 29px',
  },
}));

export const MainHead = styled('header')(() => ({
  position: 'relative',
  zIndex: 1,
  paddingBlock: '30px 50px',
  color: '#fff',
  fontSize: '18px',
  lineHeight: 1.4,
  backgroundColor: '#00d6bc',
  '.lang-au &': { textAlign: 'right' },
  '&.sell-car-head': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: '20px',
  },
}));

export const MainHeadContainer = styled('div')(() => ({
  paddingInline: '3.5%',
  '.lang-au &': { textAlign: 'right' },
}));

export const ColLg12 = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  paddingRight: '15px',
  paddingLeft: '15px',
  '@media screen and (min-width: 992px)': {
    '-webkit-box-flex': 0,
    '-ms-flex': '0 0 100%',
    flex: '0 0 100%',
    maxWidth: '100%',
  },
}));

export const MainHeadH1 = styled('span')(() => ({
  fontSize: '36px',
  lineHeight: 1.2,
  fontWeight: 700,
  color: '#fff',
  textTransform: 'uppercase',
  '&.sell-car-h1': {
    height: '23px',
    fontSize: '32px',
    fontWeight: 900,
    lineHeight: '28px',
    '@media (max-width: 992px)': {
      fontSize: '24px',
    },
  },
}));

export const MainHeadH3 = styled('h3')(() => ({
  fontSize: '36px',
  lineHeight: 1.2,
  fontWeight: 700,
  color: '#fff',
  textTransform: 'uppercase',
}));

export const MainHeadP = styled('p')(() => ({
  ':lang-au &': {
    textAlign: 'right',
  },
  '&.sell-car-p': {
    height: '9px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '13.5px',
  },
}));

export const Row = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginRight: '-15px',
  marginLeft: '-15px',
  '&.more-article': {
    marginBottom: '30px',
    textAlign: 'center',
    marginTop: '30px',
  },
  '&.more-reviews': {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '20px',
  },
  '&.featured-logo': {
    marginBottom: '30px',
    textAlign: 'center',
  },
  '&.cars-form-holder': {
    alignItems: 'center',
  },
}));

export const SellYourCar = styled('div')(() => ({
  position: 'relative',
  zIndex: 1,
  marginTop: '-25px',
  marginBottom: '50px',
  paddingTop: '30px',
  paddingBottom: '20px',
  paddingLeft: '7%',
  paddingRight: '7%',
  ':before': {
    position: 'absolute',
    zIndex: -1,
    display: 'block',
    content: '""',
    left: '15px',
    right: '15px',
    top: 0,
    bottom: 0,
    backgroundColor: '#fff',
    borderRadius: '10px',
    border: '1px solid #eaeaea',
  },
  '&.financing-block': {
    paddingInline: '2%',
  },
  '&.contact-page': {
    paddingInline: '10%',
  },
  '@media screen and (max-width: 767px)': {
    paddingLeft: '8%',
    paddingRight: '8%',
  },
  '&.aboutus-section': {
    paddingInline: '15px',
  },
  '&.privacy-block': {
    paddingInline: '4.5%',
    '@media (max-width: 991px)': {
      paddingInline: '12.5%',
    },
  },
}));

export const BrowseBrandsSection = styled('section')(() => ({
  backgroundColor: '#fff',
  paddingTop: '15px',
  paddingBottom: '15px',
  marginBottom: '50px',
  '@media screen and (max-width: 767px)': {
    marginBottom: '20px',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
  '&.searchcar': {
    backgroundColor: 'transparent',
  },
}));

export const MainHeadingH2 = styled('h2')(() => ({
  margin: '0 0 15px',
  color: '#1c2628',
  fontSize: '28px',
  lineHeight: 1.4,
  fontWeight: 900,
  '@media screen and (max-width: 767px)': {
    fontSize: '24px',
  },
  '@media screen and (max-width: 575px)': {
    fontSize: '22px',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const MainHeadingH1 = styled('h1')(() => ({
  margin: '0 0 15px',
  color: '#1c2628',
  fontSize: '28px',
  lineHeight: 1.4,
  fontWeight: 900,
  '@media screen and (max-width: 767px)': {
    fontSize: '24px',
  },
  '@media screen and (max-width: 575px)': {
    fontSize: '22px',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const BrowseBrands = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  margin: '0 -1%',
  paddingLeft: '2%',
  paddingRight: '2%',
  '@media screen and (max-width: 767px)': {
    paddingLeft: '3%',
    paddingRight: '3%',
  },
  '@media screen and (max-width: 575px)': {
    paddingLeft: '0',
    paddingRight: '0',
  },
  '&.wishlist-section': {
    marginInline: '-.6%',
    paddingInline: '1%',
    paddingTop: '40px',
    '@media screen and (max-width: 991px)': {
      justifyContent: 'center',
    },
  },
  '&.dealers': {
    gap: '50px',
    justifyContent: 'center',
    '@media screen and (max-width: 560px)': {
      gap: '10px',
    },
  },
}));

export const BrowseBrandsCarInfoColumn = styled('article')(() => ({
  width: '23%',
  background: '#fff',
  margin: '0 1% 2%',
  borderRadius: ' 0 0 3px 3px',
  position: 'relative',
  border: '2px solid transparent',
  boxShadow: '0 5px 5px 0 rgba(0,0,0,0.15)',
  ':hover': {
    borderRadius: '5px',
    borderColor: '#00d6bc',
  },
  '@media screen and (max-width: 1199px)': {
    width: '31.2%',
  },
  '@media screen and (max-width: 991px)': {
    marginBottom: '3%',
  },
  '@media screen and (max-width: 767px)': {
    width: '48%',
  },
  '@media screen and (max-width: 575px)': {
    width: '100%',
    marginRigth: 0,
    marginLeft: 0,
    marginBottom: '25px',
  },
}));

export const CarInfoColumn = styled('div')(() => ({
  width: '100%',
  background: '#fff',
  borderRadius: '0 0 3px 3px',
  margin: '0 0 17px',
  position: 'relative',
  boxShadow: '0 5px 5px 0 rgba(0,0,0,0.15)',
  ':after': {
    content: '""',
    display: 'block',
    clear: 'both',
  },
  '&.deal-page': {
    width: '275px',
    margin: 'unset',
    '@media screen and (max-width: 575px)': {
      width: '100%',
    },
  },
  '&.wishlist-section': {
    marginInline: '.6%',
    maxWidth: '280px',
    minWidth: '280px',
    width: '100%',
  },
}));

export const CarInfoColumnImgHolder = styled('div')(() => ({
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  height: ' 11.5rem',
  '@media screen and (max-width: 480px)': {
    height: '14.5rem',
  },
}));

export const AutoImgBox = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
}));

export const AutoImgBoxImg = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  margin: 'auto',
}));

export const UsedCarDetailsMainImg = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
}));

export const CarInfoColumnTextHolder = styled('div')(() => ({
  padding: '4px 17px 22px',
  borderRadius: '0 0 3px 3px',
  ':after': {
    content: '""',
    display: 'block',
    clear: 'both',
  },
  '@media screen and (max-width: 1199px)': {
    padding: '5px 10px 12px 10px',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const CarInfoColumnTextBox = styled('div')(() => ({
  position: 'relative',
  overflow: 'hidden',
  padding: '0 55px 13px 0',
  '@media screen and (max-width: 1399px)': {
    padding: '0 50px 13px 0',
  },
  '.lang-au &': {
    padding: '0 0 13px 55px',
    textAlign: 'right',
  },
}));

export const CarInfoColumnLogoImg = styled('span')(() => ({
  top: '4px',
  right: '0',
  position: 'absolute',
  width: '40px',
  height: '40px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  '.lang-au &': {
    left: '0',
    right: 'unset',
  },
}));

export const CarInfoColumnLogoImgImg = styled('img')(() => ({
  display: 'block',
  height: 'auto',
  maxWidth: '40px',
  maxHeight: '40px',
}));

export const CarInfoColumnTitleCar = styled('span')(() => ({
  display: 'block',
  margin: 0,
  fontSize: '16px',
  lineHeight: '19px',
  color: '#4e4e4e',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const CarInfoColumnTitleCategory = styled('strong')(() => ({
  display: 'block',
  fontSize: '20px',
  lineHeight: 1.3,
  fontWeight: 700,
  color: '#4e4e4e',
  marginBottom: '3px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '@media screen and (max-width: 1199px)': {
    fontSize: '18px',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const CarInfoColumnPriceHolder = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const CarInfoColumnPriceHolderPrice = styled('span')(() => ({
  display: 'block',
  fontSize: '17px',
  lineHeight: '22px',
  fontWeight: 900,
  color: '#00d6bc',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '.lang-au &': {
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  '@media screen and (max-width: 991px)': {
    fontSize: '14px',
  },
}));

export const CarInfoColumnBtn = styled('span' && Link)(() => ({
  display: 'flex',
  width: '100%',
  height: '41px',
  fontSize: '16px',
  lineHeight: '18px',
  fontWeight: 700,
  color: '#fff',
  borderRadius: '3px',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#00d6bc',
  ':focus, :hover': {
    background: '#04e4cc',
    outline: 'none',
    color: '#fff',
  },
  '@media screen and (max-width: 1199px)': {
    fontSize: '14px',
  },
  '&.btn': {
    width: '95px',
    height: '28px',
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 700,
    '.lang-au &': {
      fontSize: '10px',
    },
    '@media screen and (max-width: 1199px)': {
      width: '90px',
    },
    '@media screen and (max-width: 991px)': {
      width: '118px',
      '.lang-au &': {
        fontSize: '13px',
      },
    },
    '@media screen and (min-width: 821px) ': {
      width: '100px',
    },
    '@media screen and (max-width: 480px)': {
      fontWeight: 400,
      fontSize: '17px',
      width: 'auto',
      padding: '17px 14px 17px 14px',
      '.lang-au &': { fontSize: '17px' },
    },
  },
}));

export const SliderSectionStyle = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  width: '100%',
  padding: '0 15px',
  margin: '-65px auto 50px',
  position: 'relative',
  zIndex: 99,
  maxHeight: 'unset !important',
  '&.new-car-detail-slider-section': {
    backgroundColor: '#f6f6f6',
    marginBottom: '20px',
    maxHeight: '500px',
  },
  '&.get-deals': {
    maxWidth: '100%',
    marginTop: '20px',
    padding: '10px 50px',
    backgroundColor: '#fff',
    marginBottom: 0,
    '@media screen and (max-width: 1199px)': {
      padding: '10px 25px',
    },
    '@media screen and (max-width: 575px)': {
      padding: '25px 15px',
    },
    '.lang-au &': {
      textAlign: 'right',
    },
  },
  '@media screen and (max-width: 1399px)': {
    maxWidth: '100%',
  },
  '@media screen and (max-width: 767px)': {
    marginBottom: '25px',
  },
}));

export const SilderHolder = styled('div')(() => ({
  width: '37%',
  position: 'relative',
  '@media screen and (max-width: 767px)': {
    width: '100%',
    marginBottom: '20px',
  },
  '&.used-detail': {
    width: '62%',
    '@media screen and (max-width: 767px)': {
      width: '100%',
      marginBottom: '30px',
    },
  },
}));

export const SlideContent = styled('div')(() => ({
  backgroundColor: '#fff',
  borderRight: '1px solid #ddd',
  overflow: 'hidden',
  position: 'relative',
  border: '1px solid #ddd',
  maringRight: '1px',
  borderRadius: '10px',
  height: '35rem',
  '@media screen and (max-width: 767px)': {
    height: '14rem',
  },
}));

export const FeaturedDetailsStyled = styled('div')(() => ({
  position: 'relative',
  padding: '30px 30px',
  marginInline: 0,
  backgroundColor: ' #f6f6f6',
  fontSize: '16px',
  lineHeight: 1.6,
  color: '#3d3d3d',
  '@media screen and (max-width: 1199px)': {
    padding: '35px 20px',
    fontSize: '12px',
  },
  '@media screen and (max-width: 991px)': {
    padding: '35px 10px',
    width: '100% !important',
  },
  '@media screen and (max-width: 767px)': {
    marginBottom: '30px',
  },
}));

export const FeaturedDetailsDetail = styled('div')(() => ({
  fontSize: '16px',
}));

export const FeautredDetailsHolder = styled('div')(() => ({
  padding: '20px 20px',
  width: '100%',
}));

export const FeaturedDetailsH2 = styled('h2')(() => ({
  color: '#3d3d3d',
  fontSize: '29px',
  lineHeight: 1.3,
  marginBottom: '15px',
  fontWeight: 800,
  textTransform: 'uppercase',
  '@media screen and (max-width: 1199px)': {
    fontSize: '26px',
    marginBottom: '12px',
  },
  '@media screen and (max-width: 767px)': {
    marginTop: '20px',
    fontSize: '20px',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const FeaturedDetailsStrong = styled('strong')(() => ({
  fontWeight: 800,
}));

export const FeaturedDetailsP = styled('p')(() => ({
  fontSize: '16px',
  marginBottom: '15px',
  '.lang-au &': {
    textAlign: 'right',
  },
  '&.p': {
    '@media screen and (max-width: 991px)': {
      fontSize: '17px',
    },
  },
}));

export const FeaturedDetailsH3 = styled('h3')(() => ({
  color: '#3d3d3d',
  fontSize: '24px',
  lineHeight: 1.3,
  marginBottom: '10px',
  fontWeight: 800,
  textTransform: 'capitalize',
  '@media screen and (max-width: 1199px)': {
    fontSize: '23px',
  },
}));

export const FeaturedFrame = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

export const FeaturedFrameHolder = styled('div')(() => ({
  width: '57%',
  '@media screen and (max-width: 575px)': {
    width: '100%',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const DetailsHeader = styled('header')(() => ({
  position: 'relative',
  zIndex: 9,
  padding: '60px 15px 100px',
  color: '#fff',
  fontSize: '17px',
  lineHeight: 1.6,
  height: '266px',
  ':before': {
    position: 'absolute',
    zIndex: -1,
    backgroundColor: '#00d6bc',
    content: '""',
    left: '-9999px',
    right: '-9999px',
    top: '0',
    bottom: '0',
  },
  '@media screen and (max-width: 991px)': {
    paddingTop: '40px',
    fontSize: '15px',
    height: '219px',
  },
}));

export const DetailsHeaderH1 = styled('div')(() => ({
  margin: 0,
  fontSize: '41px',
  lineHeight: 1,
  textTransform: 'uppercase',
  '@media screen and (max-width: 991px)': {
    fontSize: '35px',
  },
}));

export const DetailsHeaderH2 = styled('span')(() => ({
  margin: '0 0 40px',
  fontSize: '25px',
  lineHeight: 1,
  textTransform: 'capitalize',
  '@media screen and (max-width: 991px)': {
    marginBottom: '20px',
    fontSize: '24px',
  },
  '&.detail-switch': {
    '.lang-au &': {
      float: 'right',
      marginLeft: '5px',
    },
  },
}));

export const DetailsHeaderHolder = styled('div')(() => ({
  '.lang-au &': {
    float: 'right',
    textAlign: 'right',
  },
  '@media screen and (max-width: 991px)': {
    width: '100%',
  },
}));

export const RamadanModelLogo = styled('img')(() => ({
  width: '100px',
  height: '60px',
  objectFit: 'contain',
  maxHeight: '100%',
  '@media screen and (max-width: 600px)': {
    width: '80px',
  },
}));

export const UsedCarsFilterContainer = styled('div')(() => ({
  width: '100%',
  backgroundColor: 'white',
}));

export const UsedCarsFilter = styled('div')(() => ({
  paddingTop: '8px',
}));

export const FeaturedDetailsPage = styled('div')(() => ({
  position: 'relative',
  zIndex: 1,
  marginTop: '-25px',
  marginBottom: '50px',
  padding: '35px 30px 50px',
  ':before': {
    position: 'absolute',
    zIndex: -1,
    display: 'block',
    content: '""',
    left: '15px',
    right: '15px',
    top: 0,
    bottom: 0,
    backgroundColor: '#fff',
    borderRadius: '10px',
    border: '1px solid #eaeaea',
  },
  '&.reviews': {
    paddingInline: '45px',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  '&.articles': {
    paddingInline: '45px',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const SponsoredBadge = styled('span')(() => ({
  top: '10px',
  left: '10px',
  fontSize: '13px',
  lineHeight: '18px',
  fontWeight: '700',
  display: ['-webkit-box', '-ms-flexbox', 'flex'],
  WebkitBoxAlign: 'center',
  msFlexAlign: 'center',
  alignItems: 'center',
  WebkitBoxPack: 'center',
  msFlexPack: 'center',
  justifyContent: 'center',
  position: 'absolute',
  padding: '2px 8px',
  background: 'rgba(255, 255, 255, 0.7)',
  color: '#000',
  zIndex: 100,
  borderRadius: '3px',
  '.lang-au &': {
    top: '10px',
    left: 'auto',
    right: '10px',
  },
  '&.reviews-badge': {
    fontSize: '11px',
    right: '10px',
    left: 'unset',
  },
  '&.reviews-badge-lang': {
    background: '#00d6bc',
    fontSize: '11px',
    opacity: 0.9,
    '.lang-au &': {
      right: 'unset',
      left: '10px',
    },
  },
  '&.badge': {
    top: '4px',
    left: '5px',
    minWidth: '74px',
    height: '22px',
    fontSize: '16px',
    padding: '0',
    borderRadius: '0',
    background: '#00d6bc',
    color: '#fff',
    zIndex: 99,
    '@media screen and (max-width: 1199px)': {
      minWidth: '70px',
      fontSize: '13px',
    },
    '.lang-au &': {
      right: 'unset',
    },
  },
}));

export const GridCardsRental = styled('div')(() => ({
  width: '291px',
  '@media screen and (max-width: 767px)': {
    width: '325px',
  },
}));

export const DealsInfoRow = styled('article')(() => ({
  boxShadow: '0 3px 8px #00000040',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '10px',
  display: 'flex',
  flexWrap: 'wrap',
  minHeight: 'inherit',
  position: 'relative',
  width: '100%',
  '&.test': {
    marginBottom: '10px',
    ':hover': {
      boxShadow: '0 0 0 2px #00d6bc',
      borderRadius: '5px',
    },
  },
  '@media screen and (max-width: 767px)': {
    maxWidth: '400px',
    margin: '0 auto 20px',
  },
}));

export const DealsLeft = styled('div')(() => ({
  width: '52%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  borderRight: '1px solid #ddd',
  '@media screen and (max-width: 767px)': {
    padding: '15px 15px 10px',
    width: '100%',
    borderRight: 'unset',
    borderBottom: '1px solid #ddd',
    display: 'block',
  },
  '.lang-au &': {
    borderLeft: '1px solid #ddd',
    borderRight: 'unset',
  },
  '&.deals-left': {
    width: '53%',
    alignItems: 'center',
    padding: '15px 20px',
    '@media screen and (max-width: 767px)': {
      width: '100%',
    },
    '@media screen and (max-width: 480px)': {
      padding: '0 0 0 0',
    },
  },
}));

export const ImgBox = styled('div')(() => ({
  marginLeft: '2%',
  marginRight: '10px',
  position: 'relative',
  width: '40%',
  height: '150px',
  '@media screen and (max-width: 1199px)': {
    marginLeft: '0',
  },
  '@media screen and (max-width: 991px)': {
    width: '100%',
    margin: '0 auto',
    maxWidth: '225px',
  },
  '@media screen and (max-width: 480px)': {
    maxWidth: '400px',
    height: '14.5rem',
  },
}));

export const DealsAdvantages = styled('div')(() => ({
  flexGrow: 1,
  padding: '12px 10px 10px 20px',
  width: '52%',
  position: 'relative',
  '&.deals-advantages': {
    width: '45%',
    marginTop: '13px',
    '@media screen and (max-width: 1199px)': {
      padding: '0 0 10px',
    },
    '@media screen and (max-width: 991px)': {
      padding: '10px 0 0',
      textAlign: 'left',
      maxWidth: '245px',
      margin: '0 auto',
    },
    '@media screen and (max-width: 480px)': {
      maxWidth: '4444px',
      paddingLeft: '10px',
    },
    '.lang-au &': {
      textAlign: 'right',
    },
  },
  '@media screen and (max-width: 991px)': {
    width: '100%',
  },
  '.lang-au &': {
    paddingInline: '8px 10px',
    '@media screen and (max-width: 991px)': {
      paddingInline: '20px 10px',
    },
  },
}));

export const DealsAdvantagesSmallLogos = styled('div')(() => ({
  position: 'absolute',
  top: '8px',
  right: '30px',
  maxWidth: '90px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  '&.small-logos': {
    top: '15px',
    right: '0',
    maxWidth: '65px',
    '.lang-au &': {
      right: 'auto',
      left: 0,
    },
  },
  '@media screen and (max-width: 480px)': {
    right: 0,
    '.lang-au &': {
      left: 0,
      right: 'unset',
    },
  },
  '.lang-au &': {
    left: '30px',
    right: 'unset',
  },
}));

export const DealsAdvantagesSmallLogosImg = styled('img')(() => ({
  maxHeight: '50px',
  '@media screen and (max-width: 480px)': {
    maxHeight: '40px',
    paddingRight: '10px',
  },
  '@media screen and (max-width: 479px)': {
    '.lang-au &': {
      paddingLeft: '10px',
    },
  },
}));

export const DealsAdvantagesDealTitle = styled('strong')(() => ({
  display: 'block',
  fontSize: '17px',
  lineHeight: 1.3,
  paddingRight: '115px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  '@media screen and (max-width: 1199px)': {
    fontSize: '15px',
  },
  '@media screen and (max-width: 767px)': {
    fontSize: '17px',
  },
  '@media screen and (max-width: 480px)': {
    textOverflow: 'initial',
    fontSize: '16px',
  },
  '.lang-au &': {
    textAlign: 'right',
    paddingRight: 0,
    paddingLeft: '85px',
  },
}));

export const DealsAdvantagesH3 = styled('h3')(() => ({
  fontSize: '20px',
  lineHeight: 1.3,
  fontWeight: 700,
  overflow: 'hidden',
  margin: '0 75px 0 0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '@media screen and (max-width: 1199px)': {
    fontSize: '22px',
  },
  '@media screen and (max-width: 767px)': {
    fontSize: '24px',
  },
  '@media screen and (max-width: 480px)': {
    textOverflow: 'initial',
    fontSize: '18px',
  },
  '.lang-au &': {
    textAlign: 'right',
    marginRight: '0',
    marginLeft: '65px',
  },
}));

export const DealsNav = styled('ul')(() => ({
  fontSize: '14px',
  lineHeight: 1.6,
  paddingTop: '40px',
  position: 'relative',
  '@media screen and (max-width: 1199px)': {
    fontSize: '14px',
  },
  '&.deals-nav': {
    paddingTop: '10px',
    '@media screen and (max-width: 480px)': {
      paddingTop: '40px',
      zIndex: 300,
      display: 'none',
    },
    '.lang-au &': {
      marginTop: '17px',
      width: '100%',
    },
  },
}));

export const DealsNavLi = styled('li')(() => ({
  marginBottom: '9px',
  display: 'inline-block',
  verticalAlign: 'top',
  width: '49%',
  paddingInline: '45px 10px',
  position: 'relative',
  '@media screen and (max-width: 1199px)': {
    paddingLeft: '38px',
  },
  '.lang-au &': {
    '@media screen and (max-width: 991px)': {
      paddingLeft: '0',
    },
    position: 'relative',
    textAlign: 'right',
  },
  '&.deals-nav-li': {
    marginBottom: '10px',
    display: 'block',
    width: '100%',
    paddingInline: 0,
    color: '#b3b3b3',
  },
}));

export const DealsRight = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '20px 25px 15px',
  width: '48%',
  position: 'relative',
  justifyContent: 'space-between',
  '@media screen and (max-width: 1199px)': {
    padding: '15px',
  },
  '@media screen and (max-width: 767px)': {
    width: '100%',
  },
  '@media screen and (max-width: 480px)': {
    width: '100%',
    padding: '5px 0 15px',
  },
  '&.deals-rights': {
    width: '47%',
    minHeight: 'inherit',
    paddingTop: '15px',
    alignItems: 'center',
    '@media screen and (max-width: 767px)': {
      alignItems: 'flex-start',
      width: '100%',
    },
    '.lang-au &': {
      '@media screen and (max-width: 480px)': {
        padding: '5px 10px 15px',
        width: '100%',
      },
    },
  },
}));

export const DealsRightBox = styled('div')(() => ({
  width: '65%',
  paddingRight: '12px',
  '@media screen and (max-width: 991px)': {
    width: '100%',
    '.lang-au &': {
      width: '100%',
    },
  },
  '@media screen and (max-width: 767px)': {
    '.lang-au &': {
      width: '60%',
    },
  },
  '@media screen and (max-width: 480px)': {
    paddingRight: '20px',
    paddingLeft: '20px',
    width: '100%',
  },
  '&.box': {
    width: '65%',
    '.lang-au &': {
      paddingLeft: '12px',
      paddingRight: '0',
    },
    '@media screen and (max-width: 1199px)': {
      paddingRight: '15px',
      width: '65%',
    },
    '@media screen and (max-width: 991px)': {
      width: '100%',
      paddingRight: 0,
    },
    '@media screen and (max-width: 767px)': {
      width: '60%',
    },
    '@media screen and (max-width: 480px)': {
      '.lang-au &': {
        marginRight: '-19px',
      },
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
}));

export const CarListInfo = styled('dl')(() => ({
  fontSize: '15px',
  lineHeight: 1.5,
  marginLeft: '7%',
  '@media screen and (max-width: 1199px)': {
    marginLeft: 0,
  },
  '.lang-au &': {
    '@media screen and (max-width: 767px)': {
      marginLeft: '0',
      marginRight: '0%',
    },
    float: 'right',
    marginLeft: '2%',
    paddingRight: '23px',
  },
  '@media screen and (max-width: 480px)': {
    fontSize: '12px',
  },
}));

export const CarListInfoDt = styled('dt')(() => ({
  float: 'left',
  minWidth: '90px',
  maxWidth: '90px',
  marginRight: '10px',
  clear: 'both',
  '.lang-au &': {
    float: 'right',
  },
}));

export const CarListInfoDd = styled('dd')(() => ({
  overflow: 'hidden',
  fontWeight: 700,
  paddingRight: '6px',
}));

export const DealsRightFrame = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexWrap: 'wrap',
  height: '80%',
  justifyContent: 'center',
  marginTop: '15px',
  paddingTop: '0',
  position: 'relative',
  textAlign: 'center',
  width: '35%',
  zIndex: 1,
  '@media screen and (max-width: 991px)': {
    width: '100%',
    height: '50%',
  },
  '@media screen and (max-width: 767px)': {
    flexDirection: 'row',
  },
  '@media screen and (max-width: 480px)': {
    paddingLeft: '10px',
    paddingRight: '10px',
    height: 'auto',
    paddingTop: '5px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  '.lang-au &': {
    '@media screen and (max-width: 767px)': {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      margingTop: '15px',
      position: 'relative',
      textAlign: 'center',
      zInder: '1',
    },
  },
}));

export const DealsRightFrameStrong = styled('strong')(() => ({
  '.lang-au &': {
    fontSize: '19px',
    '@media screen and (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      fontSize: '22px',
    },
    '@media screen and (max-width: 480px)': {
      color: '#00d6bc',
      fontSize: '18px',
      textAlign: 'right',
      width: '100%',
    },
    '@media screen and (max-width: 417px)': {
      fontSize: '14px',
    },
  },
  display: 'block',
  fontSize: '21px',
  fontWeight: 900,
  marginBottom: '15px',
  maxWidth: '165px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '@media screen and (max-width: 767px)': {
    fontSize: '22px',
  },
  '@media screen and (max-width: 480px)': {
    textOverflow: 'clip',
    paddingTop: '10px',
    width: '50%',
    textAlign: 'left',
    fontSize: '18px',
    color: '#00d6bc',
  },
  '@media screen and (max-width: 417px)': {
    fontSize: '16px',
  },
}));

export const DealsRightFrameQarSpan = styled('span')(() => ({
  '.lang-au &': {
    float: 'left',
    marginRight: '5px',
  },
}));

export const DealsRightFrameEveryDaySpan = styled('span')(() => ({
  fontSize: '16px',
  fontWeight: 'normal',
  marginLeft: '5px',
}));

export const DealsRightBtn = styled(Link)(() => ({
  '.lang-au &': {
    '@media screen and (max-width: 480px)': {
      maxWidth: '140px',
    },
    '@media screen and (max-width: 417px)': {
      maxWidth: '125px',
      fontSize: '14px',
    },
  },
  minWidth: 'inherit',
  width: '100%',
  maxWidth: '160px',
  '@media screen and (max-width: 480px)': {
    borderRadius: '4px',
  },
  '@media screen and (max-width: 417px)': {
    maxWidth: '120px',
    fontSize: '16px',
  },
}));

export const MoreArticleH2 = styled('h2')(() => ({
  textTransform: 'uppercase',
  fontSize: '25px',
  fontWeight: 700,
}));

export const PrivacyContent = styled('div')(() => ({
  lineHeight: 1.6,
  maxWidth: '70%',
  '@media screen and (max-width: 575px)': {
    lineHeight: '1.6',
    maxWidth: '95%',
  },
  '.lang-au &': {
    textAlign: 'right',
  },
}));

export const PrivacyContentP = styled('p')(() => ({
  marginBottom: '20px',
}));

export const PrivacyContentB = styled('b')(() => ({
  fontWeight: 700,
}));

export const PrivacyContentA = styled('a')(() => ({
  color: '#00D6BC',
  ':hover': {
    textDecoration: 'underline',
  },
}));

export const PrivacyContentHolder = styled('div')(() => ({
  position: 'relative',
  padding: '35px 0 35px 20px',
}));

export const PrivacyTitle = styled('h2')(() => ({
  fontSize: '28px',
  lineHeight: '1.2',
  marginBottom: '20px',
  textTransform: 'capitalize',
  fontWeight: 700,
  position: 'relative',
  ':before': {
    position: 'absolute',
    content: '""',
    left: '-25px',
    top: '-5px',
    bottom: '-5px',
    width: '6px',
    backgroundColor: '#00D6BC',
  },
  '.lang-au &': {
    ':before': {
      right: '-25px',
      left: 'unset',
    },
  },
}));

export const PrivacyNav = styled('ul')(() => ({
  paddingLeft: '15px',
  marginBottom: '30px',
  '&.privacy-nav': {
    paddingTop: '10px',
    marginLeft: '30px',
  },
}));

export const PrivacyNavLi = styled('li')(() => ({
  position: 'relative',
  marginBottom: '5px',
  ':before': {
    position: 'absolute',
    left: '-15px',
    top: '10px',
    width: '6px',
    height: '6px',
    content: '""',
    backgroundColor: '#3D3D3D',
    borderRadius: '100%',
  },
  '.lang-au &': {
    ':before': {
      left: 'unset',
      right: '-15px',
    },
  },
  '&.privacy-nav': {
    paddingTop: '10px',
    marginLeft: '30px',
  },
}));

export const PrivacyNavLiStrong = styled('strong')(() => ({
  fontWeight: 700,
}));

export const DownloadAppStyle = styled('div')(() => ({
  position: 'relative',
  display: 'inline-block',
  verticalAlign: 'middle',
  marginRight: '15px',
  ':hover': {
    color: '#00D6BC',
    cursor: 'pointer',
    div: {
      display: 'block',
    },
  },
  '@media (max-width: 480px)': {
    display: 'none',
  },
}));

export const DownloadAppStrongStyle = styled('strong')(() => ({
  textTransform: 'uppercase',
  fontSize: '11px',
  fontWeight: 800,
  letterSpacing: '1px',
  zIndex: 99999,
  '.lang-au &': {
    marginRight: 0,
    marginLeft: '15px',
    '@media (max-width: 1199px)': {
      float: 'right',
      marginTop: '5px',
    },
  },
}));

export const DownloadAppDropStyle = styled('div')(() => ({
  position: 'absolute',
  display: 'none',
  width: '150px',
  left: '50%',
  top: '100%',
  paddingTop: '17px',
  color: '#848484',
  textAlign: 'center',
  transform: 'translateX(-50%)',
}));

export const DownloadAppDropHolderStyle = styled('div')(() => ({
  boxShadow: '0 3px 10px 0 rgb(0 0 0 / 15%)',
  backgroundColor: '#fff',
  padding: '10px',
}));

export const DownloadAppDropPStyle = styled('p')(() => ({
  fontSize: '9px',
  borderTop: '1px solid #ddd',
  paddingBlock: '7px',
}));

export const SliderHolder = styled('div')(() => ({
  width: '37%',
  position: 'relative',
  '&.used-detail': {
    borderRight: '1px solid #ddd',
    marginRight: '1px',
    borderRadius: '10px',
    height: '35rem',
  },
  '&.rent-get-deals': {
    width: '62%',
    marginRight: 'unset',
    '@media screen and (max-width: 1199px)': {
      width: '100%',
      marginBottom: '30px',
    },
  },
  '&.get-deals': {
    width: '62%',
    marginRight: 'unset',
    '@media screen and (max-width: 1199px)': {
      width: '100%',
      marginBottom: '30px',
      height: 'unset',
    },
  },
  '@media screen and (max-width: 767px)': {
    width: '100%',
    marginBottom: '20px',
  },
}));

export const MainDealerIcon = styled('span')(() => ({
  bottom: 0,
  margin: '0 auto',
  width: '100%',
  height: '22px',
  fontSize: '16px',
  lineHeight: '18px',
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  borderRadius: 0,
  padding: 0,
  color: 'black',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  verticalAlign: 'baseline',
  zIndex: 99,
  transition:
    'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
}));
export const FormControlStyled = styled(FormControl)(() => ({
  '&.sell-your-car-vin-search-bar-form': {
    display: 'flex ',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '901px',
    height: '64px',
    p: {
      '.lang-au &': {
        textAlign: 'right',
      },
    },
    '@media screen and (max-width: 1200px)': {
      width: '100%',
      flexDirection: 'column',
      gap: '10px',
      height: '136px',
    },
  },
}));
export const TextFieldStyled = styled(TextField)(({ lang }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '12px',
    height: '66px',
    '@media screen and (max-width: 1200px)': {
      height: '54px',
    },
  },
  '& .MuiFormLabel-root': {
    transformOrigin: lang === 'ar' ? 'top right' : 'top left',
    right: lang === 'ar' ? '30px' : 'auto',
    left: lang === 'ar' ? 'auto' : '0',
  },
  '& .MuiInputBase-root': {
    borderRadius: '12px',
    ':before': {
      borderRadius: '12px',
    },
  },
}));
export const ButtonStyled = styled(Button)(() => ({
  '&.sell-your-car-vin-search-button': {
    width: '162px',
    height: '64px',
    padding: '6px 18px 6px 18px',
    borderRadius: '12px',
    color: 'white',
    background: '#00D6BC',
    border: '1px solid #00a187',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#00a187',
    },
    '@media screen and (max-width: 1200px)': {
      width: '100%',
      height: '54px',
    },
  },
}));
