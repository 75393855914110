import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import Banner from '../../Banners/BannerComponent';
import { useBrands } from '../../../common/hooks/brand.hooks.';
import { useModels } from '../../../common/hooks/model.hooks';
import { TType as TModelCarType } from '../../../types/resources/cars';
import {
  CarOptions,
  CarOptionsLabel,
  CarOptionsLi,
  FakeInput,
  FakeLabel,
  FakeSelect,
  FakeSelectSelect,
  Input,
  SelectLists,
  SelectListsLi,
  Visual,
  VisualBtnSearch,
  VisualCarFindHolder,
  VisualCarFindHolderH2,
  VisualContainer,
  VisualH1,
} from './HomeVisualStyledComponent';
import LazyLoad from 'react-lazyload';

export default function HomeVisual() {
  const navigate = useNavigate();
  const { lang, trans } = useContext(AppContext);
  const [isSearchNewCars, setIsSearchNewCars] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState<number | undefined>();
  const [selectedModel, setSelectedModel] = useState<number | undefined>();
  const [selectedType, setSelectedType] = useState<number | undefined>();
  const [filteredTypes, setFilteredTypes] = useState<TModelCarType[]>([]);

  const CustomDot = (props: any) => {
    const isActive = props.active;
    const titleToDisplay =
      props.bannerTitles && props.bannerTitles[props.index] !== undefined
        ? props.bannerTitles[props.index]
        : null;

    return titleToDisplay ? (
      <li
        key={Math.random()}
        className={isActive ? 'active' : 'inactive'}
        onClick={() => props.onClick()}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <span className="text-hold">
          <span className="txt">{titleToDisplay}</span>
        </span>
        <span className="border"></span>
      </li>
    ) : (
      <></>
    );
  };

  const cachedBanner = useMemo(
    () => (
      <Banner
        redirect={true}
        className="home-banner"
        section_name="A1"
        multiple={true}
        customDot={CustomDot}
      />
    ),
    [],
  );

  const { data: brands } = useBrands({
    params: {
      type: isSearchNewCars ? 'new' : 'used',
    },
  });

  const { data: models } = useModels({
    brand: selectedBrand,
    type: isSearchNewCars ? 'new' : 'used',
  });

  const toggleSearchCarsFlag = () => {
    setSelectedBrand(undefined);
    setSelectedModel(undefined);
    setSelectedType(undefined);
    setIsSearchNewCars(!isSearchNewCars);
  };

  const handleSearch = () => {
    let url = `/${lang}`;

    if (isSearchNewCars) {
      url += `/new-cars`;

      if (selectedBrand)
        url += `/${brands?.find(m => m.id === selectedBrand)?.slug}`;

      if (selectedModel)
        url += `/${models?.find(m => m.id === selectedModel)?.slug}`;

      navigate(url);
    } else {
      const searchParams = new URLSearchParams();
      url += `/used-cars`;

      if (selectedBrand) {
        searchParams.append('brand', selectedBrand.toString());

        if (selectedModel) {
          searchParams.append('model', selectedModel.toString());

          if (selectedType || filteredTypes.length === 1) {
            searchParams.append(
              'type',
              selectedType?.toString() ?? filteredTypes[0].id.toString(),
            );
          }
        }
        return navigate(`${url}?${searchParams.toString()}`);
      }
      navigate(url);
    }
  };

  const handleBrandChange = (e: any) => {
    setFilteredTypes([]);
    setSelectedModel(undefined);
    setSelectedBrand(parseInt(e.target.value));
  };

  const handleModelChange = (e: any) => {
    const modelId = parseInt(e.target.value);
    const types = models?.find(m => m.id === modelId)?.types;
    setFilteredTypes(types ?? []);
    setSelectedType(undefined);
    setSelectedModel(modelId);
  };

  const handleTypeChange = (e: any) => {
    setSelectedType(parseInt(e.target.value));
  };

  return (
    <>
      <VisualContainer>
        <Visual style={{ backgroundImage: 'url(/images/banner - image.jpg)' }}>
          <LazyLoad>
            <div className="container">
              {cachedBanner}
              <VisualCarFindHolder>
                <form>
                  <VisualH1>{trans.find_car}</VisualH1>
                  <VisualCarFindHolderH2>
                    {trans.looking_for}
                  </VisualCarFindHolderH2>
                  <CarOptions>
                    <CarOptionsLi key={Math.random()}>
                      <CarOptionsLabel>
                        <Input
                          id="radio-1"
                          type="radio"
                          onChange={toggleSearchCarsFlag}
                          checked={isSearchNewCars}
                        />
                        <FakeInput>
                          <FakeLabel>{trans.new_cars}</FakeLabel>
                        </FakeInput>
                      </CarOptionsLabel>
                    </CarOptionsLi>
                    <CarOptionsLi key={Math.random()}>
                      <CarOptionsLabel htmlFor="radio-2">
                        <Input
                          id="radio-2"
                          type="radio"
                          onChange={toggleSearchCarsFlag}
                          checked={!isSearchNewCars}
                        />
                        <FakeInput>
                          <FakeLabel>{trans.used_cars}</FakeLabel>
                        </FakeInput>
                      </CarOptionsLabel>
                    </CarOptionsLi>
                  </CarOptions>
                  <SelectLists>
                    <SelectListsLi key={Math.random()}>
                      <FakeSelect>
                        <FakeSelectSelect
                          id="brand_id"
                          name="brand_id"
                          className="select"
                          value={selectedBrand}
                          onChange={handleBrandChange}
                        >
                          <option value="">{trans.select_brand}</option>
                          {brands?.map(brand => (
                            <option key={brand.id} value={brand.id}>
                              {brand.name}
                            </option>
                          ))}
                        </FakeSelectSelect>
                      </FakeSelect>
                    </SelectListsLi>
                    <SelectListsLi key={Math.random()}>
                      <FakeSelect>
                        <FakeSelectSelect
                          className="select"
                          name="model_id"
                          id="model_id"
                          value={selectedModel}
                          onChange={handleModelChange}
                        >
                          <option value="">{trans.select_model}</option>
                          {models?.map(model => (
                            <option key={model.id} value={model.id}>
                              {model.name}
                            </option>
                          ))}
                        </FakeSelectSelect>
                      </FakeSelect>
                    </SelectListsLi>
                    {!isSearchNewCars && (
                      <SelectListsLi key={Math.random()}>
                        <FakeSelect>
                          <FakeSelectSelect
                            className="select"
                            name="type_id"
                            id="type_id"
                            value={
                              filteredTypes.length === 1
                                ? filteredTypes[0].id
                                : selectedType
                            }
                            onChange={handleTypeChange}
                          >
                            <option value="">{trans.select_type}</option>
                            {filteredTypes.map((type: any) => (
                              <option key={type.id} value={type.id}>
                                {type.name}
                              </option>
                            ))}
                          </FakeSelectSelect>
                        </FakeSelect>
                      </SelectListsLi>
                    )}
                  </SelectLists>
                  <VisualBtnSearch
                    type="button"
                    className="btn-search"
                    onClick={handleSearch}
                  >
                    {trans.search}
                  </VisualBtnSearch>
                </form>
              </VisualCarFindHolder>
            </div>
          </LazyLoad>
        </Visual>
      </VisualContainer>
    </>
  );
}
