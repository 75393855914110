import { useContext } from 'react';
import LazyImage from '../../../Other/LazyImageComponent';
import { AppContext } from '../../../../contexts/AppContext';
import Logo from '../../../../common/images/SellYourCarModalImage.png';
import './SellYourCarFirstPageModal-styles.css';
import { Button, styled } from '@mui/material';
import { VinDecoder } from '../../../UsedCars/apis/usedCarsApi';
import { useNavigate } from 'react-router-dom';

interface ISellYourCarFirstPageModalProps {
  toggleSignInDialog: (value: boolean) => void;
  vin: string;
  setError: (value: boolean) => void;
  setIsLoading: (value: boolean) => void;
}

export const SellYourCarFirstPageModal = ({
  toggleSignInDialog,
  vin,
  setError,
  setIsLoading,
}: ISellYourCarFirstPageModalProps) => {
  const { trans, lang } = useContext(AppContext);
  const navigate = useNavigate();

  const handleClick = async () => {
    setIsLoading(true);
    toggleSignInDialog(false);
    try {
      const result = await VinDecoder(vin);
      setIsLoading(false);
      if (result) {
        setError(false);
        navigate(`/${lang}/sell-your-car/step-two`, {
          state: { ...result, vin },
        });
      } else if (!result) {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const ButtonStyled = styled(Button)(() => ({
    width: '400px',
    height: '64px',
    borderRadius: '12px',
    background: '#00D6BC',
    color: 'white',
    textTransform: 'uppercase',
    '&:hover': {
      background: '#00a187',
    },
    '@media (max-width: 768px)': {
      width: '350px',
    },
    '@media (max-width: 480px)': {
      width: '300px',
    },
    '@media (max-width: 343px)': {
      width: '100%',
    },
  }));

  return (
    <div className="modal fade modal-center advance-search-modal loginpopup show">
      <div
        className="modal-dialog modal-dialog-signup modal-lg style"
        style={{
          maxWidth: '480px',
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => toggleSignInDialog(false)}
              >
                ×
              </span>
            </button>
          </div>
          <div className="sell-your-car-modal-body">
            <LazyImage
              src={Logo}
              alt="login icon"
              defaultSrc=""
              style={{
                width: '75px',
                height: '110px',
              }}
            />
            <h2>{trans.Confirm_car_id_Number}</h2>
            <span>{vin}</span>
            <div className="sell-your-car-modal-buttons">
              <ButtonStyled onClick={handleClick}>
                {trans.sell_confirm}
              </ButtonStyled>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
