import { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { isValidEmail } from '../../../Helper';
import { signUp } from '../../../services/ApiService';
import Banner from '../../Banners/BannerComponent';
import PageLoader from '../../Other/PageLoader/PageLoaderComponent';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { User } from '../../../types';
import '../SignUp/signup-styles.css';
import '../../User/LogIn/login-styles.css';

export default function SignUp(this: any) {
  const { lang, trans, setUserData } = useContext(AppContext);
  const [showPageLoader, setShowPageLoader] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState([]);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [honeypotFieldValue, setHoneypotFieldValue] = useState('');
  const navigate = useNavigate();

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 995;
  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 100);
  }, []);

  const handleFirstNameChange = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: any) => {
    setLastName(e.target.value);
  };
  const handleHoneypotFieldChange = (e: any) => {
    setHoneypotFieldValue(e.target.value);
  };

  const handlePhoneNumberChange = (e: any) => {
    setPhoneNumber(e);
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const submitUser = async () => {
    setShowPageLoader(true);

    setFirstNameError('');
    setLastNameError('');
    setPhoneNumberError('');
    setEmailError('');
    setPasswordError('');
    setConfirmPasswordError('');
    setError([]);

    let form: any = {};

    form.first_name = firstName;
    form.last_name = lastName;
    form.email = email;
    form.mobile_number = phoneNumber;
    form.password = password;

    const isValid = ValidateRequiredFields(form);
    if (!isValid) {
      setShowPageLoader(false);
      return;
    }

    try {
      const response = await signUp(form);
      if (response.error) {
        setError(response.error);
      } else if (response) {
        const accessToken = response.access_token;
        const refreshToken = response.refresh_token;

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        const user: User = JSON.parse(atob(accessToken.split('.')[1]));

        setUserData(user);

        navigate(`/${lang}/verify-phone`);
      }
    } catch (error: any) {
      const { data } = error.response;
      setEmailError(data.email);
      setPhoneNumberError(data.mobile_number);
      if (data.message && Array.isArray(data.message)) {
        data.message.map((message: string) => {
          if (message.toLowerCase().startsWith('password')) {
            setPasswordError(message);
            return () => {};
          }
        });
      } else {
        setError(data.messages ? data.messages : data.message);
      }
    } finally {
      setShowPageLoader(false);
    }
  };

  const ValidateRequiredFields = (form: any) => {
    let isValid = true;

    if (form.first_name === '') {
      setFirstNameError(trans.firstname_required);
      isValid = false;
    }
    if (form.last_name === '') {
      setLastNameError(trans.lastname_required);
      isValid = false;
    }
    if (form.mobile === '') {
      setPhoneNumberError(trans.mobile_required);
      isValid = false;
    }
    if (form.email === '') {
      setEmailError(trans.email_required);
      isValid = false;
    } else if (!form.email.match(isValidEmail)) {
      setEmailError(trans.email_invalid);
      isValid = false;
    }
    if (form.password === '') {
      setPasswordError(trans.password_required);
      isValid = false;
    } else if (form.password.length < 8) {
      // setPasswordError(trans.password_length);
      // isValid = false;
    }
    if (confirmPassword === '') {
      setConfirmPasswordError(trans.confirmpassword_required);
      isValid = false;
    }
    if (form.password !== confirmPassword) {
      setPasswordError(trans.passwords_dont_match);
      setConfirmPasswordError(trans.passwords_dont_match);
      isValid = false;
    }

    return isValid;
  };

  return (
    <>
      <MetaTag href={`/${lang}/register`} metaKey="register" />
      {showPageLoader && <PageLoader />}
      <main id="main">
        <div className="container">
          <div className="row">
            <div className="col-12 signup-col">
              {!isMobile && (
                <Banner className="signup-banner" section_name="A3" />
              )}
              <div className="signup-holder">
                <div className="signup-box">
                  <div className="login-logo">
                    <img src="/images/logo.jpg" />
                  </div>
                  <h2>{trans.sign_up}</h2>
                  <form className="login-form row">
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        maxLength={255}
                        placeholder={trans.first_name}
                        value={firstName}
                        onChange={handleFirstNameChange}
                      />
                      <span className="errorMessage">{firstNameError}</span>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        maxLength={255}
                        placeholder={trans.last_name}
                        value={lastName}
                        onChange={handleLastNameChange}
                      />
                      <span className="errorMessage">{lastNameError}</span>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="email"
                        className="form-control"
                        maxLength={255}
                        placeholder={trans.email}
                        value={email}
                        onChange={handleEmailChange}
                      />
                      <span className="errorMessage">{emailError}</span>
                    </div>
                    <div className="form-group col-md-6">
                      <PhoneInput
                        country="qa"
                        inputClass="custom-tel"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        placeholder=""
                        masks={{ qa: '........' }}
                      />
                      <span className="errorMessage">{phoneNumberError}</span>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="password"
                        className="form-control"
                        maxLength={255}
                        placeholder={trans.password}
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <span className="errorMessage">{passwordError}</span>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="password"
                        className="form-control"
                        maxLength={255}
                        placeholder={trans.confirm_password}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      <span className="errorMessage">
                        {confirmPasswordError}
                      </span>
                    </div>
                    <div className="form-group col-md-12">
                      <span className="errorMessage">
                        {typeof error === 'object'
                          ? error.join(', ')
                          : error === 'Server Error'
                          ? 'Server Error, please check your mobile number and try again'
                          : error}
                      </span>
                    </div>
                    <div className="form-group button col-12 d-none">
                      <input
                        name="id_number"
                        tabIndex={-1}
                        autoComplete="nope"
                        type="text"
                        className="form-control"
                        maxLength={255}
                        placeholder="Full Name"
                        value={honeypotFieldValue}
                        onChange={handleHoneypotFieldChange}
                      />
                    </div>

                    <div className="form-group button col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={submitUser}
                      >
                        {trans.sign_up}
                      </button>
                    </div>
                    <div className="already-login col-12">
                      <p>
                        {trans.already_account}{' '}
                        <Link to={`/${lang}/login`}>{trans.login}</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              {isMobile && (
                <Banner className="signup-banner" section_name="A3" />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
