import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { AppContext } from '../../../contexts/AppContext';
import PageLoader from '../../Other/PageLoader/PageLoaderComponent';
import { isValidEmail } from '../../../Helper';
import { postRentCarInquiry } from '../apis';
import { useRentCarDetails } from '../hooks';
import { IRentalCar } from '../../../types/cars/rental_car';
import '../../../styles/css/steps.css';

// ----------------- Will be used in the future -----------------
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

interface RentFromProps {
  car: IRentalCar;
  onCloseRentForm: () => void;
}

export const RentForm = (props: RentFromProps) => {
  const { lang, trans } = useContext(AppContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const params = useParams();
  const { data: carDetails } = useRentCarDetails(
    params && params.id ? Number(params.id) : Number(params.carId),
  );

  // ----------------- Will be used in the future -----------------
  // const [from, setFrom] = useState('');
  // const [to, setTo] = useState('');
  // const [pickUpLocation, setPickUpLocation] = useState<number | null>(null);
  // const [dropOffLocation, setDropOffLocation] = useState<number | null>(null);
  // const [openStartDate, setOpenStartDate] = useState(false);
  // const [openEndDate, setOpenEndDate] = useState(false);

  // const rentalsLocation = carDetails?.location || undefined;

  const ref1 = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const ref3 = useRef<any>(null);
  // ----------------- Will be used in the future -----------------
  // const ref4 = useRef<any>(null);
  // const ref5 = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [showPageLoader, setShowPageLoader] = useState(false);

  // ----------------- Will be used in the future -----------------
  // const handleStartDateChange = (date: any) => {
  //   setFrom(date);
  //   setOpenStartDate(false);
  //   setOpenEndDate(true);
  // };

  // const handleEndDateChange = (date: any) => {
  //   setTo(date);
  //   setOpenEndDate(false);
  // };

  // const handleOutsideClick = () => {
  //   setOpenStartDate(false);
  //   setOpenEndDate(false);
  // };

  useEffect(() => {
    ref1?.current.focus();
  });

  const handlePhoneNumberInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPhoneNumber(event.target.value.slice(0, 8));
  };

  useEffect(() => {
    if (fullName === '' || undefined) {
      setFirstName('');
      setLastName('');
    }
    const words = fullName.split(' ');
    const newFirstName = words[0];
    words.splice(0, 1);
    const newLastName = words.join(' ');
    setFirstName(newFirstName);
    if (newLastName.length === 0 || newLastName === undefined) setLastName('-');
    else setLastName(newLastName);
  }, [fullName]);

  const goToStep = (num: any) => {
    switch (num) {
      case 1:
        var activeSteps = document.getElementsByClassName('active');
        if (activeSteps && activeSteps.length > 0) {
          Array.from(activeSteps).forEach((activeStep: any) => {
            activeStep.classList.remove('active');
          });
        }

        document.getElementById('step1')?.classList.add('active');
        document.getElementById('target_step1')?.classList.add('active');
        ref1?.current.focus();
        break;
      case 2:
        var activeSteps = document.getElementsByClassName('active');
        if (activeSteps && activeSteps.length > 0) {
          Array.from(activeSteps).forEach((activeStep: any) => {
            activeStep.classList.remove('active');
          });
        }

        document.getElementById('step2')?.classList.add('active');
        document.getElementById('target_step1')?.classList.add('active');
        document.getElementById('target_step2')?.classList.add('active');
        ref2?.current.focus();
        break;
      case 3:
        var activeSteps = document.getElementsByClassName('active');
        if (activeSteps && activeSteps.length > 0) {
          Array.from(activeSteps).forEach((activeStep: any) => {
            activeStep.classList.remove('active');
          });
        }

        document.getElementById('step3')?.classList.add('active');
        document.getElementById('target_step1')?.classList.add('active');
        document.getElementById('target_step2')?.classList.add('active');
        document.getElementById('target_step3')?.classList.add('active');
        ref3?.current.focus();
        break;
      // ----------------- Will be used in the future -----------------
      // case 4:
      //   var activeSteps = document.getElementsByClassName('active');
      //   if (activeSteps && activeSteps.length > 0) {
      //     Array.from(activeSteps).forEach((activeStep: any) => {
      //       activeStep.classList.remove('active');
      //     });
      //   }

      //   document.getElementById('step4')?.classList.add('active');
      //   document.getElementById('target_step1')?.classList.add('active');
      //   document.getElementById('target_step2')?.classList.add('active');
      //   document.getElementById('target_step3')?.classList.add('active');
      //   document.getElementById('target_step4')?.classList.add('active');
      //   ref4?.current?.focus();
      //   break;
      // case 5:
      //   var activeSteps = document.getElementsByClassName('active');
      //   if (activeSteps && activeSteps.length > 0) {
      //     Array.from(activeSteps).forEach((activeStep: any) => {
      //       activeStep.classList.remove('active');
      //     });
      //   }

      //   document.getElementById('step5')?.classList.add('active');
      //   document.getElementById('target_step1')?.classList.add('active');
      //   document.getElementById('target_step2')?.classList.add('active');
      //   document.getElementById('target_step3')?.classList.add('active');
      //   document.getElementById('target_step4')?.classList.add('active');
      //   document.getElementById('target_step5')?.classList.add('active');
      //   ref5?.current?.focus();
      //   break;
      case 4:
        submitInquiry();
        break;
      default:
        break;
    }
  };

  const validatePhoneNumber = () => {
    const allowedStartingDigits = ['3', '4', '5', '6', '7', '8'];
    if (phoneNumber.length !== 8) {
      setPhoneNumberError(trans.phone_at_least_8);
      return false;
    }
    if (!allowedStartingDigits.includes(phoneNumber[0])) {
      setPhoneNumberError(trans.phone_should_be_valid);
      return false;
    }
    setPhoneNumberError(null);
    return true;
  };

  const submitInquiry = () => {
    if (
      firstName.trim() === '' ||
      lastName === '' ||
      email === '' ||
      !validatePhoneNumber()
      // ----------------- Will be used in the future -----------------
      // !from ||
      // !to ||
      // !pickUpLocation ||
      // !dropOffLocation
    )
      return;

    setShowPageLoader(true);
    // ----------------- Will be used in the future -----------------
    // let selectedEndDate = new Date(to);
    // const selectedStartDate = new Date(from);

    // const formatDate = date => {
    //   const day = date.getDate().toString().padStart(2, '0');
    //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //   const year = date.getFullYear().toString();

    //   return `${year}-${month}-${day}`;
    // };

    // const formattedDates = {
    //   from: formatDate(selectedStartDate),
    //   to: formatDate(selectedEndDate),
    // };

    let form = {
      name: firstName,
      email: email,
      mobile_number: `+974${phoneNumber}`,
      rental_car_id: carDetails?.id,
      // ----------------- Will be used in the future -----------------
      // from: formattedDates.from,
      // to: formattedDates.to,
      // pick_up_location: pickUpLocation,
      // drop_off_location: dropOffLocation,
    };

    postRentCarInquiry(form as any)
      .then(response => {
        if (response.error) {
          setShowPageLoader(false);
          console.error('Submission error:', response.error);
          return;
        }

        setShowPageLoader(false);

        var activeSteps = document.getElementsByClassName('active');
        if (activeSteps && activeSteps.length > 0) {
          Array.from(activeSteps).forEach((activeStep: any) => {
            activeStep.classList.remove('active');
          });
        }

        if (location.pathname.includes('featured'))
          navigate(`../${lang}/featured/thank-you`);
        if (location.pathname.includes('rent-cars'))
          navigate(`../${lang}/new/thank-you`);
      })
      .catch(error => {
        setShowPageLoader(false);
        console.error('Submission error:', error);
      });
  };

  return (
    <>
      <div
        className="modal steps-wizard-modal fade show deals-form-modal"
        id="exampleModalCenter"
        style={{}}
      >
        {showPageLoader && <PageLoader />}
        <div
          className="steps-wizard-modal-dialog steps-wizard-modal-dialog-centered"
          role="document"
        >
          <div className="steps-wizard-modal-content">
            <button
              type="button"
              className="close close-steps-wizard-modal"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.onCloseRentForm()}
            >
              <span aria-hidden="true">×</span>
            </button>
            <form className="buttonnew">
              <input
                type="hidden"
                name="_token"
                value="lnx7rIxp88K1DwGh3yyoY448TiIX9jYNAWbo3Qcw"
              />
              <input
                type="hidden"
                name="featured_car_id"
                id="carid"
                value="21"
              />
              <div className="getdeal-popup">
                <div className="step-app" id="wizard-modal">
                  <div className="step-content vertical-center">
                    <div className="step-tab-panel active" id="step1">
                      <div className="steps-wizard-form">
                        <h2 className="steps-title">{trans.hello}!</h2>
                        <div className="form-group">
                          <label htmlFor="firstname">
                            {parse(trans.lets_start_with_your_full_name)}. *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            onChange={e => setFullName(e.target.value)}
                            placeholder={trans.type_your_answer_here}
                            ref={ref1}
                            onKeyDown={event =>
                              event.key === 'Enter' &&
                              firstName.trim() !== '' &&
                              goToStep(2)
                            }
                          />
                        </div>
                        <button
                          type="button"
                          id="next_step1"
                          className="btn btn-primary next-btn"
                          disabled={firstName.trim() === ''}
                          onClick={() => goToStep(2)}
                        >
                          {trans.next}
                        </button>
                      </div>
                    </div>
                    <div className="step-tab-panel" id="step2">
                      <div className="steps-wizard-form">
                        <div className="form-group">
                          <label htmlFor="emailaddress">
                            {parse(trans.what_is_your_email_address)} *
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                            placeholder={trans.type_your_answer_here}
                            ref={ref2}
                            onKeyDown={event =>
                              event.key === 'Enter' &&
                              email.trim() !== '' &&
                              email.match(isValidEmail) &&
                              goToStep(3)
                            }
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary next-btn"
                          disabled={
                            email.trim() === '' || !email.match(isValidEmail)
                          }
                          onClick={() => goToStep(3)}
                        >
                          {trans.next}
                        </button>
                      </div>
                    </div>
                    <div className="step-tab-panel" id="step3">
                      <div className="steps-wizard-form">
                        <div
                          className="form-group"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <label htmlFor="phonenumber">
                            {parse(trans.and_your_phone_number)} *
                          </label>
                          <div
                            className="d-flex flex-column justify-content-bottom align-items-start"
                            style={{ rowGap: '5px' }}
                          >
                            <div
                              dir="ltr"
                              className="d-flex justify-content-center align-items-top"
                              style={{ gap: '20px' }}
                            >
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ gap: '5px' }}
                              >
                                <img
                                  src="/images/language1.png"
                                  alt="Qatar Flag"
                                />
                                <span style={{ fontSize: '29px' }}>+974:</span>
                              </div>
                              <input
                                type="number"
                                min="0"
                                onKeyDown={evt => {
                                  if (['e', 'E'].includes(evt.key))
                                    evt.preventDefault();
                                  if (
                                    evt.key === 'Enter' &&
                                    phoneNumber.trim().length >= 8
                                  )
                                    goToStep(4);
                                }}
                                className="form-control"
                                value={phoneNumber}
                                onChange={handlePhoneNumberInput}
                                placeholder={trans.type_your_answer_here}
                                ref={ref3}
                              />
                            </div>
                            <small
                              className={`align-self-${
                                lang === 'ar' ? 'end' : 'start'
                              }`}
                              style={{ color: '#f00', minHeight: '20px' }}
                            >
                              {phoneNumberError}
                            </small>
                          </div>
                          <input
                            type="hidden"
                            name="phone_code"
                            id="phone_code"
                            value="+974"
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary next-btn"
                          disabled={phoneNumber.trim().length < 8}
                          onClick={submitInquiry}
                        >
                          {trans.submit_form}
                        </button>
                      </div>
                    </div>
                    {/* ----------------- Will be used in the future ----------------- */}
                    {/* <div className="step-tab-panel" id="step4">
                      <div className="steps-wizard-form">
                        <div className="form-group">
                          <label htmlFor="fromDate">
                            {parse(trans.select_rent_date)}. *
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div
                              style={{
                                display: 'flex',
                                gap: '20px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <span>{trans.start}</span>
                                <MobileDatePicker
                                  onOpen={() => setOpenStartDate(true)}
                                  open={openStartDate}
                                  value={from}
                                  onChange={handleStartDateChange}
                                  disablePast
                                  onClose={handleOutsideClick}
                                />
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <span>{trans.end}</span>
                                <MobileDatePicker
                                  onOpen={() => setOpenEndDate(true)}
                                  open={openEndDate}
                                  value={to}
                                  onChange={(date: any) => {
                                    handleEndDateChange(date);
                                  }}
                                  disabled={!from}
                                  disablePast
                                  shouldDisableDate={(date: any) => {
                                    const today = new Date();
                                    const selectedStartDate = new Date(from);
                                    return (
                                      (date as any) < today ||
                                      (date as any) <= selectedStartDate
                                    );
                                  }}
                                  onClose={handleOutsideClick}
                                />
                              </div>
                            </div>
                          </LocalizationProvider>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary next-btn"
                          disabled={!from || !to}
                          onClick={() => goToStep(5)}
                        >
                          {trans.next}
                        </button>
                      </div>
                    </div>

                    <div className="step-tab-panel" id="step5">
                      <div className="steps-wizard-form">
                        <div className="form-group">
                          <label htmlFor="pickUpLocation">
                            {parse(trans.select_location)}. *
                          </label>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              gap: '20px',
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <select
                                className="form-control"
                                value={pickUpLocation || ''}
                                onChange={e => {
                                  setPickUpLocation(Number(e.target.value));
                                  setDropOffLocation(Number(e.target.value));
                                }}
                                style={{
                                  fontFamily: 'inherit',
                                  fontSize: '20px',
                                }}
                              >
                                <option disabled value="" hidden>
                                  {trans.pick_up_location}
                                </option>
                                {rentalsLocation ? (
                                  rentalsLocation.map((dealer, index) => (
                                    <option
                                      key={String(dealer.id) + index}
                                      value={dealer.id}
                                    >
                                      {dealer.address}
                                    </option>
                                  ))
                                ) : (
                                  <option value="">No locations found</option>
                                )}
                              </select>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <select
                                className="form-control"
                                value={dropOffLocation ?? ''}
                                onChange={e =>
                                  setDropOffLocation(Number(e.target.value))
                                }
                                style={{
                                  fontFamily: 'inherit',
                                  fontSize: '20px',
                                }}
                              >
                                <option disabled value="" hidden>
                                  {trans.drop_off_location}
                                </option>
                                {dropOffLocation === pickUpLocation && (
                                  <option value={pickUpLocation as any} hidden>
                                    {trans.same_as_pickup_location} (
                                    {rentalsLocation &&
                                      rentalsLocation.find(
                                        dealer => dealer.id === pickUpLocation,
                                      )?.address}
                                    )
                                  </option>
                                )}
                                {rentalsLocation ? (
                                  rentalsLocation.map((dealer, index) => (
                                    <option
                                      key={String(dealer.id) + index}
                                      value={dealer.id}
                                    >
                                      {dealer.address}
                                    </option>
                                  ))
                                ) : (
                                  <option value="">No locations found</option>
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary next-btn"
                          onClick={submitInquiry}
                          disabled={!pickUpLocation || !dropOffLocation}
                        >
                          {trans.submit_form}
                        </button>
                      </div>
                    </div> */}
                  </div>
                  <ul className="step-steps">
                    <li
                      id="target_step1"
                      className="active"
                      onClick={() => goToStep(1)}
                    >
                      1
                    </li>
                    <li id="target_step2" onClick={() => goToStep(2)}>
                      2
                    </li>
                    <li id="target_step3" onClick={() => goToStep(3)}>
                      3
                    </li>
                    {/* ----------------- Will be used in the future ----------------- */}
                    {/* <li id="target_step4" onClick={() => goToStep(4)}>
                      4
                    </li>
                    <li id="target_step5" onClick={() => goToStep(5)}>
                      5
                    </li> */}
                  </ul>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
