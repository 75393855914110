import { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { Link } from 'react-router-dom';
import PageLoader from '../Other/PageLoader/PageLoaderComponent';
import { AccountDeactivationConfirmationModel } from './AccountListings/AccountDeactivationConfirmationModel';
import { getUnreadMessagesCount } from './AccountChats/utils/chatApis';
import {
  useGetNotifications,
  useUserGetFavorites,
  useUserGetMyListings,
  useUserLogout,
} from '../../common/hooks/user.hook';
import { ListingStatus } from '../../common/apis/user.apis';
import './AccountLeftComponent-styles.css';

const allStatuses = [
  ListingStatus.Approved,
  ListingStatus.Pending,
  ListingStatus.Rejected,
];

interface IAccountLeftComponentProps {
  unreadChatMessagesCount?: number;
  setUnreadChatMessagesCount?: React.Dispatch<React.SetStateAction<number>>;
}

const AccountLeftComponent = ({
  unreadChatMessagesCount,
  setUnreadChatMessagesCount,
}: IAccountLeftComponentProps) => {
  const { lang, trans, userData } = useContext(AppContext);
  const [isDeactivationModalvisible, setIsDeactivationModalvisible] =
    useState(false);

  const { data: notifications, isLoading: isLoadingNotifications } =
    useGetNotifications();

  const [unreadCount, setUnreadCount] = useState(0);

  const { data: myListingData, isLoading: isLoadingMyListings } =
    useUserGetMyListings({
      params: {
        status: allStatuses,
        lang: lang,
      },
      enabled: true,
    });

  const {
    data: favourite,
    isLoading: isLoadingFavourite,
    refetch: refetchFavourite,
  } = useUserGetFavorites({
    enabled: true,
  });

  const fetchUnreadChatMessagesCount = useCallback(async () => {
    try {
      if (userData) {
        const response = await getUnreadMessagesCount();
        if (response) {
          setUnreadChatMessagesCount && setUnreadChatMessagesCount(response);
          setUnreadCount(response);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, [userData]);

  useEffect(() => {
    fetchUnreadChatMessagesCount();
    refetchFavourite();
  }, [fetchUnreadChatMessagesCount]);

  const { mutateAsync: logout, isLoading: isLoggingOut } = useUserLogout();

  const singout = async () => {
    await logout();
    localStorage.removeItem('userData');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    window.location.href = `/${lang}`;
  };

  const showDeactivationModal = () => {
    setIsDeactivationModalvisible(true);
  };

  const hideDeactivationModal = () => {
    setIsDeactivationModalvisible(false);
  };

  const isLoading =
    isLoadingNotifications ||
    isLoadingFavourite ||
    isLoadingMyListings ||
    isLoggingOut;

  return (
    <aside className="accounts-aside">
      {isLoading && <PageLoader loadSectionOnly />}
      <div className="user-login">
        <strong className="username">
          {userData?.first_name && userData.first_name}{' '}
          {userData?.last_name && userData.last_name}
        </strong>
      </div>
      <ul className="accounts-nav">
        <li>
          <Link to={`/${lang}/account-details`}>
            <span className="nav-icon">
              <img src={'/images/accounts/aside-icon1.png'} alt="icon" />
            </span>
            <span className="txt">{trans.account}</span>
          </Link>
        </li>
        <li>
          <Link to={`/${lang}/account-notifications`}>
            <span className="nav-icon notifi">
              <span className="count">{notifications?.length}</span>
              <img src={'/images/accounts/aside-icon2.png'} alt="icon" />
            </span>
            <span className="txt">{trans.notifications}</span>
          </Link>
        </li>
        <li>
          <Link to={`/${lang}/account-wishlist`}>
            <span className="nav-icon">
              <span className="count">{favourite?.meta.total}</span>
              <img src={'/images/accounts/aside-icon3.png'} alt="icon" />
            </span>
            <span className="txt">{trans.wishlist}</span>
          </Link>
        </li>
        <li>
          <Link to={`/${lang}/account-chat`}>
            <span className="nav-icon">
              <span className="count">
                {unreadChatMessagesCount ?? unreadCount}
              </span>
              <img src={'/images/accounts/aside-icon4.png'} alt="icon" />
            </span>
            <span className="txt">{trans.chat}</span>
          </Link>
        </li>
        <li>
          <Link to={`/${lang}/account-listings`}>
            <span className="nav-icon">
              <span className="count">{myListingData?.length}</span>
              <img src={'/images/accounts/aside-icon5.png'} alt="icon" />
            </span>
            <span className="txt">{trans.my_listings}</span>
          </Link>
        </li>
      </ul>
      <ul className="accounts-nav log">
        {/* <li className="pl-0">
          <Link to={`/${lang}/account-support`}>
            <span className="txt">{trans.support}</span>
          </Link>
        </li> */}
        <li>
          <div
            className="logout-btn"
            style={{ cursor: 'pointer' }}
            onClick={singout}
          >
            <a>
              <span className="nav-icon">
                <img src={'/images/accounts/aside-icon7.png'} alt="icon" />
              </span>
              <span className="txt">{trans.sign_out}</span>
            </a>
          </div>
          <form action="{{ route('logout') }}" method="POST"></form>
        </li>
        <li>
          <div
            className="logout-btn"
            style={{ cursor: 'pointer' }}
            onClick={showDeactivationModal}
          >
            <a>
              <span className="nav-icon">
                <img src={'/images/accounts/aside-icon7.png'} alt="icon" />
              </span>
              <span className="txt">{trans.deactivate}</span>
            </a>
          </div>

          <form action="{{ route('deactivateme') }}" method="POST"></form>
        </li>
      </ul>
      {isDeactivationModalvisible && (
        <AccountDeactivationConfirmationModel
          hideModal={hideDeactivationModal}
        />
      )}
    </aside>
  );
};

export default AccountLeftComponent;
