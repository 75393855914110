import { useContext, useState } from 'react';
import { IUsedBike } from '../../../types/bikes';
import { AppContext } from '../../../contexts/AppContext';
import { formatPrice } from '../../../helpers/formatHelper';
import { successNotification } from '../../Other/ToastMessage';
import { Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { timeSince } from '../../../Helper';
import { Link } from 'react-router-dom';
import { BikeListingFeatureListSkeletons } from './BikeDetailsSkeletons';
import { BikeDealerLogo } from './BikeLazyImage/BikeDealerLogo';
import '../../Listings/common/ListingDetailsFeaturesList/ListingDetailsFeaturesList-styles.css';
import {
  AutoImgBox,
  SliderSectionStyle,
} from '../../../common/styles/CommonStyles';

type ListingDetailsFeaturesListProps = {
  bike: IUsedBike;
  onChatNow: () => void;
  getDealClick: (bike: IUsedBike) => void;
  isLoading: boolean;
};

export const BikeListingDetailsFeaturesList = ({
  bike,
  getDealClick,
  onChatNow,
  isLoading,
}: ListingDetailsFeaturesListProps) => {
  const { trans, lang, userData } = useContext(AppContext);
  const [showNumber, setShowNumber] = useState(false);
  const shareEmailSubject = 'OneAutoCar - All Deals One Place';
  const appUrls = {
    playStore: 'https://play.google.com/store/apps/details?id=com.oneautocar',
    appStore:
      'https://apps.apple.com/qa/app/one-autocar-all-deals-oneplace/id1629835090',
  };

  const dealerShowroomRedictionLink = () => {
    const dealer = bike.bike_dealer;
    if (dealer) return `/${lang}/dealer/${dealer.slug}`;
    else return '';
  };

  const generateShareMessage = () => {
    if (!bike) return '';

    const message = `${bike.bike_model?.brand.name} ${bike.bike_model?.name}${
      bike.bike_dealer?.name ? `\nDealer: ${bike.bike_dealer.name}` : ''
    }\nMileage: ${bike.mileage}\nPrice: ${
      bike.price
    }\n\nCheck it on the website: ${1}\n\nOr download the app on:\nPlay Store: ${
      appUrls.playStore
    }\n\nApp Store: ${appUrls.appStore}`;
    return message;
  };

  const generateContactMessage = () => {
    if (!bike) return '';
    const message = `Hi, I am interested in this bike. Please let me know if it is still available. \n\n*bike Details:*\n${
      bike.bike_model?.brand.name
    } ${bike.bike_model?.name}${
      bike.mileage ? `\nOdometer: ${bike.mileage} KM` : ''
    }\nPrice: ${formatPrice(bike.price)}\nDealer: ${
      bike.bike_dealer?.name
    }\nReference: ${bike.id}\n\n ${window.location.href}`;
    return message;
  };

  const handleShowNumber = () => {
    if (!bike.mobile_number) return;
    showNumber
      ? handleCall()
      : successNotification(
          trans.number_copied_to_clipboard,
          'info',
          '',
          lang === 'en' ? 'top-right' : 'top-left',
        );
    navigator.clipboard.writeText(bike.mobile_number);
    setShowNumber(true);
  };

  const handleCall = () => {
    if (!bike.mobile_number) return;
    if (bike.mobile_number.includes('974')) {
      window.open(`tel:${bike.mobile_number}`, '_blank');
    } else {
      window.open(`tel:${'974' + bike.mobile_number}`, '_blank');
    }
  };

  const handleWhatsapp = () => {
    const contactMessage = generateContactMessage();
    window.open(
      `https://wa.me/${97430663555}?text=${encodeURIComponent(contactMessage)}`,
      '_blank',
    );
  };

  const renderRow = (
    label: string,
    value: string | number | null,
    transform?: (value: string | number) => string,
  ) => {
    return value !== null && value !== '' ? (
      <tr key={label}>
        <td>{label}</td>
        <td>{transform ? transform(value) : value}</td>
      </tr>
    ) : null;
  };

  const tableRows = [
    { label: trans.type, value: bike.bike_type?.name },
    { label: trans.year, value: bike.year },
    { label: trans.car_condition, value: bike.bike_condition?.name },
    {
      label: trans.mileage,
      value: bike.mileage,
      transform: value => (value && value > 0 ? `${value} ${trans.km}` : ''),
    },
    { label: trans.engine_type, value: bike.bike_engine_type?.name },
    { label: trans.engine_size, value: bike.bike_engine_size?.name },
    { label: trans.cylinder, value: bike.bike_cylinder?.name },
    { label: trans.battery_capacity, value: bike.bike_battery_capacity?.name },
    { label: trans.battery_range, value: bike.bike_battery_range?.name },
    { label: trans.transmission, value: bike.bike_transmission?.name },
    { label: trans.color, value: bike.bike__color?.name },
  ];

  if (isLoading) {
    return <BikeListingFeatureListSkeletons trans={trans} />;
  }

  return (
    <section className="deals-detail-info">
      <div className="deals-detail">
        <header className="details-head">
          <div className="box">
            <h1>{bike.bike_model?.brand?.name}</h1>
            <h2>{bike.bike_model?.name}</h2>
            <h1>
              {bike && bike.bike_trim !== null ? bike?.bike_trim?.name : ''}
            </h1>
          </div>
          <div className="box2">
            <strong className="price-qar">
              <span>{formatPrice(bike.price)}</span> <sub>{trans.qar}</sub>
            </strong>
          </div>
          <div className="used-cars-buttons-container">
            <div className="used-cars-buttons">
              {bike?.mobile_number && (
                <Button
                  onClick={handleShowNumber}
                  style={{ color: showNumber ? '#000' : '' }}
                  variant="outlined"
                  startIcon={lang === 'en' ? <PhoneIcon /> : ''}
                  className="show-number-used-car"
                >
                  {showNumber ? bike.mobile_number : trans.show_number}
                </Button>
              )}
              {bike?.bike_dealer && (
                <Button
                  onClick={handleWhatsapp}
                  variant="contained"
                  className="whatsapp-btn-used-car"
                >
                  <WhatsAppIcon />
                  WhatsApp
                </Button>
              )}
            </div>
            <div>
              <Button
                onClick={() => getDealClick(bike)}
                variant="contained"
                className="get-deal-used-car"
              >
                {trans.submit_inquiry}
              </Button>
            </div>
          </div>
        </header>
        <table className="table table-striped details-table">
          <tbody>
            {tableRows.map(row => {
              return renderRow(row.label, row.value || '', row.transform);
            })}
            <tr>
              <td>{bike.is_featured ? trans.dealer : trans.seller}</td>
              <td>
                {bike.bike_dealer &&
                bike.bike_dealer.name &&
                bike.bike_dealer.name !== ''
                  ? bike.bike_dealer.name
                  : trans.private}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>{trans.location}</td>
              <td>{timeSince(bike.created_at, trans)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <SliderSectionStyle className="comments-detail get-deals">
        <div className="comments-holder-detail">
          <div className="comment-header-body-holder">
            <div className="comment-dealear-image-holder">
              <AutoImgBox>
                {bike.bike_dealer && (
                  <BikeDealerLogo
                    src={bike.bike_dealer.logo}
                    alt="Profile"
                    style={{
                      width: '300px',
                      height: '90px',
                      objectFit: 'contain',
                    }}
                  />
                )}
              </AutoImgBox>
            </div>
            <div className="comment-header">
              <div className="comment-header-heading">
                {!bike.bike_dealer && bike.user
                  ? bike.user.first_name + ' ' + bike.user.last_name
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </SliderSectionStyle>
      <div className="buttons-holder">
        <div className="holder">
          <ul className="sharenav">
            <li>
              {bike.bike_dealer && bike.bike_dealer.slug && (
                <Link
                  to={dealerShowroomRedictionLink()}
                  className="btn btn-secondary showroom"
                >
                  {trans.online_showroom}
                </Link>
              )}
              {userData &&
                (!bike.bike_dealer || !bike.bike_dealer.slug) &&
                bike.user_id !== userData.id && (
                  <button
                    onClick={onChatNow}
                    className="loginpopupload btn btn-secondary"
                  >
                    {trans.chat_now}
                  </button>
                )}
            </li>
            <li>
              <a href="javascript:void(0)" className="btn btn-secondary share">
                <i className="fa fa-share-alt"></i>
              </a>
              <div className="reaction-blocks">
                <ul className="comment-facies">
                  <li>
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${1}`}
                    >
                      <img src="/images/ico-fb.png" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`http://twitter.com/share?text=check this car out from ONE AUTOCAR&amp;url=${1}`}
                    >
                      <img src="/images/ico-tw.png" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://wa.me/?app_absent=1&text=${encodeURIComponent(
                        generateShareMessage(),
                      )}`}
                      target="_blank"
                    >
                      <img src="/images/ico-whatsapp.png" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`mailto:?subject=${encodeURIComponent(
                        shareEmailSubject,
                      )}&body=${encodeURIComponent(generateShareMessage())}`}
                    >
                      <img src="/images/ico-email.png" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={generateShareMessage}>
                      <img src="/images/ico-share.png" />
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <Link to={`/${lang}/used-bikes`} className="btn-back">
          <i className="fa fa-chevron-left"></i> {trans.back}
        </Link>
        <span className="refrance">
          {trans.reference_no}{' '}
          <span>{`OAC-${
            bike.bike_dealer_id ? bike.bike_dealer_id + '-' + bike.id : bike.id
          }`}</span>
        </span>
      </div>
    </section>
  );
};
