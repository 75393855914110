import { useContext, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { AppContext } from '../../contexts/AppContext';
import { updateLanguage } from '../../services/ApiService';
import SignIn from '../User/SignIn/SignInComponent';
import DownloadAppQRTooltip from './DownloadAppQRTooltip';
import SearchBar from './HeaderSearchComponent';
import { DownloadAppBanner } from './DownloadAppBanner';
import ReviewsRedirectionButton from './ReviewsRedirectionButton';
import { useSpring, animated } from 'react-spring';
import { SellYourVehicleModal } from '../User/SellYourVehicle/SellYourVehicleModal';
import { ComingSoonComponent } from './ComingSoonComponent';
import '../../styles/css/header-styles.css';

interface HeaderProps {
  showBanner: boolean;
  hideBanner: (showBanner: boolean) => void;
}

export default function Header({ showBanner, hideBanner }: HeaderProps) {
  const { lang, trans, userData } = useContext(AppContext);
  const location = useLocation();
  const [showCollapsedMenu, setShowCollapsedMenu] = useState<boolean>(false);
  const [languageSwitchUrl, setLanguageSwitchUrl] = useState('');
  const [sellCar, setSellCar] = useState(false);

  const languageToSwitch = lang === 'ar' ? 'en' : 'ar';
  const currentPage = window.location.pathname
    .split('/')
    .filter(p => !['en', 'ar'].includes(p))
    .splice(1)
    .join('/');

  const [showSignIn, setShowSignIn] = useState(false);
  const [sellVehicle, setSellVehilce] = useState(false);
  const [showIcon, setShowIcon] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setLanguageSwitchUrl(
      `/${languageToSwitch}${currentPage === '' ? '' : `/${currentPage}`}`,
    );
  }, [languageToSwitch, currentPage]);

  const setActive = (currentElement: any) => {
    if (width <= 1200) setShowCollapsedMenu(!showCollapsedMenu);
    currentElement.target.parentNode.classList.add('active');
  };

  useEffect(() => {
    setShowCollapsedMenu(false);
  }, [location.pathname]);

  const sellYourCarClick = () => {
    if (userData) {
      setSellVehilce(true);
      setSellCar(false);
    } else {
      setSellCar(true);
      setShowSignIn(true);
      setSellVehilce(false);
    }
  };

  const toggleSignInDialog = (show: any) => {
    setShowSignIn(show);
    setSellVehilce(false);
  };

  const isSearchIconVisible = useMemo(() => {
    return width < 1200;
  }, [width]);

  const iconSpring = useSpring({
    opacity: showIcon ? 1 : 0,
    transform: `translateX(${showIcon ? 0 : -200}px)`,
  });

  const inputSpring = useSpring({
    opacity: showIcon ? 0 : 1,
    transform: `translateX(${showIcon ? 200 : 0}px)`,
  });

  const changeLanguageClick = (e: any) => {
    e.preventDefault();
    if (userData?.api_token) {
      let form = {
        lang: languageToSwitch,
      };
      updateLanguage(form).then(response => {
        if (response.status === 'success') {
          if (userData) {
            userData.Language_Preference = languageToSwitch;
            localStorage.setItem('userData', JSON.stringify(userData));
          }

          window.location.href = languageSwitchUrl;
        }
      });
    } else {
      window.location.href = languageSwitchUrl;
    }
  };

  const searchIconClick = () => {
    setShowIcon(!showIcon);
  };

  return (
    <div>
      <header id="header">
        <div className="container">
          <DownloadAppBanner showBanner={showBanner} hideBanner={hideBanner} />
          <div className="header-holder">
            <strong className="logo">
              <Link to={'/' + lang}>
                <animated.img
                  src={`/images/logo.webp`}
                  alt="One Auto Car"
                  style={isSearchIconVisible ? iconSpring : {}}
                  loading={'lazy'}
                />
              </Link>
            </strong>
            <div className="nav-holder">
              <div className="search-info-bar">
                <div className="left-align">
                  <ReviewsRedirectionButton />
                  <DownloadAppQRTooltip
                    textContent={trans.download_app}
                    tooltipTextContent1={trans.scan_qr}
                    tooltipTextContent2={trans.to_download}
                  />
                  <select className="vodiapicker">
                    <option
                      value="en"
                      className="test"
                      data-thumbnail={`/images/language1.png`}
                    >
                      English
                    </option>
                    <option value="au" data-thumbnail={`/images/language2.png`}>
                      العربية
                    </option>
                  </select>
                  <div className="lang-select">
                    <Link
                      to={languageSwitchUrl}
                      onClick={changeLanguageClick}
                      className="lang-button"
                    >
                      <img
                        className="lang-header"
                        src={`/images/language1.png`}
                        alt="Flag"
                      />
                      <span className="lang-header english pl-3">
                        {'العربية'}
                      </span>
                      <span className="lang-header arabic pl-3">
                        {'English'}
                      </span>
                    </Link>
                  </div>
                  {!userData ? (
                    <Link to={`/${lang}/login`} className="login-button">
                      {trans.sign_in}
                    </Link>
                  ) : (
                    <Link
                      to={`/${lang}/account-details`}
                      className="login-button"
                    >
                      {trans.hello} {userData.first_name}
                    </Link>
                  )}
                </div>
              </div>
              <div className="header-separator" />
              <div className="nav-info-bar">
                <Navbar color="light" light expand="xl" sticky="top">
                  <span className="navbar-brand nav-brand-placeholder"></span>
                  <NavbarToggler
                    onClick={() => setShowCollapsedMenu(!showCollapsedMenu)}
                  />
                  <Collapse isOpen={showCollapsedMenu} navbar>
                    <div className="nav-column active">
                      <Nav navbar>
                        <NavItem
                          className={`${
                            window.location.pathname === `/${lang}`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to={`/${lang}`}
                            onClick={setActive}
                          >
                            {trans.home}
                          </Link>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname === `/${lang}/new-cars`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link smooth"
                            to={`/${lang}/new-cars`}
                            onClick={setActive}
                          >
                            {trans.new_cars}
                          </Link>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname === `/${lang}/used-cars`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link smooth"
                            to={`/${lang}/used-cars`}
                            onClick={setActive}
                          >
                            {trans.used_cars}
                          </Link>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname === `/${lang}/new-bikes` ||
                            window.location.pathname === `/${lang}/used-bikes`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link className="nav-link" to="#">
                            {trans.bikes}
                          </Link>
                          <div className="menu-dopdown">
                            <ul className="dopdown-nav">
                              <NavItem
                                className={`${
                                  window.location.pathname ===
                                  `/${lang}/new-bikes`
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                <Link
                                  className="nav-link"
                                  to={`/${lang}/new-bikes`}
                                  onClick={setActive}
                                >
                                  {trans.new_bikes}
                                </Link>
                              </NavItem>
                              <NavItem
                                className={`${
                                  window.location.pathname ===
                                  `/${lang}/used-bikes`
                                    ? 'disabled'
                                    : 'disabled'
                                }`}
                              >
                                <Link
                                  className="nav-link disabled"
                                  to={`/${lang}/used-bikes`}
                                  onClick={setActive}
                                  style={{
                                    color: 'gray',
                                    backgroundColor: 'transparent',
                                    opacity: 0.4,
                                  }}
                                >
                                  {trans.used_bikes}
                                </Link>
                              </NavItem>
                            </ul>
                          </div>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname ===
                              `/${lang}/sell-your-car` ||
                            window.location.pathname ===
                              `/${lang}/sell-your-bike`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <button
                            className="nav-link button-as-link float-left"
                            onClick={sellYourCarClick}
                          >
                            {trans.sell_now}
                          </button>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname === `/${lang}/rent-cars`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link smooth"
                            to={`/${lang}/rent-cars`}
                            onClick={setActive}
                          >
                            {trans.rent}
                          </Link>
                        </NavItem>
                        <NavItem
                          className={`${
                            window.location.pathname === `/${lang}/dealers`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to={`/${lang}/dealers`}
                            onClick={setActive}
                          >
                            {trans.dealers}
                          </Link>
                        </NavItem>
                        {/* <NavItem
                          className={`${
                            window.location.pathname === `/${lang}/financing`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to={`/${lang}/financing`}
                            onClick={setActive}
                          >
                            {trans.financing}
                          </Link>
                        </NavItem> */}
                        {/* <NavItem
                          className={`${
                            window.location.pathname === `/${lang}/insurance`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to={`/${lang}/insurance`}
                            onClick={setActive}
                          >
                            {trans.insurance}
                          </Link>
                        </NavItem> */}
                        <NavItem
                          className={`${
                            window.location.pathname === `/${lang}/articles`
                              ? 'active'
                              : ''
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to={`/${lang}/articles`}
                            onClick={setActive}
                          >
                            {trans.articles}
                          </Link>
                        </NavItem>
                        {width <= 480 && (
                          <NavItem
                            className={`${
                              window.location.pathname === `/${lang}/reviews`
                                ? 'active'
                                : ''
                            }`}
                          >
                            <Link
                              className="nav-link"
                              to={`/${lang}/reviews`}
                              onClick={setActive}
                            >
                              {trans.reviews}
                            </Link>
                          </NavItem>
                        )}
                      </Nav>
                    </div>
                  </Collapse>
                </Navbar>
                <SearchBar
                  inputSpring={inputSpring}
                  isSearchIconVisible={isSearchIconVisible}
                  showIcon={showIcon}
                />
              </div>
              <span
                className="form-opener"
                onClick={searchIconClick}
                style={{
                  cursor: 'pointer',
                }}
              >
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
      </header>
      {showSignIn && (
        <SignIn
          sellCar={sellCar}
          toggleSignInDialog={toggleSignInDialog}
          location={location.pathname}
        />
      )}
      {sellVehicle && (
        <SellYourVehicleModal
          location={location.pathname}
          toggleSignInDialog={toggleSignInDialog}
        />
      )}
    </div>
  );
}
