import { IArticle } from '../../types/articles/article';
import LazyLoad from 'react-lazyload';
import parse from 'html-react-parser';
import { useContext, useMemo } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { format } from 'date-fns';
import { ArticlesCardSkeleton } from '../Articles/components/ArticclesSkeleton/ArticlesSkeleton';
import { ArticlesCardImage } from '../Articles/components';
import {
  DetailColumn,
  DetailColumnH2,
  ImageHolder,
  InfoWrapper,
  TextHolder,
  TextHolderP,
  TitleDate,
} from '../Articles/components/ArticleCard/ArticleCardStyle';
import { AutoImgBox, SponsoredBadge } from '../../common/styles/CommonStyles';

interface IArticleCardProps {
  article?: IArticle;
  isLoading?: boolean;
}

export const ArticleCard = ({ article, isLoading }: IArticleCardProps) => {
  const { lang, trans, darkImageLoadingPlaceholder } = useContext(AppContext);

  const articleDescription = useMemo(() => {
    return article?.description.replace(/<[^>]*>?/gm, '');
  }, [article?.description]);

  if (isLoading || !article) {
    return <ArticlesCardSkeleton />;
  }

  return (
    <div key={Math.random()} className="col-md-6 mb-4">
      <DetailColumn to={`/${lang}/article/${article.slug}`}>
        <ImageHolder>
          <AutoImgBox>
            {article.is_featured && (
              <SponsoredBadge>{trans.sponsored}</SponsoredBadge>
            )}
            <LazyLoad style={{ height: '100%' }}>
              <ArticlesCardImage
                src={article.thumbnail}
                defaultSrc={darkImageLoadingPlaceholder}
                class="lazy"
                alt={`${article.slug}-thumbnail`}
              />
            </LazyLoad>
          </AutoImgBox>
        </ImageHolder>
        <TextHolder>
          <DetailColumnH2>{article.title}</DetailColumnH2>
          {articleDescription && (
            <TextHolderP>{parse(articleDescription)}</TextHolderP>
          )}
          <InfoWrapper>
            <TitleDate>
              {format(new Date(article.created_at), 'MMM d, yyyy')}
            </TitleDate>
          </InfoWrapper>
        </TextHolder>
      </DetailColumn>
    </div>
  );
};
