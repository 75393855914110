import { useContext, useRef, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { sendSupportMessage } from '../../../services/ApiService';
import { successNotification } from '../../Other/ToastMessage';
import AccountLeftComponent from '../AccountLeftComponent';
import '../AccountDetails/header-styles.css';
import './AccountSupport-styles.css';

const AccountSupport = () => {
  const [supportMessage, setSupportMessage] = useState<any>();
  const { userData, trans, lang } = useContext(AppContext);
  const nameRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const sendSupport = async (e: any) => {
    e.preventDefault();
    await sendSupportMessage({
      name: supportMessage.name,
      message: supportMessage.message,
      mobile: userData.mobile,
    });

    setSupportMessage({
      name: '',
      message: '',
    });
    successNotification(
      trans.support_message_sent_successfully,
      'info',
      trans.support_message,
      lang === 'en' ? 'top-right' : 'top-left',
    );
  };

  function handleChange(evt: any) {
    const value = evt.target.value;
    setSupportMessage({
      ...supportMessage,
      [evt.target.name]: value,
    });
  }

  return (
    <main id="main">
      <div className="accounts-container">
        <div className="accounts-frame">
          <AccountLeftComponent />
          <section className="accounts-content">
            <header className="accounts-header">
              <h2>{trans.support}</h2>
            </header>
            <div className="accounts-support">
              <form
                className="row generic-form sell-car-form"
                onSubmit={sendSupport}
              >
                <div className="col-lg-12">
                  <h3 className="contact-title">
                    {trans.get_in_touch_with_us}
                  </h3>
                </div>
                <div className="col-md-12 col-sm-12 form-group">
                  <input
                    ref={nameRef}
                    className="form-control"
                    type="text"
                    placeholder={trans.name}
                    name="name"
                    value={supportMessage?.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-12 col-sm-12 form-group">
                  <textarea
                    className="form-control"
                    placeholder={trans.message}
                    name="message"
                    value={supportMessage?.message}
                    onChange={handleChange}
                    ref={messageRef}
                  />
                </div>
                <div className="col-xl-12 form-group buttons">
                  <button
                    disabled={
                      nameRef.current?.value !== '' &&
                      messageRef.current?.value !== ''
                        ? false
                        : true
                    }
                    type="submit"
                    className="btn btn-primary"
                  >
                    {trans.send}
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default AccountSupport;
