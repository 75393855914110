import { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { AppContext } from '../../../contexts/AppContext';
import PageLoader from '../../Other/PageLoader/PageLoaderComponent';
import AccountLeftComponent from '../AccountLeftComponent';
import Header from './Header';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { useUpdateUserProfile } from '../../../services/hooks/useApiService';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import './header-styles.css';

const AccountDetails = () => {
  const { trans, lang, userData } = useContext(AppContext);
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userAccountData, setUserAccountData] = useState<any>();
  const [userMobile, setUserMobile] = useState<string>(userData.mobile_number);

  useEffect(() => {
    if (!userData) return setUserAccountData(null);
    setUserAccountData(userData);
  }, [userData]);

  function handleChange(evt: any) {
    const value = evt.target.value;
    setUserAccountData({
      ...userAccountData,
      [evt.target.name]: value,
    });
  }

  const { mutateAsync: updateAccountDetails } = useUpdateUserProfile();

  const updateAccount = async () => {
    setIsLoading(true);
    try {
      const request = new FormData();
      request.append('id', userData?.id);
      request.append('first_name', userAccountData?.first_name);
      request.append('last_name', userAccountData?.last_name);
      request.append('email', userAccountData?.email);
      request.append('mobile_number', userMobile);

      const response = await updateAccountDetails(request);
      if (response) {
        localStorage.setItem('accessToken', response.access_token);
        localStorage.setItem('refreshToken', response.refresh_token);
        if (edit && userAccountData.mobile_number !== userMobile) {
          navigate(`/${lang}/verify-phone`);
        } else {
          window.location.href = `/${lang}/account-details`;
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <MetaTag
        href={`/${lang}/account-details`}
        metaKey="accountDetails"
        index={false}
      />
      <main id="main">
        <div className="accounts-container">
          <div className="accounts-frame">
            <AccountLeftComponent />
            <section className="accounts-content">
              <Header
                edit={edit}
                setEdit={setEdit}
                updateAccount={updateAccount}
              />
              {isLoading && <PageLoader loadSectionOnly />}
              <form id="updateAccountForm" onSubmit={updateAccount}>
                <div
                  className={`accounts-details ${edit ? 'hide-content' : ''} `}
                >
                  <div className="accounts-info">
                    <h2>{trans.full_name}</h2>
                    <dl className="cars-info-list">
                      <dt>{trans.first_name1}</dt>
                      <dd>
                        <p>{userAccountData?.first_name}</p>
                        <input
                          className={`form-control ${
                            edit ? 'editing pl-2' : ''
                          }`}
                          name="first_name"
                          type="text"
                          value={`${userAccountData?.first_name}`}
                          onChange={handleChange}
                        />
                      </dd>
                      <dt>{trans.last_name1}</dt>
                      <dd>
                        {userAccountData?.last_name !== null ? (
                          <p>{userAccountData?.last_name}</p>
                        ) : (
                          ''
                        )}
                        <input
                          className={`form-control ${
                            edit ? 'editing pl-2' : ''
                          }`}
                          name="last_name"
                          type="text"
                          value={`${
                            userAccountData?.last_name !== null
                              ? userAccountData?.last_name
                              : ''
                          }`}
                          onChange={handleChange}
                        />
                      </dd>
                    </dl>
                  </div>
                  <div className="accounts-info">
                    <h2>{trans.contact_information}</h2>
                    <dl className="cars-info-list">
                      <dt>{trans.email_address1}</dt>
                      <dd>
                        <p>{userAccountData?.email}</p>
                        <input
                          className={`form-control ${
                            edit ? 'editing pl-2' : ''
                          }`}
                          name="email"
                          type="text"
                          value={`${userAccountData?.email}`}
                          onChange={handleChange}
                        />
                      </dd>
                      <dt>{trans.mobile_number}</dt>
                      <dd>
                        <p className="account-details-phone-number">
                          {userAccountData?.mobile_number
                            ? userAccountData.mobile_number.startsWith('9')
                              ? `+${userAccountData.mobile_number}`
                              : userAccountData.mobile_number
                            : trans.not_available}
                        </p>
                        {edit && (
                          <PhoneInput
                            containerStyle={{ position: 'absolute' }}
                            dropdownStyle={{ position: 'absolute' }}
                            country="qa"
                            inputClass="custom-tel"
                            value={`${userAccountData?.mobile_number}`}
                            onChange={setUserMobile}
                            placeholder=""
                            masks={{ qa: '........' }}
                          />
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default AccountDetails;
