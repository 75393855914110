import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import AccountLeftComponent from '../AccountLeftComponent';
import PageLoader from '../../Other/PageLoader/PageLoaderComponent';
import Card from './Card';
import { useUserGetFavorites } from '../../../common/hooks/user.hook';
import PagingComponent from '../../Other/PagingComponent';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { BrowseBrands } from '../../../common/styles/CommonStyles';
import '../AccountDetails/header-styles.css';
import '../../../styles/css/pagination-styles.css';

const AccountWishList = () => {
  const { lang, trans } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0,
  );
  const [pagesCount, setPagesCount] = useState(0);
  const pageSize = 8;

  const { data, isLoading, refetch } = useUserGetFavorites({
    params: {
      perPage: pageSize,
      page: currentPage + 1,
      lang: lang,
    },
    enabled: true,
  });

  useEffect(() => {
    refetch();
    if (!data) return;
    if (data.meta.lastPage < currentPage) {
      setCurrentPage(data?.meta?.lastPage - 1);
    }
    setPagesCount(data?.meta?.lastPage);
  }, [data]);

  const addPage = useMemo(() => {
    return currentPage + 1 === 1 ? '' : `?page=${currentPage + 1}`;
  }, [currentPage]);

  return (
    <>
      <MetaTag
        metaKey="accountDetails"
        href={`/${lang}/account-wishlist${addPage}`}
      />
      <main id="main">
        <div className="accounts-container">
          <div className="accounts-frame">
            {isLoading && <PageLoader loadSectionOnly />}
            <AccountLeftComponent />
            <section className="accounts-content">
              <header className="accounts-header">
                <h2>{trans.wishlist}</h2>
              </header>
              {!isLoading ? (
                (data?.data.length ?? 0) > 0 ? (
                  <BrowseBrands className="wishlist-section">
                    {data?.data.map((favourite: any) => (
                      <Card key={favourite.car?.id} favourite={favourite} />
                    ))}
                  </BrowseBrands>
                ) : (
                  <div className="empaty-notification">
                    <div className="notification-icon">
                      <img
                        src={'/images/accounts/wishlist-icon.png'}
                        alt="icon"
                      />
                    </div>
                    <h2>{trans.there_are_0_cars_in_your_wishlist}</h2>
                    <Link className="btn btn-secondary" to={`/${lang}`}>
                      {trans.back_to_home_page}
                    </Link>
                  </div>
                )
              ) : null}
              {/* Button to trigger refetch */}
              <div style={{ paddingBottom: '20px' }}>
                <span className="pagination-middle">
                  <PagingComponent
                    pagesCount={pagesCount}
                    pageSize={pageSize}
                    pageChangeHandler={(p: any) => setCurrentPage(p)}
                    currentPage={currentPage}
                  />
                </span>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default AccountWishList;
