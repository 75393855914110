import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import PagingComponent from '../../Other/PagingComponent';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { useGetArticles } from '../hooks/useArticles';
import { ArticlesSkeleton } from '../components/ArticclesSkeleton/ArticlesSkeleton';
import { ArticleCard } from '../../common/ArticleCard';
import {
  ColLg12,
  FeaturedDetailsPage,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  Row,
} from '../../../common/styles/CommonStyles';

export default function Articles() {
  const { lang, trans } = useContext(AppContext);

  const ArticlesPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPage, setArticlesPage] = useState(0);

  const { data: articles, isLoading: showPageLoader } = useGetArticles({
    searchQueryParams: {
      page: `${articlesPage}`,
      perPage: `${ArticlesPerPage}`,
    },
    page: currentPage,
  });

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setArticlesPage(0);
    };
  }, []);

  const addPage = useMemo(() => {
    return currentPage === 1 ? '' : `?page=${currentPage}`;
  }, [currentPage]);

  if (showPageLoader && !articles) {
    return <ArticlesSkeleton trans={trans} />;
  }

  return (
    <>
      <MetaTag metaKey="articles" href={`/${lang}/articles${addPage}`} />
      <main id="main" className="articles">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH1>{trans.articles}</MainHeadH1>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        <FeaturedDetailsPage className="container articles">
          <Row>
            {articles?.data.map(article => (
              <ArticleCard
                key={article.id}
                article={article}
                isLoading={showPageLoader}
              />
            ))}
          </Row>
          {articles && articles?.meta && (
            <span className="pagination-middle">
              <PagingComponent
                total={articles?.meta?.total}
                pagesCount={articles?.meta?.lastPage}
                pageSize={articles?.meta?.perPage}
                pageChangeHandler={(page: number) => {
                  setCurrentPage(page + 1);
                  setArticlesPage(page);
                  navigate(`/${lang}/articles?page=${page}`);
                }}
                currentPage={articlesPage}
              />
            </span>
          )}
        </FeaturedDetailsPage>
      </main>
    </>
  );
}
