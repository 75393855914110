import { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useParams, useSearchParams } from 'react-router-dom';
import { TUsedCarsFilter } from '../../UsedCars/UsedCarsComponent';
import { useDealers } from '../hooks/useDealersApis';
import {
  useBikeBrands,
  useGetBikeTypes,
  useModelsForUsedBikes,
} from '../../../common/hooks/bikes';
import { IDealer } from '../../../types/resources';
import { useGetUsedBikes } from '../../UsedBikes';
import { IModel } from '../../../types/resources/cars';
import { SelectChangeEvent } from '@mui/material';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import PagingComponent from '../../Other/PagingComponent';
import NewBikeDealerImageShowroom from './NewBikeDealerImageShowroom';
import { UsedBikeBrandCardShowroomComponent } from './UsedBikeBrandCardShowroomComponent';
import { UsedBikeBrandFilterShowroomComponent } from './UsedBikeBrandFilterShowroomComponent';
import { UsedBikeDealerShowroomSkeleton } from './DealerCardSkeleton';
import {
  ColLg12,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  MainHeadP,
  Row,
} from '../../../common/styles/CommonStyles';
import { ResultsNotFound } from '../../common';
import '../components/ShowRoomUsedCarsComponent/ShowRoomUsedCars-styles.css';

export type TFilters = {
  brandIds: number[];
  modelIds: number[];
  types: number[];
};

const ShowroomUsedBikesComponent = () => {
  const { lang, trans, darkImageLoadingPlaceholder } = useContext(AppContext);
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [usedCarQueryParams, setUsedCarQueryParams] = useState<
    TUsedCarsFilter | undefined
  >({
    brandIds: searchParams.get('brand')
      ? [Number(searchParams.get('brand'))]
      : undefined,
    modelIds: searchParams.get('model')
      ? [Number(searchParams.get('model'))]
      : undefined,
  });

  const [filters, setFilters] = useState<TFilters>({
    brandIds: searchParams.get('brand')
      ? [Number(searchParams.get('brand'))]
      : [],
    modelIds: searchParams.get('model')
      ? [Number(searchParams.get('model'))]
      : [],
    types: [],
  });

  const { data: dealersData } = useDealers();

  const { data: models, isFetching: isFetchingModels } = useModelsForUsedBikes({
    params: {
      brand: filters?.brandIds,
      type: 'used',
    },
    enabled: !!filters?.brandIds && filters?.brandIds.length > 0,
  });

  const { data: types } = useGetBikeTypes();

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0,
  );

  const selectedDealer = dealersData?.find(
    (dealer: IDealer) => dealer.slug === params.name,
  );

  const dealerId = selectedDealer?.id;

  const { data: brands } = useBikeBrands({
    params: { type: 'used', dealer: dealerId },
    enabled: !!dealerId,
  });

  const { data: dealerUsedCars, isLoading: isLoadingDealerUsedCars } =
    useGetUsedBikes({
      query: {
        ...usedCarQueryParams,
        dealerIds: dealerId ? [dealerId] : undefined,
        sellerType: 'dealer',
        page: currentPage + 1 || 1,
        perPage: pageSize,
      },
      enabled: !!dealerId,
    });

  const availableTypes = useMemo(() => {
    // Get selected models
    const selectedModels = models?.filter(model =>
      filters?.modelIds?.includes(model.id),
    );

    // Get types of selected models
    const types = selectedModels?.map(model => model.types);

    // Get available types
    const availableTypes: IModel['types'] = [];
    types?.forEach(type => {
      type.forEach(t => {
        if (!availableTypes.find(availableType => availableType.id === t.id)) {
          availableTypes.push(t);
        }
      });
    });

    setFilters({
      ...filters,
      types: filters?.types?.filter(typeId =>
        availableTypes.find(type => type.id === typeId),
      ),
    });

    return availableTypes;
  }, [filters?.modelIds]);

  const handleBrandChange = (
    event: SelectChangeEvent<typeof filters.brandIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        brandIds: value,
        modelIds: filters.modelIds?.filter(modelId => {
          const model = models?.find(model => model.id === modelId);
          return model ? value.includes(model.brand_id) : false;
        }),
      });
    }
  };

  const handleModelChange = (
    event: SelectChangeEvent<typeof filters.modelIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        modelIds: value,
      });
    }
  };

  const handleTypeChange = (event: SelectChangeEvent<typeof filters.types>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        types: value,
      });
    }
  };

  const renderBrandValue = (selected: Array<string | number>) => {
    if (!selected || selected.length === 0) {
      return trans.any;
    }

    return selected
      .map(id => {
        const brand = brands?.find(brand => brand.id === id);
        return brand?.name;
      })
      .join(', ');
  };

  const renderModelValue = (selected: Array<string | number>) => {
    if (isFetchingModels) return <p>Loading...</p>;
    if (selected.length === 0) {
      return trans.any;
    }

    const filteredModels = models?.filter(model => selected.includes(model.id));
    return filteredModels?.map(model => model.name).join(', ');
  };

  const typesToRender = useMemo(() => {
    if (availableTypes.length === 0 && !types) return [];

    return availableTypes.length > 0 ? availableTypes : types;
  }, [availableTypes, types]);

  const renderTypeValue = (selected: Array<string | number>) => {
    if (selected.length === 0) {
      return trans.any;
    }

    return selected
      .map(id => {
        const type = typesToRender?.find(type => type.id === id);
        return type?.name;
      })
      .join(', ');
  };

  const handleSearch = () => {
    const filteredFilters = Object.keys(filters).reduce((acc, key) => {
      if (
        !Array.isArray(filters[key as keyof TFilters]) ||
        !filters[key as keyof TFilters].includes(-1)
      ) {
        acc[key] = filters[key as keyof TFilters];
      }

      return acc;
    }, {});
    setUsedCarQueryParams(filteredFilters);
  };

  const handleClear = async () => {
    setFilters({
      brandIds: [],
      modelIds: [],
      types: [],
    });
    setUsedCarQueryParams(undefined);
  };

  const addPage = useMemo(() => {
    return currentPage === 0 ? '' : `?page=${currentPage + 1}`;
  }, [currentPage]);

  if (isLoadingDealerUsedCars || !selectedDealer)
    return (
      <>
        <MetaTag
          metaKey="dealerUsedBikes"
          href={`/${lang}/dealer/${selectedDealer?.slug}/used-bikes${addPage}`}
          params={{ '%NAME%': selectedDealer?.name }}
        />
        <UsedBikeDealerShowroomSkeleton trans={trans} />
      </>
    );

  return (
    <>
      <MetaTag
        metaKey="dealerUsedBikes"
        href={`/${lang}/dealer/${selectedDealer?.slug}/used-bikes${addPage}`}
        params={{ '%NAME%': selectedDealer?.name }}
      />
      <main id="main" className="online_showroom used_cars">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH1>{trans.online_showroom}</MainHeadH1>
                <MainHeadP>{trans.used_bikes}</MainHeadP>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        <div className="container featured-details-page">
          <Row className="featured-logo">
            <div className="col-12">
              {selectedDealer?.logo && (
                <NewBikeDealerImageShowroom
                  src={selectedDealer?.logo}
                  defaultSrc={darkImageLoadingPlaceholder}
                  alt={`${selectedDealer?.slug}-logo`}
                  class="lazy"
                />
              )}
            </div>
          </Row>
          <UsedBikeBrandFilterShowroomComponent
            brands={brands}
            filters={filters}
            handleBrandChange={handleBrandChange}
            handleClear={handleClear}
            handleModelChange={handleModelChange}
            handleSearch={handleSearch}
            handleTypeChange={handleTypeChange}
            models={models}
            renderBrandValue={renderBrandValue}
            renderModelValue={renderModelValue}
            renderTypeValue={renderTypeValue}
            trans={trans}
            typesToRender={typesToRender}
          />
          <UsedBikeBrandCardShowroomComponent
            darkImageLoadingPlaceholder={darkImageLoadingPlaceholder}
            dealerUsedCars={dealerUsedCars}
            lang={lang}
            trans={trans}
            isLoading={isLoadingDealerUsedCars}
          />
          {dealerUsedCars?.data && (
            <span className="pagination-middle">
              <PagingComponent
                total={dealerUsedCars?.meta?.total}
                pagesCount={dealerUsedCars?.meta?.lastPage}
                pageSize={dealerUsedCars?.meta?.perPage}
                pageChangeHandler={(page: number) => {
                  setCurrentPage(page);
                }}
                currentPage={currentPage}
              />
            </span>
          )}
          {dealerUsedCars?.data?.length === 0 && (
            <ResultsNotFound lang={lang} trans={trans} />
          )}
        </div>
      </main>
    </>
  );
};

export default ShowroomUsedBikesComponent;
