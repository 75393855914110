import { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { useParams, useSearchParams } from 'react-router-dom';
import PagingComponent from '../../../Other/PagingComponent';
import LazyImage from '../../../Other/LazyImageComponent';
import { useDealers } from '../../hooks/useDealersApis';
import MetaTag from '../../../../common/meta/MetaTag';
import { metaData } from '../../../../common/meta/metaData';
import { useGetUsedCars } from '../../../UsedCars/hooks';
import { IDealer } from '../../../../types/resources';
import { IModel } from '../../../../types/resources/cars';
import { useBrands } from '../../../../common/hooks/brand.hooks.';
import { useModelsForUsedCars } from '../../../../common/hooks/model.hooks';
import { useGetCarTypes } from '../../../../common/hooks/type.hooks';
import { SelectChangeEvent } from '@mui/material';
import { TUsedCarsFilter } from '../../../UsedCars/UsedCarsComponent';
import {
  ColLg12,
  MainHead,
  MainHeadContainer,
  MainHeadH1,
  MainHeadP,
  Row,
} from '../../../../common/styles/CommonStyles';
import { UsedCarBrandCardShowroomComponent } from '../UsedCarBrandCardShowroomComponent';
import { UsedBikeDealerShowroomSkeleton } from '../DealerCardSkeleton';
import { UsedCarBrandFilterShowroomComponent } from '../UsedCarBrandFilterShowroomComponent';
import { ResultsNotFound } from '../../../common';
import './ShowRoomUsedCars-styles.css';
import NewBikeDealerImageShowroom from '../NewBikeDealerImageShowroom';

export type TFilters = {
  brandIds: number[];
  modelIds: number[];
  types: number[];
};

const ITEM_HEIGHT = 48;
export const MenuProps = {
  PaperProps: {
    sx: {
      color: '#4E4E4E',
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + 8,
      width: 200,
    },
  },
};

export const smallMenuProps = {
  PaperProps: {
    sx: {
      color: '#4E4E4E',
    },
    style: {
      maxHeight: ITEM_HEIGHT * 10 + 8,
      width: 80,
    },
  },
  MenuListProps: {
    style: {
      padding: 0,
    },
    sx: {
      '& li': {
        fontSize: '12px',
      },
    },
  },
};

export const selectStyle = {
  color: '#4E4E4E',
};

export const smallSelectStyle = {
  fontSize: '11px',
  padding: 0,
};

export default function ShowroomUsedCars() {
  const { lang, trans, darkImageLoadingPlaceholder } = useContext(AppContext);
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [usedCarQueryParams, setUsedCarQueryParams] = useState<
    TUsedCarsFilter | undefined
  >({
    brandIds: searchParams.get('brand')
      ? [Number(searchParams.get('brand'))]
      : undefined,
    modelIds: searchParams.get('model')
      ? [Number(searchParams.get('model'))]
      : undefined,
  });

  const [filters, setFilters] = useState<TFilters>({
    brandIds: searchParams.get('brand')
      ? [Number(searchParams.get('brand'))]
      : [],
    modelIds: searchParams.get('model')
      ? [Number(searchParams.get('model'))]
      : [],
    types: [],
  });

  const { data: dealersData } = useDealers();

  const { data: models, isFetching: isFetchingModels } = useModelsForUsedCars({
    brandIds: filters?.brandIds,
  });

  const { data: types } = useGetCarTypes();

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0,
  );

  const selectedDealer = dealersData?.find(
    (dealer: IDealer) => dealer.slug === params.name,
  );

  const dealerId = selectedDealer?.id;

  const { data: brands } = useBrands({
    params: { type: 'used', dealer: dealerId },
    enabled: !!dealerId,
  });

  const { data: dealerUsedCars, isLoading: isLoadingDealerUsedCars } =
    useGetUsedCars({
      query: {
        ...usedCarQueryParams,
        dealerIds: dealerId ? [dealerId] : undefined,
        sellerType: 'dealer',
        page: currentPage + 1 || 1,
        perPage: pageSize,
      },
      enabled: !!dealerId,
    });

  const availableTypes = useMemo(() => {
    // Get selected models
    const selectedModels = models?.filter(model =>
      filters?.modelIds?.includes(model.id),
    );

    // Get types of selected models
    const types = selectedModels?.map(model => model.types);

    // Get available types
    const availableTypes: IModel['types'] = [];
    types?.forEach(type => {
      type.forEach(t => {
        if (!availableTypes.find(availableType => availableType.id === t.id)) {
          availableTypes.push(t);
        }
      });
    });

    setFilters({
      ...filters,
      types: filters?.types?.filter(typeId =>
        availableTypes.find(type => type.id === typeId),
      ),
    });

    return availableTypes;
  }, [filters?.modelIds]);

  const handleBrandChange = (
    event: SelectChangeEvent<typeof filters.brandIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        brandIds: value,
        modelIds: filters.modelIds?.filter(modelId => {
          const model = models?.find(model => model.id === modelId);
          return model ? value.includes(model.brand_id) : false;
        }),
      });
    }
  };

  const handleModelChange = (
    event: SelectChangeEvent<typeof filters.modelIds>,
  ) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        modelIds: value,
      });
    }
  };

  const handleTypeChange = (event: SelectChangeEvent<typeof filters.types>) => {
    const {
      target: { value },
    } = event;

    if (typeof value === 'object') {
      setFilters({
        ...filters,
        types: value,
      });
    }
  };

  const renderBrandValue = (selected: Array<string | number>) => {
    if (!selected || selected.length === 0) {
      return trans.any;
    }

    return selected
      .map(id => {
        const brand = brands?.find(brand => brand.id === id);
        return brand?.name;
      })
      .join(', ');
  };

  const renderModelValue = (selected: Array<string | number>) => {
    if (isFetchingModels) return <p>Loading...</p>;
    if (selected.length === 0) {
      return trans.any;
    }

    const filteredModels = models?.filter(model => selected.includes(model.id));
    return filteredModels?.map(model => model.name).join(', ');
  };

  const typesToRender = useMemo(() => {
    if (availableTypes.length === 0 && !types) return [];

    return availableTypes.length > 0 ? availableTypes : types;
  }, [availableTypes, types]);

  const renderTypeValue = (selected: Array<string | number>) => {
    if (selected.length === 0) {
      return trans.any;
    }

    return selected
      .map(id => {
        const type = typesToRender?.find(type => type.id === id);
        return type?.name;
      })
      .join(', ');
  };

  const handleSearch = () => {
    const filteredFilters = Object.keys(filters).reduce((acc, key) => {
      if (
        !Array.isArray(filters[key as keyof TFilters]) ||
        !filters[key as keyof TFilters].includes(-1)
      ) {
        acc[key] = filters[key as keyof TFilters];
      }

      return acc;
    }, {});
    setUsedCarQueryParams(filteredFilters);
  };

  const handleClear = async () => {
    setFilters({
      brandIds: [],
      modelIds: [],
      types: [],
    });
    setUsedCarQueryParams(undefined);
  };

  const addPage = useMemo(() => {
    return currentPage === 0 ? '' : `?page=${currentPage + 1}`;
  }, [currentPage]);

  if (isLoadingDealerUsedCars || !selectedDealer)
    return (
      <>
        <MetaTag
          metaKey="dealerUsedCars"
          href={`/${lang}/dealer/${selectedDealer?.slug}/used-cars${addPage}`}
          params={{ '%NAME%': selectedDealer?.name }}
        />
        <UsedBikeDealerShowroomSkeleton trans={trans} />
      </>
    );

  return (
    <>
      <MetaTag
        metaKey="dealerUsedCars"
        href={`/${lang}/dealer/${selectedDealer?.slug}/used-cars${addPage}`}
        params={{ '%NAME%': selectedDealer?.name }}
      />
      <main id="main" className="online_showroom used_cars">
        <MainHead>
          <MainHeadContainer className="container">
            <Row>
              <ColLg12>
                <MainHeadH1>{trans.online_showroom}</MainHeadH1>
                <MainHeadP>{trans.used_cars}</MainHeadP>
              </ColLg12>
            </Row>
          </MainHeadContainer>
        </MainHead>
        <div className="container featured-details-page">
          <Row className="featured-logo">
            <div className="col-12">
              {selectedDealer?.logo && (
                <NewBikeDealerImageShowroom
                  src={selectedDealer?.logo}
                  defaultSrc={darkImageLoadingPlaceholder}
                  alt={selectedDealer?.name}
                />
              )}
            </div>
          </Row>
          <UsedCarBrandFilterShowroomComponent
            brands={brands}
            filters={filters}
            handleBrandChange={handleBrandChange}
            handleClear={handleClear}
            handleModelChange={handleModelChange}
            handleSearch={handleSearch}
            handleTypeChange={handleTypeChange}
            models={models}
            renderBrandValue={renderBrandValue}
            renderModelValue={renderModelValue}
            renderTypeValue={renderTypeValue}
            trans={trans}
            typesToRender={typesToRender}
          />
          <UsedCarBrandCardShowroomComponent
            darkImageLoadingPlaceholder={darkImageLoadingPlaceholder}
            dealerUsedCars={dealerUsedCars}
            isLoading={isLoadingDealerUsedCars}
            lang={lang}
            trans={trans}
          />
          {dealerUsedCars?.data && (
            <span className="pagination-middle">
              <PagingComponent
                total={dealerUsedCars?.meta?.total}
                pagesCount={dealerUsedCars?.meta?.lastPage}
                pageSize={dealerUsedCars?.meta?.perPage}
                pageChangeHandler={(page: number) => {
                  setCurrentPage(page);
                }}
                currentPage={currentPage}
              />
            </span>
          )}
          {dealerUsedCars?.data?.length === 0 && (
            <ResultsNotFound lang={lang} trans={trans} />
          )}
        </div>
      </main>
    </>
  );
}
