import { useContext, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUsedBikeById } from '../hooks';
import { useGetUsedDealClick } from '../../UsedCars/hooks';
import MetaTag from '../../../common/meta/MetaTag';
import { metaData } from '../../../common/meta/metaData';
import { UsedBikeInquiryForm } from './UsedBikeInquiryForm';
import { BikeListingDetails } from './BikeListingDetails';
import { BikeListingDetailsSkeleton } from './BikeDetailsSkeletons';
import { EChatConversationType } from '../../../types/chats/chat_conversation';

const UsedBikeDetailComponent = ({ setImageSrc, handleOpen }) => {
  const { lang, userData, trans } = useContext(AppContext);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showDealForm, setShowDealForm] = useState(false);

  const params = useParams();
  const { data: bikeDetails, isLoading } = useGetUsedBikeById(params.id);
  const handleChatNow = async () => {
    if (!userData) {
      setShowModal(true);
    }

    if (userData && bikeDetails && bikeDetails.user_id) {
      navigate(`/${lang}/account-chat/${EChatConversationType.Bike}/new`, {
        state: {
          new: true,
          bike: bikeDetails,
          loggedInUser: userData,
          chat_conversation_type: EChatConversationType.Bike,
        },
      });
    }
  };

  const { getDealClick } = useGetUsedDealClick(setShowDealForm);

  const responsiveFeatured = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    },
  };

  if (isLoading) {
    return (
      <BikeListingDetailsSkeleton
        trans={trans}
        responsiveFeatured={responsiveFeatured}
      />
    );
  }

  return (
    <>
      <MetaTag
        metaKey="usedBikesDetails"
        href={`/${lang}/used-bikes/${bikeDetails?.bike_model?.brand.slug}/${bikeDetails?.bike_model?.slug}/${bikeDetails?.year}/${bikeDetails?.id}`}
        params={{
          '%BRAND%': bikeDetails?.bike_model?.brand?.name,
          '%MODEL%': bikeDetails?.bike_model?.name,
          '%YEAR%': bikeDetails?.year,
        }}
      />
      {bikeDetails && bikeDetails.id && (
        <BikeListingDetails
          bike={bikeDetails}
          onChatStart={handleChatNow}
          signInModalInfo={{
            isVisible: showModal,
            setVisibility: setShowModal,
          }}
          setImageSrc={setImageSrc}
          handleOpen={handleOpen}
          getUsedDealClick={getDealClick}
          isLoading={isLoading}
          responsiveFeatured={responsiveFeatured}
        />
      )}
      {showDealForm && bikeDetails && (
        <UsedBikeInquiryForm
          bikeId={bikeDetails.id}
          closeDealForm={() => setShowDealForm(false)}
        />
      )}
    </>
  );
};

export default UsedBikeDetailComponent;
