import {
  BrowseBrandsSection,
  CarsSliderBlock,
  GridCardsRental,
} from '../../../common/styles/CommonStyles';
import PaginationComponent from '../../Listings/common/PaginationComponent';
import { IUsedCar } from '../../../types/cars/used_car';
import Banner from '../../Banners/BannerComponent';
import { ResultsNotFound } from '../../common';
import PagingComponent from '../../Other/PagingComponent';
import { CarComponentSkeleton } from '../../UsedCars/UsedCarsSkeletons';
import { BikeGridCard } from './BikeGridCard';
import { BikeListCard } from './BikeListCard';
import { IUsedBike } from '../../../types/bikes';
import { FeaturedBikesCarousel } from './FeaturedBikesCarousel';
import '../../UsedCars/components/UsedCarBody-styles.css';

export const UsedBikeBodyComponent = ({
  noResults,
  isLoading,
  usedFeaturedBikesData,
  favoriteBikes,
  handleFavouriteBikeClick,
  isGrid,
  toggleGridView,
  sortBikes,
  currentPage,
  pagesCount,
  pageSize,
  setCurrentPage,
  usedBikesWithBanners,
  generateRedirectionLink,
  lang,
  trans,
  trans1,
  trans2,
  responsiveFeatured,
}: {
  noResults: boolean;
  isLoading: boolean;
  usedFeaturedBikesData: any;
  favoriteBikes: number[] | undefined;
  handleFavouriteBikeClick: (currentElement: any, bikeId: number) => void;
  isGrid: boolean;
  toggleGridView: (isGrid: boolean) => void;
  sortBikes: (sort: string) => void;
  currentPage: number;
  pagesCount: number;
  pageSize: number;
  setCurrentPage: (page: number) => void;
  usedBikesWithBanners: (string | IUsedBike)[];
  generateRedirectionLink: (car: IUsedBike, lang: string) => string;
  lang: string;
  trans: any;
  trans1: any;
  trans2: any;
  responsiveFeatured: any;
}) => {
  if (isLoading) {
    return (
      <CarComponentSkeleton
        trans1={trans1}
        trans2={trans2}
        responsive={responsiveFeatured}
        isGrid={isGrid}
        noResults={noResults}
        toggleGridView={toggleGridView}
        sortCars={sortBikes}
        currentPage={currentPage}
        pagesCount={pagesCount}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
      />
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className="container usedcars">
        <BrowseBrandsSection
          className="searchcar container"
          style={{ marginTop: 10 }}
        >
          <CarsSliderBlock className="cars-slider-block featured mt-4">
            <FeaturedBikesCarousel
              featuredBikes={usedFeaturedBikesData?.data || []}
              favorite={favoriteBikes || []}
              onFavouriteClick={handleFavouriteBikeClick}
              isLoading={isLoading}
            />
          </CarsSliderBlock>
          <PaginationComponent
            trans1={trans1}
            trans2={trans2}
            noResultsFound={noResults}
            isGrid={isGrid}
            setIsGrid={toggleGridView}
            onSort={sortBikes}
            currentPage={currentPage}
            totalPages={pagesCount}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
          />
          <div
            id="used_cars_for_sale_lisit"
            className={`search-details usedcars-rows ${
              isGrid && 'd-flex flex-wrap justify-content-start'
            }`}
            style={{ ...(isGrid && { gap: '15px' }) }}
          >
            {usedBikesWithBanners &&
              usedBikesWithBanners.length > 0 &&
              usedBikesWithBanners.map((usedBike: string | IUsedCar) => {
                return typeof usedBike === 'string' ? (
                  <>
                    <div
                      key={Math.random()}
                      style={{ flexBasis: '100%', height: 0 }}
                    />
                    <Banner
                      key={Math.random()}
                      section_name="C2"
                      className="default-image-banner C2-margin"
                    />
                    <div
                      key={Math.random()}
                      style={{ flexBasis: '100%', height: 0 }}
                    />
                  </>
                ) : isGrid ? (
                  <GridCardsRental key={usedBike.id}>
                    <BikeGridCard
                      bike={usedBike}
                      redirectionLink={generateRedirectionLink(usedBike, lang)}
                      isFavorite={
                        favoriteBikes! &&
                        favoriteBikes.find(
                          (id: number) => id === usedBike.id,
                        ) != undefined
                      }
                      onFavoriteClick={handleFavouriteBikeClick}
                      isLoading={isLoading}
                    />
                  </GridCardsRental>
                ) : (
                  <BikeListCard
                    key={usedBike.id}
                    redirectionLink={generateRedirectionLink(usedBike, lang)}
                    usedBike={usedBike}
                    onFavoriteClick={handleFavouriteBikeClick}
                    isFavourite={
                      favoriteBikes! &&
                      favoriteBikes.find(f => f === usedBike.id) != undefined
                    }
                    isLoading={isLoading}
                  />
                );
              })}
          </div>
          {usedBikesWithBanners?.length > 0 && (
            <span className="pagination-middle">
              <PagingComponent
                pagesCount={pagesCount}
                pageSize={pageSize}
                pageChangeHandler={(p: any) => setCurrentPage(p)}
                currentPage={currentPage}
              />
            </span>
          )}
          {noResults ? <ResultsNotFound lang={lang} trans={trans} /> : null}
        </BrowseBrandsSection>
      </div>
    </div>
  );
};
