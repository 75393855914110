import { useContext, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import SignIn from '../User/SignIn/SignInComponent';
import ReviewsRedirectionButton from './ReviewsRedirectionButton';
import '../../styles/css/footer-styles.css';

export default function Footer() {
  const { lang, trans, userData } = useContext(AppContext);
  const navigate = useNavigate();
  const [showSignIn, setShowSignIn] = useState(false);
  const location = useLocation().pathname;

  const sellYourCarClick = (e: any) => {
    if (userData) {
      navigate(`${lang}/sell-your-car`);
    } else setShowSignIn(true);
  };

  const sellYourBikeClick = (e: any) => {
    if (userData) {
      navigate(`${lang}/sell-your-bike`);
    } else setShowSignIn(true);
  };

  const toggleSignInDialog = (show: any) => {
    setShowSignIn(show);
  };

  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="row footer-holder">
            <div className="col-sm-2 mb-4 mb-sm-0">
              <strong className="footer-logo">
                <a href={`/${lang}`}>
                  <img src={`/images/footer-logo.png`} alt="one auto car" />
                </a>
              </strong>
            </div>
            <div className="col-sm-10 col-xl-6">
              <div className="row">
                <div className="col-sm-4 mb-4 mb-sm-0">
                  <h3>{trans.overview}</h3>
                  <div className="row">
                    <div className="col-sm-6 mb-4 mb-sm-0 col-6">
                      <ul className="footer-links">
                        <li className="footer-overview-headings">
                          <Link to={`/${lang}/new-cars`}>{trans.new_cars}</Link>
                        </li>
                        <li className="footer-overview-headings">
                          <Link to={`/${lang}/new-bikes`}>
                            {trans.new_bikes}
                          </Link>
                        </li>
                        <li className="footer-overview-headings">
                          <Link to={`/${lang}/used-cars`}>
                            {trans.used_cars}
                          </Link>
                        </li>
                        <li className="footer-overview-headings">
                          <Link
                            to={`/${lang}/used-bikes`}
                            style={{ cursor: 'not-allowed', color: 'gray' }}
                          >
                            {trans.used_bikes}
                          </Link>
                        </li>
                        <li className="footer-overview-headings">
                          <button
                            className="footer-button-as-link"
                            onClick={sellYourCarClick}
                          >
                            {trans.sell_car}
                          </button>
                        </li>
                        <li className="footer-overview-headings">
                          <button
                            className="footer-button-as-link"
                            onClick={sellYourBikeClick}
                            disabled
                            style={{ cursor: 'not-allowed', color: 'gray' }}
                          >
                            {trans.sellYourBike}
                          </button>
                        </li>
                        <li className="footer-overview-headings">
                          <Link to={`/${lang}/articles`}>{trans.articles}</Link>
                        </li>
                        <li className="footer-overview-headings">
                          <Link to={`/${lang}/why-we-rock`}>
                            {trans.why_we_rock}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-4 mb-4 mb-sm-0 col-6"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <ReviewsRedirectionButton />
                  <div>
                    <h3
                      style={{
                        margin: '0 0 15px',
                      }}
                    >
                      {trans.contact_us}
                    </h3>
                    <ul className="footer-links">
                      <li>
                        <a href="mailto:support@oneautocar">
                          support@oneautocar
                        </a>
                      </li>
                      <li>
                        <Link to={''}>{trans.feedback}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4 col-6">
                  <h3>{trans.advertise}</h3>
                  <ul className="footer-links">
                    <li>
                      <a href="mailto:info@oneautocar.com">
                        info@oneautocar.com
                      </a>
                    </li>
                    <li>
                      <a dir="ltr" href="tel:+97430663555">
                        +974 3066 3555
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 mt-4 mt-xl-0">
              <ul className="btn-list">
                <li>
                  <a
                    href="https://apps.apple.com/qa/app/one-autocar-all-deals-oneplace/id1629835090"
                    target="_blank"
                  >
                    <img src={`/images/img20.png`} alt="img" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.oneautocar"
                    target="_blank"
                  >
                    <img src={`/images/img21.png`} alt="img" />
                  </a>
                </li>
              </ul>
              <ul className="social-icons">
                <li>
                  <a href="https://www.facebook.com/ONEAUTOCAR-101537961476913/">
                    <i className="fa fa-facebook" aria-label="facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/one.autocar/?hl=en">
                    <i className="fa fa-instagram" aria-label="instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCb2a5kSSkE4qoqo77Go08jg/featured">
                    <i className="fa fa-youtube" aria-label="youtube"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/oneautocar">
                    <i className="fa fa-twitter" aria-label="twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@oneautocar">
                    <img src={`/images/tiktok.svg`} width="12" alt="img" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ul className="bottom-links">
          <li>
            <Link to={`/${lang}/privacy-policy`}>{trans.privacy}</Link>
          </li>
          <li>
            <Link to={`/${lang}/terms-conditions`}>{trans.terms}</Link>
          </li>
        </ul>
      </footer>
      {showSignIn && (
        <SignIn toggleSignInDialog={toggleSignInDialog} location={location} />
      )}
    </>
  );
}
