import { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { timeSince } from '../../../../Helper';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { successNotification } from '../../../Other/ToastMessage';
import { formatPrice } from '../../../../helpers/formatHelper';
import { IUsedCar } from '../../../../types/cars/used_car';
import { BikeListingFeatureListSkeletons } from '../../../UsedBikes/components/BikeDetailsSkeletons';
import { BikeDealerLogo } from '../../../UsedBikes/components/BikeLazyImage/BikeDealerLogo';
import './ListingDetailsFeaturesList-styles.css';
import {
  AutoImgBox,
  SliderSectionStyle,
} from '../../../../common/styles/CommonStyles';
import { useUsedCarsPageClick } from '../../../UsedCars/hooks';
import { validateMobileNumber } from '../../../../helpers/validateMobileNumber';

type ListingDetailsFeaturesListProps = {
  car: IUsedCar;
  onChatNow: () => void;
  getDealClick: (car: IUsedCar) => void;
  isLoading: boolean;
};

const ListingDetailsFeaturesList = ({
  car,
  onChatNow,
  getDealClick,
  isLoading,
}: ListingDetailsFeaturesListProps) => {
  const { trans, lang, userData } = useContext(AppContext);
  const [showNumber, setShowNumber] = useState(false);
  const shareEmailSubject = 'OneAutoCar - All Deals One Place';
  const appUrls = {
    playStore: 'https://play.google.com/store/apps/details?id=com.oneautocar',
    appStore:
      'https://apps.apple.com/qa/app/one-autocar-all-deals-oneplace/id1629835090',
  };

  const dealerShowroomRedictionLink = () => {
    const dealer = car.dealer;
    if (dealer) return `/${lang}/dealer/${dealer.slug}`;
    else return '';
  };

  const generateShareMessage = () => {
    if (!car) return '';

    const message = `${car.model?.brand.name} ${car.model?.name}${
      car.dealer?.name ? `\nDealer: ${car.dealer.name}` : ''
    }\nMileage: ${car.mileage}\nPrice: ${
      car.price
    }\n\nCheck it on the website: ${1}\n\nOr download the app on:\nPlay Store: ${
      appUrls.playStore
    }\n\nApp Store: ${appUrls.appStore}`;
    return message;
  };

  const generateContactMessage = () => {
    if (!car) return '';
    const message = `Hi, I am interested in this car. Please let me know if it is still available. \n\n*Car Details:*\n${
      car.model?.brand.name
    } ${car.model?.name}${
      car.mileage ? `\nOdometer: ${car.mileage} KM` : ''
    }\nPrice: ${formatPrice(car.price)}\nDealer: ${
      car.dealer?.name
    }\nReference: ${car.id}\n\n ${window.location.href}`;
    return message;
  };

  const handleShowNumber = () => {
    if (!car.mobile_number) return;
    showNumber
      ? handleCall()
      : successNotification(
          trans.number_copied_to_clipboard,
          'info',
          '',
          lang === 'en' ? 'top-right' : 'top-left',
        );
    navigator.clipboard.writeText(car.mobile_number);
    setShowNumber(true);
  };

  const handleCall = () => {
    if (!car.mobile_number) return;
    if (car.mobile_number.includes('974')) {
      window.open(`tel:${car.mobile_number}`, '_blank');
    } else {
      window.open(`tel:${'974' + car.mobile_number}`, '_blank');
    }
  };

  const handleWhatsapp = () => {
    if (car.dealer) {
      const contactMessage = generateContactMessage();
      window.open(
        `https://wa.me/${97430663555}?text=${encodeURIComponent(
          contactMessage,
        )}`,
        '_blank',
      );
    } else {
      const { mobile_number, whatsapp_number } = car;
      const number = whatsapp_number ? whatsapp_number : mobile_number;
      window.open(`https://wa.me/${number}`, '_blank');
    }
  };

  const showPrivateWhatsapp = useMemo(() => {
    const { mobile_number, whatsapp_number } = car;
    if (whatsapp_number) return true;
    else if (mobile_number) {
      const number = mobile_number.replace(/\s/g, '');
      return validateMobileNumber(number);
    }
    return false;
  }, [car]);

  const renderRow = (
    label: string,
    value: string | number | null,
    transform?: (value: string | number) => string,
  ) => {
    return value !== null && value !== '' ? (
      <tr key={label}>
        <td>{label}</td>
        <td>{transform ? transform(value) : value}</td>
      </tr>
    ) : null;
  };

  const tableRows = [
    { label: trans.type, value: car.type?.name },
    { label: trans.year, value: car.year },
    {
      label: trans.doors,
      value: car.doors,
      transform: value => (value && value > 5 ? '5+' : value),
    },
    { label: trans.car_condition, value: car.condition?.name },
    {
      label: trans.mileage,
      value: car.mileage,
      transform: value => (value && value > 0 ? `${value} ${trans.km}` : ''),
    },
    { label: trans.engine_type, value: car.engine_type?.name },
    { label: trans.engine_size, value: car.engine_size?.name },
    { label: trans.cylinders, value: car.cylinder?.name },
    { label: trans.battery_capacity, value: car.battery_capacity?.name },
    { label: trans.battery_range, value: car.battery_range?.name },
    { label: trans.transmission, value: car.transmission?.name },
    { label: trans.drivetrain, value: car.variant?.name },
    { label: trans.exterior_color, value: car.exterior_color?.name },
    { label: trans.interior_color, value: car.interior_color?.name },
    { label: trans.seat_material, value: car.seat_material?.name },
  ];

  const { mutateAsync: usedCarPageClick } = useUsedCarsPageClick();

  if (isLoading) {
    return <BikeListingFeatureListSkeletons trans={trans} />;
  }

  return (
    <section className="deals-detail-info">
      <div className="deals-detail">
        <header className="details-head">
          <div className="box">
            <h1 style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{car.model?.brand.name}</span>
              <text>{car.model?.name}</text>
              <span>{car && car.trim !== null ? car?.trim?.name : ''}</span>
            </h1>
          </div>
          <div className="box2">
            <strong className="price-qar">
              <span>{formatPrice(car.price)}</span> <sub>{trans.qar}</sub>
            </strong>
          </div>
          <div className="used-cars-buttons-container">
            <div className="used-cars-buttons">
              {car?.mobile_number && (
                <Button
                  onClick={() => {
                    usedCarPageClick({ id: car.id, event: 'phone_click' });
                    handleShowNumber();
                  }}
                  style={{ color: showNumber ? '#000' : '' }}
                  variant="outlined"
                  startIcon={lang === 'en' ? <PhoneIcon /> : ''}
                  className="show-number-used-car"
                >
                  {showNumber ? car.mobile_number : trans.show_number}
                </Button>
              )}
              {(car?.dealer || showPrivateWhatsapp) && (
                <Button
                  onClick={() => {
                    usedCarPageClick({ id: car.id, event: 'whatsapp_click' });
                    handleWhatsapp();
                  }}
                  variant="contained"
                  className="whatsapp-btn-used-car"
                >
                  <WhatsAppIcon />
                  WhatsApp
                </Button>
              )}
            </div>
            {car?.dealer && (
              <div>
                <Button
                  onClick={() => getDealClick(car)}
                  variant="contained"
                  className="get-deal-used-car"
                >
                  {trans.submit_inquiry}
                </Button>
              </div>
            )}
          </div>
        </header>
        <table className="table table-striped details-table">
          <tbody>
            {tableRows.map(row => {
              return renderRow(row.label, row.value || '', row.transform);
            })}
            <tr>
              <td>{car.is_featured ? trans.dealer : trans.seller}</td>
              <td>
                {car.dealer && car.dealer.name && car.dealer.name !== ''
                  ? car.dealer.name
                  : trans.private}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>{trans.location}</td>
              <td>{timeSince(car.created_at, trans)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <SliderSectionStyle className="comments-detail get-deals">
        <div className="comments-holder-detail">
          <div className="comment-header-body-holder">
            {
              <div className="comment-dealear-image-holder">
                <AutoImgBox>
                  {car.dealer && (
                    <BikeDealerLogo
                      src={car.dealer.logo}
                      alt={`${car.dealer.slug}-logo`}
                      style={{
                        width: '300px',
                        height: '90px',
                        objectFit: 'contain',
                      }}
                    />
                  )}
                </AutoImgBox>
              </div>
            }
            <div className="comment-header">
              <div className="comment-header-heading">
                {!car.dealer && car.user
                  ? car.user.first_name + ' ' + car.user.last_name
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </SliderSectionStyle>
      <div className="buttons-holder">
        <div className="holder">
          <ul className="sharenav">
            <li>
              {car.dealer && car.dealer.slug && (
                <Link
                  to={dealerShowroomRedictionLink()}
                  className="btn btn-secondary showroom"
                >
                  {trans.online_showroom}
                </Link>
              )}
              {userData &&
                (!car.dealer || !car.dealer.slug) &&
                car.user_id !== userData.id && (
                  <button
                    onClick={onChatNow}
                    className="loginpopupload btn btn-secondary"
                  >
                    {trans.chat_now}
                  </button>
                )}
            </li>
            <li>
              <a href="javascript:void(0)" className="btn btn-secondary share">
                <i className="fa fa-share-alt"></i>
              </a>
              <div className="reaction-blocks">
                <ul className="comment-facies">
                  <li>
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${1}`}
                    >
                      <img src="/images/ico-fb.png" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`http://twitter.com/share?text=check this car out from ONE AUTOCAR&amp;url=${1}`}
                    >
                      <img src="/images/ico-tw.png" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://wa.me/?app_absent=1&text=${encodeURIComponent(
                        generateShareMessage(),
                      )}`}
                      target="_blank"
                    >
                      <img src="/images/ico-whatsapp.png" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`mailto:?subject=${encodeURIComponent(
                        shareEmailSubject,
                      )}&body=${encodeURIComponent(generateShareMessage())}`}
                    >
                      <img src="/images/ico-email.png" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={generateShareMessage}>
                      <img src="/images/ico-share.png" />
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <Link to={`/${lang}/used-cars`} className="btn-back">
          <i className="fa fa-chevron-left"></i> {trans.back}
        </Link>
        <span className="refrance">
          {trans.reference_no}{' '}
          <span>{`OAC-${
            car.dealer_id ? car.dealer_id + '-' + car.id : car.id
          }`}</span>
        </span>
      </div>
    </section>
  );
};

export default ListingDetailsFeaturesList;
