import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../../contexts/AppContext';
import { IModel } from '../../../../types/resources/cars';
import { NewCarModelCardSkeleton } from '../NewCarsCardsSkeletons';
import { ModelCardImage } from './ModelCardImage';
import {
  AutoImgBox,
  CarInfoColumnLogoImg,
  CarInfoColumnLogoImgImg,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
  BrowseBrandsCarInfoColumn,
  CarInfoColumnImgHolder,
} from '../../../../common/styles/CommonStyles';
import { useModelPageClick, useModelPageView } from '../../hooks';
import { useInView } from 'react-intersection-observer';

interface IModelCardProps {
  model: IModel;
  isLoading?: boolean;
}

export const ModelCard = ({ model, isLoading }: IModelCardProps) => {
  const { lang, darkImageLoadingPlaceholder } = useContext(AppContext);

  const { mutateAsync: modelClick } = useModelPageClick();

  if (isLoading) {
    return <NewCarModelCardSkeleton />;
  }
  return (
    <BrowseBrandsCarInfoColumn key={model.id}>
      <Link
        to={`/${lang}/new-cars/${model.brand.slug}/${model.slug}`}
        onClick={() => modelClick(Number(model.id))}
      >
        <CarInfoColumnImgHolder>
          <AutoImgBox>
            <ModelCardImage
              src={model.cover}
              defaultSrc={darkImageLoadingPlaceholder}
              alt={`${model.brand.slug}-${model.slug}-logo`}
            />
          </AutoImgBox>
        </CarInfoColumnImgHolder>
        <CarInfoColumnTextHolder>
          <CarInfoColumnTextBox>
            <CarInfoColumnLogoImg>
              <CarInfoColumnLogoImgImg
                src={model.brand.original_logo}
                alt={`${model.brand.slug}-${model.slug}-logo`}
              />
            </CarInfoColumnLogoImg>
            <h3>
              <CarInfoColumnTitleCar>{model.brand.name}</CarInfoColumnTitleCar>
              <CarInfoColumnTitleCategory>
                {model.name}
              </CarInfoColumnTitleCategory>
            </h3>
          </CarInfoColumnTextBox>
        </CarInfoColumnTextHolder>
      </Link>
    </BrowseBrandsCarInfoColumn>
  );
};
