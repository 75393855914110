import AccountLeftComponent from '../AccountLeftComponent';
import AccountChatsList from './AccountChatsList/AccountChatsList';
import { useCallback, useContext, useEffect, useState } from 'react';
import { IChatConversation } from '../../../types/chats/chat_conversation';
import { AppContext } from '../../../contexts/AppContext';
import { getChatConversations } from './utils/chatApis';
import '../AccountDetails/header-styles.css';

export default function AccountChats(this: any) {
  const [chatConversations, setChatConversations] = useState<
    IChatConversation[]
  >([]);
  const [isLoadingChatConversations, setIsLoadingChatConversations] =
    useState<boolean>(false);
  const [unreadChatMessagesCount, setUnreadChatMessagesCount] =
    useState<number>(0);

  const { userData } = useContext(AppContext);

  const fetchConversations = useCallback(async () => {
    try {
      if (userData) {
        setIsLoadingChatConversations(true);
        const response = await getChatConversations();
        if (response) {
          setChatConversations(response);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingChatConversations(false);
    }
  }, [userData]);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  return (
    <main id="main">
      <div className="accounts-container">
        <div className="accounts-frame">
          <AccountLeftComponent
            unreadChatMessagesCount={unreadChatMessagesCount}
            setUnreadChatMessagesCount={setUnreadChatMessagesCount}
          />
          <AccountChatsList
            chatConversations={chatConversations}
            isLoadingChatConversations={isLoadingChatConversations}
            fetchConversations={fetchConversations}
            setUnreadChatMessagesCount={setUnreadChatMessagesCount}
          />
        </div>
      </div>
    </main>
  );
}
