import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useDeleteChatConversation } from './hooks/useChats';
import { EChatConversationType } from '../../../types/chats/chat_conversation';
import '../AccountDetails/styles.css';
interface IDeleteChatPopupConfirmationProps {
  toggleDelete: () => void;
  chatConversationId: number;
  chatConversationType: EChatConversationType;
}

const DeleteChatPopupConfirmation = ({
  toggleDelete,
  chatConversationId,
  chatConversationType,
}: IDeleteChatPopupConfirmationProps) => {
  const { lang, trans } = useContext(AppContext);
  const { mutateAsync: deleteChat } = useDeleteChatConversation();
  const handleDeleteChat = async () => {
    const result = await deleteChat({
      chat_conversation_id: chatConversationId,
      chat_conversation_type: chatConversationType,
    });
    if (result) {
      window.location.href = `/${lang}/account-chat`;
    }
  };
  return (
    <div
      className="modal fade modal-center add-new-modal close-modal show"
      id="deletechat"
      role="dialog"
      aria-labelledby="deletechat"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <p>
              {trans.are_you_sure} <br />
              {trans.delete_this_chat}
            </p>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary"
              id="confirm-delete"
              data-id=""
              onClick={handleDeleteChat}
            >
              {trans.confirm}
            </button>
            <button
              type="button"
              className="btn btn-primary cancel"
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggleDelete}
            >
              {trans.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteChatPopupConfirmation;
