import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetReviews } from './hooks/useReview';
import { useSearchParams } from 'react-router-dom';
import MetaTag from '../../common/meta/MetaTag';
import { metaData } from '../../common/meta/metaData';
import { AppContext } from '../../contexts/AppContext';
import {
  ReviewsFilter,
  TReviewsFilters,
} from './components/ReviewsFilter/ReviewsFilter';
import { IGetReviews } from './apis';
import { ReviewsBodyComponent } from './components/ReviewsBodyComponent';
import {
  UsedCarsFilter,
  UsedCarsFilterContainer,
} from '../../common/styles/CommonStyles';

export type TReviewsFilter = Omit<IGetReviews, 'page' | 'perPage'>;

const ReviewsLayout = () => {
  const { lang, trans } = useContext(AppContext);
  const ReviewsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPage, setReviewsPage] = useState(0);

  const [searchParams] = useSearchParams();
  const brandIds = searchParams.get('brand');
  const modelIds = searchParams.get('model');

  const [reviewsQueryParams, setReviewsQueryParams] = useState<
    TReviewsFilter | undefined
  >(
    brandIds && modelIds
      ? {
          brandIds: searchParams.get('brand')
            ? [Number(searchParams.get('brand'))]
            : undefined,
          modelIds: searchParams.get('model')
            ? [Number(searchParams.get('model'))]
            : undefined,
          language: searchParams.get('lang') as string,
        }
      : undefined,
  );

  const [filters, setFilters] = useState<TReviewsFilters>({
    brandIds: searchParams.get('brand')
      ? [Number(searchParams.get('brand'))]
      : [],
    modelIds: searchParams.get('model')
      ? [Number(searchParams.get('model'))]
      : [],
    language: searchParams.get('lang') as string,
  });

  const getFilteredFilters = (filters: TReviewsFilters) => {
    const filteredFilters = Object.keys(filters).reduce((acc, key) => {
      // Check if the value associated with the key is not undefined
      // and if it is not an empty array
      if (
        typeof filters[key as keyof TReviewsFilters] !== 'undefined' &&
        filters[key as keyof TReviewsFilters]?.length > 0
      ) {
        // Add the key-value pair to the accumulator
        acc[key] = filters[key as keyof TReviewsFilters];
      }

      return acc;
    }, {});

    return filteredFilters;
  };

  const { data: reviews, isLoading } = useGetReviews({
    query: {
      ...reviewsQueryParams,
      page: reviewsPage + 1,
      perPage: ReviewsPerPage,
    },
    enabled: !!reviewsQueryParams,
  });

  useEffect(() => {
    setReviewsPage(0);
  }, []);

  useEffect(() => {
    if (filters.language) {
      const filteredFilters = getFilteredFilters(filters);
      setReviewsQueryParams(filteredFilters);
    }
  }, [reviews, filters.language]);

  const addPage = useMemo(() => {
    return currentPage === 1 ? '' : `?page=${currentPage}`;
  }, [currentPage]);

  const noResults = reviews && reviews?.data.length === 0 && !isLoading;

  return (
    <>
      <MetaTag metaKey="reviews" href={`/${lang}/reviews${addPage}`} />
      <main id="main" className="articles">
        <UsedCarsFilterContainer>
          <UsedCarsFilter className="container" style={{ borderTop: 0 }}>
            <ReviewsFilter
              filters={filters}
              getFilteredFilters={getFilteredFilters}
              setFilters={setFilters}
              setReviewsQueryParams={setReviewsQueryParams}
            />
          </UsedCarsFilter>
        </UsedCarsFilterContainer>
        <ReviewsBodyComponent
          lang={lang}
          noResults={noResults}
          reviews={reviews}
          reviewsPage={reviewsPage}
          setCurrentPage={setCurrentPage}
          setReviewsPage={setReviewsPage}
          isLoading={isLoading}
          trans={trans}
        />
      </main>
    </>
  );
};

export default ReviewsLayout;
