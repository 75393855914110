import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import LazyImage from '../../Other/LazyImageComponent';
import { formatPrice } from '../../../helpers/formatHelper';
import { IRentalCar } from '../../../types/cars/rental_car';
import { CarGridCardSkeleton } from '../../UsedCars/UsedCarsSkeletons';
import { CarGridImageCard } from '../../Listings/common/CarGridCard/CarGridImageCard';
import {
  AutoImgBox,
  CarInfoColumn,
  CarInfoColumnBtn,
  CarInfoColumnImgHolder,
  CarInfoColumnLogoImg,
  CarInfoColumnPriceHolder,
  CarInfoColumnPriceHolderPrice,
  CarInfoColumnTextBox,
  CarInfoColumnTextHolder,
  CarInfoColumnTitleCar,
  CarInfoColumnTitleCategory,
  SponsoredBadge,
} from '../../../common/styles/CommonStyles';
import {
  CarInfoColumnDl,
  CarInfoColumnDlDd,
  CarInfoColumnDlDt,
} from '../../UsedCars/components/UsedCarStyles';

type CarGridCardProps = {
  car: IRentalCar;
  isLoading?: boolean;
};

export const RentCarGridCard = ({ car, isLoading }: CarGridCardProps) => {
  const { trans, darkImageLoadingPlaceholder, lang } = useContext(AppContext);
  const redirectionUrl = `/${lang}/rent-cars/${car.model?.brand?.slug}/${car.model?.slug}/${car.year}/${car.id}`;
  if (isLoading) return <CarGridCardSkeleton />;
  return (
    <div key={car.id} className="swiper-slide">
      <div className="slide-holder">
        <Link reloadDocument={false} replace={true} to={redirectionUrl}>
          <CarInfoColumn>
            <CarInfoColumnImgHolder>
              <AutoImgBox>
                {car.is_featured ? (
                  <SponsoredBadge className="badge">
                    {trans.featured}
                  </SponsoredBadge>
                ) : null}
                <CarGridImageCard
                  src={car.cover ? car.cover : darkImageLoadingPlaceholder}
                  defaultSrc={darkImageLoadingPlaceholder}
                  alt={`${car.model?.brand?.name.replace(
                    /\s+/g,
                    '-',
                  )}-${car.model?.name.replace(/\s+/g, '-')}-logo`}
                  width={300}
                  height={200}
                />
              </AutoImgBox>
            </CarInfoColumnImgHolder>
            <CarInfoColumnTextHolder>
              <CarInfoColumnTextBox>
                <CarInfoColumnLogoImg>
                  <LazyImage
                    src={car.rental_dealer?.logo ?? ''}
                    defaultSrc={darkImageLoadingPlaceholder}
                    alt="img"
                    width={40}
                    height={40}
                  />
                </CarInfoColumnLogoImg>
                <CarInfoColumnTitleCar>
                  {car?.model?.brand?.name}
                </CarInfoColumnTitleCar>
                <CarInfoColumnTitleCategory>
                  {car?.model?.name}
                </CarInfoColumnTitleCategory>
                <CarInfoColumnDl>
                  <CarInfoColumnDlDt>{trans.year}:</CarInfoColumnDlDt>
                  <CarInfoColumnDlDd>{car?.year}</CarInfoColumnDlDd>
                  <CarInfoColumnDlDt>{trans.type}:</CarInfoColumnDlDt>
                  <CarInfoColumnDlDd>{car?.type?.name}</CarInfoColumnDlDd>
                  <CarInfoColumnDlDt>{trans.company}:</CarInfoColumnDlDt>
                  <CarInfoColumnDlDd>
                    {car?.rental_dealer?.name}
                  </CarInfoColumnDlDd>
                </CarInfoColumnDl>
              </CarInfoColumnTextBox>
              <CarInfoColumnPriceHolder>
                <CarInfoColumnPriceHolderPrice>
                  <span className="qar">{trans.qar}</span>{' '}
                  <span>{formatPrice(car.price_per_day)}</span>
                </CarInfoColumnPriceHolderPrice>
                <CarInfoColumnBtn
                  className="btn"
                  to={`/${lang}/rent-cars/${car.model?.brand?.slug}/${car.model?.slug}/${car.year}/${car.id}`}
                >
                  {trans.view_car}
                </CarInfoColumnBtn>
              </CarInfoColumnPriceHolder>
            </CarInfoColumnTextHolder>
          </CarInfoColumn>
        </Link>
      </div>
    </div>
  );
};
