import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import AccountLeftComponent from './AccountLeftComponent';
import PageLoader from '../Other/PageLoader/PageLoaderComponent';
import parse from 'html-react-parser';
import { useGetNotifications } from '../../common/hooks/user.hook';
import { markNotificationsAsRead } from '../../common/apis/user.apis';
import notificationsIcon from '../../common/images/aside-icon2.png';
import './AccountDetails/header-styles.css';

const AccountNotifications = () => {
  const { lang, trans } = useContext(AppContext);

  const { data, isLoading } = useGetNotifications();

  useEffect(() => {
    markNotificationsAsRead();
  }, []);

  return (
    <main id="main">
      <div className="accounts-container">
        <div className="accounts-frame">
          {isLoading && <PageLoader />}
          <AccountLeftComponent />
          <section className="accounts-content">
            <header className="accounts-header">
              <h2>{trans.latest_notifications}</h2>
            </header>
            {!isLoading ? (
              data?.length > 0 ? (
                <table className="table table-striped">
                  <tbody>
                    {data?.map((notification: any) => (
                      <tr>
                        <th scope="row">
                          <div className="user-img">
                            <img src={notificationsIcon} alt="icon" />
                          </div>
                        </th>
                        <td dir={lang == 'ar' ? 'ltr' : 'rtl'}>
                          {parse(notification?.detail)}
                        </td>
                        <td className="time">
                          <time>{notification.created_at}</time>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="empaty-notification">
                  <div className="notification-icon">
                    <img src={'/images/accounts/bell-icon.png'} alt="icon" />
                  </div>
                  <h2>{trans.no_notifications}</h2>
                  <Link className="btn btn-secondary" to={`/${lang}`}>
                    {trans.back_to_home_page}
                  </Link>
                </div>
              )
            ) : null}
          </section>
        </div>
      </div>
    </main>
  );
};

export default AccountNotifications;
