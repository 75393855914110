import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { addFavorite, deleteFavorite } from '../../services/ApiService';
import SignIn from '../User/SignIn/SignInComponent';
import { useGetFavoriteUsedCars } from './hooks';
import {
  TOrder,
  TSortBy,
  useHandleFavouriteCarClick,
  useInsertBanners,
  useSortCars,
} from './hooks/useUsedCars';
import MetaTag from '../../common/meta/MetaTag';
import { useGetUsedCars } from './hooks';
import { IGetUsedCars } from './apis/usedCarsApi';
import {
  ListingFilterBar,
  TFilters,
} from '../Listings/common/ListingFiltersBar';
import AdvancedSearch from '../Other/AdvancedSearchComponent';
import { IUsedCar } from '../../types/cars/used_car';
import { generateRedirectionLink } from '../Home/HomeUsedCarsComponent';
import { UsedCarBodyComponent } from './components/UsedCarBodyComponent';
import {
  UsedCarsFilter,
  UsedCarsFilterContainer,
} from '../../common/styles/CommonStyles';
import { UsedCarsContainer } from './components/UsedCarStyles';
import { useBrands } from '../../common/hooks/brand.hooks.';
import { useModelsForUsedCars } from '../../common/hooks/model.hooks';
import { useDealers } from '../Dealers/hooks/useDealersApis';
import { useScrollPosition } from '../Utils/useScrollStickyHandler';
import { appendSearchParams, clearSearchParams } from '../Utils/FilterHandler';

export type TUsedCarsFilter = Omit<IGetUsedCars, 'page' | 'perPage'>;

const UsedCars = () => {
  const responsiveFeatured = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };
  const { lang, trans, userData } = useContext(AppContext);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [usedCars, setUsedCars] = useState<any>([]);
  const [usedCarsWithBanners, setUsedCarsWithBanners] = useState<
    Array<IUsedCar>
  >([]);

  const [isGrid, setIsGrid] = useState(false);

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const [showSignIn, setShowSignIn] = useState(false);
  const pageSize = 16;
  const [pagesCount, setPagesCount] = useState(0);
  const [featuredPagesCount, setFeaturedPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0,
  );
  const [sort, setSort] = useState<{ sortBy: TSortBy; order: TOrder }>();
  const [filtersNumber, setFiltersNumber] = useState<number>(0);
  const brandIds = searchParams.getAll('brand').map(Number);
  const modelIds = searchParams.getAll('model').map(Number);
  const dealerIds = searchParams.getAll('dealer').map(Number);
  const types = searchParams.getAll('type').map(Number);
  const sellerTypes = searchParams.get('sellerType');
  const maxPrices = searchParams.get('maxPrice');
  const minPrices = searchParams.get('minPrice');
  const minMileages = searchParams.get('minMileage');
  const maxMileages = searchParams.get('maxMileage');
  const minYears = searchParams.get('minYear');
  const maxYears = searchParams.get('maxYear');
  const engineType = searchParams.getAll('engineTypes').map(Number);
  const engineSize = searchParams.getAll('engineSizes').map(Number);
  const cylinder = searchParams.getAll('cylinders').map(Number);
  const batteryCapacity = searchParams.getAll('batteryCapacities').map(Number);
  const batteryRange = searchParams.getAll('batteryRanges').map(Number);
  const transmission = searchParams.getAll('transmissions').map(Number);
  const seatMaterial = searchParams.getAll('seatMaterials').map(Number);
  const condition = searchParams.getAll('conditions').map(Number);
  const exteriorColor = searchParams.getAll('exteriorColors').map(Number);
  const interiorColor = searchParams.getAll('interiorColors').map(Number);
  const optionalFeatures = searchParams.getAll('optionalFeatures').map(Number);
  const search = searchParams.get('q');

  const [usedCarQueryParams, setUsedCarQueryParams] = useState<
    TUsedCarsFilter | undefined
  >(
    brandIds ||
      modelIds ||
      types ||
      dealerIds ||
      sellerTypes ||
      maxPrices ||
      minPrices ||
      minMileages ||
      maxMileages ||
      minYears ||
      maxYears ||
      engineType ||
      engineSize ||
      cylinder ||
      batteryCapacity ||
      batteryRange ||
      transmission ||
      seatMaterial ||
      condition ||
      exteriorColor ||
      interiorColor ||
      optionalFeatures ||
      search
      ? {
          brandIds: brandIds,
          modelIds: modelIds,
          types: types,
          dealerIds: dealerIds,
          sellerType: sellerTypes || undefined,
          maxPrice: Number(maxPrices) || undefined,
          minPrice: Number(minPrices) || undefined,
          minMileage: Number(minMileages) || undefined,
          maxMileage: Number(maxMileages) || undefined,
          minYear: Number(minYears) || undefined,
          maxYear: Number(maxYears) || undefined,
          engineTypes: engineType,
          engineSizes: engineSize,
          cylinders: cylinder,
          batteryCapacities: batteryCapacity,
          batteryRanges: batteryRange,
          transmissions: transmission,
          seatMaterials: seatMaterial,
          conditions: condition,
          exteriorColors: exteriorColor,
          interiorColors: interiorColor,
          optionalFeatures: optionalFeatures,
          search: search || undefined,
        }
      : undefined,
  );

  const [filters, setFilters] = useState<TFilters>({
    brandIds: brandIds,
    modelIds: modelIds,
    types: types,
    dealerIds: dealerIds,
    maxPrice: Number(maxPrices) || -1,
    minPrice: Number(minPrices) || -1,
    minMileage: Number(minMileages) || -1,
    maxMileage: Number(maxMileages) || -1,
    minYear: Number(minYears) || -1,
    maxYear: Number(maxYears) || -1,
    optionalFeatures: optionalFeatures || [],
    sellerType: sellerTypes || '',
    engineSizes: engineSize,
    engineTypes: engineType,
    cylinders: cylinder,
    batteryCapacities: batteryCapacity,
    batteryRanges: batteryRange,
    transmissions: transmission,
    seatMaterials: seatMaterial,
    conditions: condition,
    exteriorColors: exteriorColor,
    interiorColors: interiorColor,
  });

  useEffect(() => {
    let count = 0;
    if (!usedCarQueryParams) return setFiltersNumber(0);
    Object.keys(usedCarQueryParams).forEach(key => {
      if (usedCarQueryParams[key] && key !== 'minYear' && key !== 'dealerIds') {
        if (Array.isArray(usedCarQueryParams[key])) {
          if (usedCarQueryParams[key]?.length > 0) {
            count++;
          }
        } else {
          if (usedCarQueryParams[key] !== -1) {
            count++;
          }
        }
      }
    });
    setFiltersNumber(count);
  }, [usedCarQueryParams]);

  const { insertBanners } = useInsertBanners();

  const toggleGridView = (isGrid: boolean) => {
    setIsGrid(isGrid);
  };

  const { data: brands } = useBrands({});

  const { data: models, isFetching: isFetchingModels } = useModelsForUsedCars({
    brandIds: filters.brandIds,
  });

  const { data: dealers } = useDealers();

  const { data: usedCarsData, isFetching: isFetchingUsedCars } = useGetUsedCars(
    {
      query: {
        ...usedCarQueryParams,
        page: currentPage + 1,
        perPage: pageSize,
        is_featured: false,
        sortBy: sort?.sortBy,
        status: 'approved',
        sortDirection: sort?.order,
        search: search || undefined,
      },
      enabled: true,
    },
  );

  const { data: usedFeaturedCarsData, isFetching: isFetchingFeaturedUsedCars } =
    useGetUsedCars({
      query: {
        page: (currentPage % featuredPagesCount) + 1 || 1,
        perPage: 5,
        is_featured: true,
        status: 'approved',
      },
      enabled: true,
    });

  const { data: favoriteCars, isLoading: isLoadingFavoriteCars } =
    useGetFavoriteUsedCars({
      enabled: !!userData,
      userData,
    });

  const toggleSignInDialog = (show: any) => {
    setShowSignIn(show);
  };

  const { sortCars } = useSortCars(setSort);

  const toggleAdvancedSearch = (show: boolean) => {
    setShowAdvancedSearch(show);
  };

  const { handleFavouriteCarClick } = useHandleFavouriteCarClick(
    userData,
    addFavorite,
    deleteFavorite,
    setShowSignIn,
  );

  useEffect(() => {
    if (!usedCarsData) return;
    setUsedCars(usedCarsData.data);
    if (usedCarsData.meta.lastPage < currentPage) {
      setCurrentPage(usedCarsData?.meta?.lastPage - 1);
    }
    setPagesCount(usedCarsData?.meta?.lastPage);
    setShowAdvancedSearch(false);
  }, [usedCarsData]);

  useEffect(() => {
    if (!usedFeaturedCarsData) return;
    setFeaturedPagesCount(usedFeaturedCarsData?.meta.lastPage);
  }, [usedFeaturedCarsData]);

  useEffect(() => {
    if (!isFetchingUsedCars) {
      const interval = isGrid ? 12 : 5;
      setUsedCarsWithBanners(insertBanners(interval, usedCars));
    }
  }, [isGrid, usedCars]);

  const handleReset = () => {
    setFilters({
      brandIds: [],
      modelIds: [],
      types: [],
      maxPrice: -1,
      minPrice: -1,
      minMileage: -1,
      maxMileage: -1,
      minYear: -1,
      maxYear: -1,
      optionalFeatures: [],
      sellerType: '',
    });
    setUsedCarQueryParams({});
    setFiltersNumber(0);
    setSearchParams('');
  };

  const handleSearch = () => {
    const filteredFilters = getFilteredFilters(filters);
    setUsedCarQueryParams(filteredFilters);
    setShowAdvancedSearch(false);
  };

  const getFilteredFilters = (filters: TFilters) => {
    const filteredFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters[key as keyof TFilters] !== -1) {
        acc[key] = filters[key as keyof TFilters];
      }
      return acc;
    }, {} as TFilters);

    // Clear existing search params
    clearSearchParams(searchParams);

    // Append new search params based on filters
    appendSearchParams(searchParams, filteredFilters);

    setSearchParams(searchParams.toString());

    return filteredFilters;
  };

  const addPage = useMemo(() => {
    return currentPage + 1 === 1 ? '' : `?page=${currentPage + 1}`;
  }, [currentPage]);

  const noResults = useMemo(() => {
    return usedCarsWithBanners.length === 0 && !isFetchingUsedCars;
  }, [usedCarsWithBanners]);

  const isLoading = useMemo(() => {
    return (
      isFetchingUsedCars ||
      isFetchingFeaturedUsedCars ||
      isLoadingFavoriteCars ||
      (usedCarsWithBanners.length === 0 && !noResults)
    );
  }, [
    isFetchingUsedCars,
    isFetchingFeaturedUsedCars,
    isLoadingFavoriteCars,
    usedCarsWithBanners,
    noResults,
  ]);

  // change the position of the fitler on scroll
  const top = useScrollPosition();

  return (
    <>
      <MetaTag metaKey="usedCars" href={`/${lang}/used-cars${addPage}`} />
      <UsedCarsContainer>
        <UsedCarsFilterContainer
          style={{
            position: 'fixed',
            zIndex: 999,
            top: top,
          }}
        >
          <UsedCarsFilter className="container">
            <ListingFilterBar
              dealers={dealers}
              models={models}
              isFetchingModels={isFetchingModels}
              brands={brands}
              setShowAdvancedFilter={setShowAdvancedSearch}
              filters={filters}
              setFilters={setFilters}
              getFilteredFilters={getFilteredFilters}
              setUsedCarQueryParams={setUsedCarQueryParams}
              filtersNumber={filtersNumber}
              setFiltersNumber={setFiltersNumber}
            />
          </UsedCarsFilter>
        </UsedCarsFilterContainer>
        <UsedCarBodyComponent
          trans1={trans.used_cars_for_sale}
          trans2={trans.p_of}
          responsiveFeatured={responsiveFeatured}
          currentPage={currentPage}
          favoriteCars={favoriteCars}
          generateRedirectionLink={generateRedirectionLink}
          handleFavouriteCarClick={handleFavouriteCarClick}
          isGrid={isGrid}
          isLoading={isLoading}
          lang={lang}
          noResults={noResults}
          pageSize={pageSize}
          pagesCount={pagesCount}
          setCurrentPage={setCurrentPage}
          sortCars={sortCars}
          toggleGridView={toggleGridView}
          trans={trans}
          usedCarsWithBanners={usedCarsWithBanners}
          usedFeaturedCarsData={usedFeaturedCarsData}
        />
      </UsedCarsContainer>
      {showAdvancedSearch && (
        <AdvancedSearch
          showAdvancedSearch={showAdvancedSearch}
          toggleAdvancedSearch={toggleAdvancedSearch}
          handleSearch={handleSearch}
          filters={filters}
          setFilters={setFilters}
          handleClear={handleReset}
        />
      )}
      {showSignIn && (
        <SignIn
          toggleSignInDialog={toggleSignInDialog}
          showSignIn={showSignIn}
          location={location.pathname}
        />
      )}
    </>
  );
};

export default UsedCars;
