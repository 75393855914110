export default function TransEn() {
  return {
    with: 'WITH',
    free_valuation: 'FREE VALUATION',
    already_account: 'Already have an Account?',
    inquiry_call_heading:
      'You’re one step away <br> from owning your dream car!',
    confirm_email: 'Confirm Email',
    confirm_password: 'Confirm Password',
    username: 'User Name',
    bank_details: 'Company Details',
    sponsored: 'Sponsored',
    deactivate: 'Deactivate Account',
    deactivateAccountConfirmationMessage:
      'Are you sure you want to deactivate your account?',
    more_deals_from: 'More deals from',
    save: 'Save',
    no: 'No',
    publish: 'Publish',
    photos_videos: 'Photos & Videos',
    phone_number: 'Phone Number',
    battery_range: 'Battery Range (km)',
    battery_capacity: 'Battery Capacity (kwh)',
    no_dealers_found: 'No dealers found',
    in_qatar: 'in Qatar',
    list_of_dealers: 'List of Dealers',
    used: 'Used',
    new: 'New',
    choose_a_category: 'Choose a category:',
    online_showroom: 'Online Showroom',
    showroom: 'Showroom',
    w_a_reply: 'Write a reply',
    shop: 'shop',
    deals: 'Deals',
    drive_it: 'Drive it',
    drivetrain: 'Drivetrain',
    buy_it: 'Buy it',
    dream_it1: 'Dream it',
    w_a_message: 'Write a comment',
    location: 'Doha, Al Duhail, Qatar',
    p_of: 'of',
    n_a: 'N/A',
    clear: 'Clear',
    engine_type: 'Engine Type',
    seat_material: 'Seat Material',
    interior_color: 'Interior color',
    exterior_color: 'Exterior Color',
    transmission: 'Transmission',
    cylinders: 'Cylinders',
    engine_size: 'Engine Size (L)',
    car_condition: 'Condition',
    chat_now: 'Chat Now',
    visit_online_showroom: 'Visit Online Showroom',
    back: ' Back',
    reference_no: 'Reference No',
    any: 'Any',
    min: 'Min',
    max: 'Max',
    from: 'From',
    to: 'To',
    newest: 'Newest listing',
    oldest: 'Oldest listing',
    lowp: 'Lowest Price',
    highp: 'Highest Price',
    lowm: 'Lowest Mileage',
    higm: 'Highest Mileage',
    latestm: 'Latest Model Year',
    oldm: 'Oldest Model Year',
    download_app: 'DOWNLOAD APP',
    sell_car: 'SELL YOUR CAR',
    a1_banner_title: 'Ad',
    scan_qr: 'Scan the QR CODE below',
    to_download: 'to directly download our app',
    home: 'HOME',
    cars: 'CARS',
    for: 'for',
    your: 'YOUR',
    used_cars_for_sale: 'Used Cars For Sale',
    new_cars: 'NEW CARS',
    used_cars: 'USED CARS',
    used_cars1: 'USED CARS',
    dealers: 'DEALERS',
    financing: 'FINANCING',
    insurance: 'INSURANCE',
    articles: 'ARTICLES',
    find_car: 'FIND YOUR CAR',
    looking_for: 'What are you looking for?',
    select_brand: 'Select Brand',
    select_model: 'Select Model',
    select_type: 'Select Type',
    select_trim: 'Select Trim',
    search: 'Search',
    advanced_search: 'Advanced Search',
    car_details: 'Car Details',
    watch_this: 'WATCH THIS',
    our_story: 'OUR STORY',
    featured_cars: 'FEATURED CARS',
    featured: 'Featured',
    view_deal: 'View Deal',
    find_car_you_looking: "FIND THE CAR YOU'RE LOOKING FOR",
    dream_it: 'Dream it. Buy it. Drive it.',
    find_it: 'Find It',
    browse_top_brands: 'Browse by Top Brands',
    view_all: 'View All',
    view_all1: 'View All',
    sell_car_with_oneautocar: 'SELL YOUR CAR WITH ONE AUTOCAR',
    free_listing: 'FREE LISTING',
    sell_it: 'Sell it',
    view_all_used_cars: 'View All Used Cars',
    year: 'Year',
    km_driven: 'Km Driven',
    km: 'KM',
    kwh: 'kWh',
    kms: 'KMs',
    dealer: 'Dealer',
    dealer1: 'Dealer',
    seller: 'Seller',
    private: 'Private',
    qar: 'QAR',
    qarPerMonth: 'QAR / month',
    view_car: 'View Car',
    overview: 'OVERVIEW',
    contact_us: 'CONTACT US',
    contact: 'Contact',
    advertise: 'ADVERTISE WITH US',
    feedback: 'Feedback',
    privacy: 'Privacy Policy',
    terms: 'Terms & Conditions',
    browse_cars_by_brands: 'Browse New Cars by Brands',
    browse_bikes_by_brands: 'Browse New Bikes by Brands',
    view_deals: 'View Deals',
    interior_comfort: 'Interior Comfort',
    seamless_connectivity: 'Seamless Connectivity',
    intelligent_mobility: 'Intelligent Mobility',
    '1_year_warranty': '1 year warranty',
    free_registration: 'Free registration',
    downpayment: '0% downpayment',
    litres: 'L',
    automatic: 'Automatic',
    petrol: 'Petrol',
    doors: 'Doors',
    leather: 'Leather',
    brand: 'Brand',
    model: 'Model',
    Car_models: 'Car Models',
    allmodel: 'All Models',
    type: 'Type',
    mileage: 'Mileage',
    price: 'Price',
    bond: 'Bond wherever you go',
    equipped:
      'The all-new nissan x-terra 2021 is designed and equipped for your next family adventure.',
    starting_from: 'Starting from',
    month: 'Month',
    benefits: 'Benefits',
    in_house_financing: 'In house financing',
    roadside_assistance: 'Roadside assistance',
    get_deal: 'Get Deal',
    visit_website: 'Visit Website',
    get_quote: 'Get Quote',
    more_deals: 'More deals from Saleh Al Hamad Al Mana Co',
    saleh: 'Saleh Al Hamad Al Mana Co.',
    visit_now: 'Visit now',
    sorry_msg_show_1:
      'Sorry, we couldn’t find any <br> vehicles for your search.',
    sorry_msg_show_2: 'Please start new search or go back to ',
    sorry_msg_show_3:
      'Sorry, we couldn’t find any <br> reviews for your search.',
    wwr_p1: `Otto arrived on Earth from a galaxy far, far away in search of answers to our struggles. With over 300 years of car sales experience, he devised the concept of creating ONE AUTOCAR!<br/> A revolutionary automotive listing platform that connects car buyers to sellers. He aimed to make his car website an extraordinary automotive listing platform as he is. And so he started developing it with his repertoire of new technologies from the galaxy he arrived from. Armed with extensive advanced AI technology, he built One Autocar.<br/> A platform that exceeds your expectations in every way. Otto's mission is to simplify and improve your buying experience and help you make better car-buying decisions. In addition, car dealers can easily list their new or used vehicles for sale in a few steps.<br/>To this day, Otto maintains a close relationship with his users, ensuring that all cars listed on ONE AUTOCAR are updated and constantly working to improve his site for the ultimate user experience.`,
    wwr_p2:
      'Believe us when we say it, dreams do come true! And Otto is here to make sure your dream turns into a reality by providing you with the best deals out there.<br/>One Autocar offers all users a fast and easy way to buy, sell, finance, and insure their cars, whether they are old or new. Indeed, our platform has got it all! In order to ensure that nobody is left out, in addition to the website, a mobile application has also been developed. The app is available for both iOS and Android devices. What are you waiting for?<br/>Sign up now and become a member of our family!',
    usedcar_search:
      '<span class="txt">Search</span> <span class="for">for</span> <strong>YOUR CAR</strong>',
    brand_details:
      'Discover the best %modelName% deals from the top trusted and authorized %brandName% Dealers in Qatar.\
                    View all of the deals in one place - Compare prices, features, specifications and benefits. \
                    Have easy access to all the information you need that will help you pick the ideal car.\
                    With ONE AUTOCAR, your dream car is one click away. \
                    Click on Get deal, fill in the form and you will be contacted immediately. \
                    All Deals, One Place - ONE AUTOCAR',

    lets_start_with_your_full_name: "Let's start with your <b>full name</b>",
    type_your_answer_here: 'Type your answer here...',
    what_is_your_email_address: 'What is your <b>email address</b>?',
    next: 'Next',
    'done!': 'Done!',
    login: 'Login',
    account: 'Account',
    notifications: 'Notifications',
    wishlist: 'Wishlist',
    chat: 'Chat',
    my_listings: 'My Listings',
    support: 'Support',
    get_in_touch_with_us: 'Get in touch with us',
    name: 'Name',
    message: 'Message',
    other_models: 'Other Models',
    models: 'Models',
    other: 'Other',
    send: 'Send',
    sign_out: 'Sign Out',
    account_details: 'Account Details',
    full_name: 'Full Name',
    first_name: 'First Name',
    last_name: 'Last Name',
    contact_information: 'Contact Information',
    email_address: 'Email Address',
    first_name1: 'First Name',
    last_name1: 'Last Name',
    email_address1: 'Email Address',
    email: 'Email',
    mobile_number: 'Mobile Number',
    whatsapp_number: 'Whatsapp Number',
    show_number: 'Show Number',
    location1: 'Location',
    city: 'City',
    country: 'Country',
    latest_notifications: 'Latest Notifications',
    you_donot_have_any_notifications: 'You don`t have any notifications',
    back_to_home_page: 'Back to Home',
    there_are_0_cars_in_your_wishlist: 'There are 0 cars in your wishlist',
    you_have_0_messages_in_your_inbox: 'You have 0 messages in your inbox',
    view_listing: 'View Listing',
    password: 'Password',
    sign_in: 'Sign In',
    member_since: 'Member since ',
    sign_up: 'Sign Up',
    forgot_password: 'Forgot Password?',
    scan_qr_code_to_login: 'Scan QR code to login',
    'need_an_account?': 'Need an account?',
    'your_information_was_sent_perfectly.':
      'Your information was sent perfectly.',
    accountCreatedSuccessfully: 'Your Account has been created Successfully!',
    back_to_home: 'Back to Home',
    back_to_results: 'Back to Results',
    and_your_last_name: 'And your <b>last name</b>?',
    and_your_phone_number: 'And your <b>phone number</b>?',
    solutions_in_qatar: 'Solutions in Qatar',
    banks_in_qatar: 'Banks in Qatar',
    why_we_rock: 'Why We Rock',
    why_we_rock_img: 'images/why-we-rock.png',
    why_we_rock_app_img: 'images/download-app.png',
    yes: 'Yes',
    listings: 'Listings',
    remove: 'Remove',
    add_listing: 'Add Listing',
    you_have_0_listings: 'You have 0 listings',
    hello: 'Hello',
    no_deals_found: 'No deals found',
    cancel: 'Cancel',
    companies_in_qatar: 'Companies in Qatar',
    list_of_insurance_companies: 'List of Insurance Companies',
    our_aim: 'Our Aim',
    our_app: 'Our APP',
    about_us: 'About Us',
    delete_listings_msg_show_1:
      'Are you sure you want to remove this Listing from One AutoCar?',

    noti_car_2:
      'Your listing <strong> %brand% %model% </strong> (%reference_no%) is pending',

    noti_car_1:
      'Your listing <strong> %brand% %model% </strong> (%reference_no%) has been approved,<a href="%link%">Click here</a>',

    noti_car_3:
      'Your listing <strong> %brand% %model% </strong> (%reference_no%) has been rejected, %reason%',

    noti_car_chat:
      '%name% sent you a message - <strong> %brand% %model% </strong> (%reference_no%)',

    noti_car_comment:
      '%name% has commented on your listing <strong> %brand% %model% </strong> (%reference_no%) <a href="%link%">Click here</a>',
    all_deals: 'All Deals.',
    one_place: 'One Place.',
    arabic: 'Arabic',
    english: 'English',
    en: 'EN',
    ar: 'AR',
    landing_page_top_section_description_1:
      'Whether you want to sell your car or find the right one for you, the One Autocar app is here to facilitate an easy and convenient automotive shopping experience helping you make better choices when it comes to your vehicle.',
    landing_page_top_section_description_2:
      'Download our App from the Appstore or Play Store today.',
    noti_car_reply:
      '%name% has replied on your listing <strong> %brand% %model% </strong> (%reference_no%) <a href="%link%">Click here</a>',
    submit: 'Submit',
    insurance_details: 'Insurance Details',
    by: 'by',
    call_us_on: 'Call us on',
    more_articles: 'MORE ARTICLES',
    error: 'Error',
    page_doesnt_exist: "The page you're looking for doesn't exist",
    sign_in_first: 'Please sign in first',
    year_ago: 'year ago',
    years_ago: 'years ago',
    month_ago: 'month ago',
    months_ago: 'months ago',
    day_ago: 'day ago',
    days_ago: 'days ago',
    hour_ago: 'hour ago',
    hours_ago: 'hours ago',
    minute_ago: 'minute ago',
    minutes_ago: 'minutes ago',
    second_ago: 'second ago',
    seconds_ago: 'seconds ago',
    enter_code: 'Enter the code sent to your phone.',
    resend_code: 'Resend code',
    send_code_confirmation_msg:
      "We'll send you a code to confirm this accounts is yours",
    mobile_or_email: 'Mobile number or email address',
    type_password: 'Type your new password',
    mobile_required: 'Mobile number is required',
    code_required: 'Code is required',
    code_length: 'Code must be 6 characters',
    password_required: 'Password is required',
    password_length: 'Password must be atleast 8 characters',
    confirm_password_required: 'Confirm password is required',
    passwords_no_match: 'Passwords do not match',
    main_dealer: 'Main Dealer',
    approved: 'Approved',
    pending: 'Pending',
    rejected: 'Rejected',
    no_results: 'No results',
    more: 'More',
    less: 'Less',
    email_required: 'Email is required',
    firstname_required: 'First Name is required',
    lastname_required: 'Last Name is required',
    username_required: 'User Name is required',
    confirmemail_required: 'Confirm Email is required',
    confirmpassword_required: 'Confirm Password is required',
    country_required: 'Country is required',
    city_required: 'City is required',
    email_invalid: 'Email is invalid',
    emails_dont_match: 'Emails do not match',
    passwords_dont_match: 'Passwords do not match',
    name_required: 'Name is required',
    brand_required: 'Brand is required',
    model_required: 'Model is required',
    type_required: 'Type is required',
    year_required: 'Year is required',
    transmission_required: 'Transmission is required',
    enginesize_required: 'Engine Size is required',
    cylinders_required: 'Cylinders are required',
    batterycapacity_required: 'Battery Capacity is required',
    batteryrange_required: 'Battery Range is required',
    exteriorcolor_required: 'Exterior Color is required',
    interiorcolor_required: 'Interior Color is required',
    seatmaterial_required: 'Seat Material is required',
    price_required: 'Price is required',
    mileage_required: 'Mileage is required',
    cardetails_required: 'Car Details are required',
    enginetype_required: 'Engine Type is required',
    business_inquiry: 'This is a business inquiry',
    industry: 'Industry',
    attachment: 'Attachment',
    message_required: 'Message is required',
    support_message_sent_successfully: 'Message Sent Successfully',
    support_message: 'Support Message',
    you_have_zero_rejected_cars: 'You have 0 rejected cars',
    you_have_zero_accepted_cars: 'You have 0 accepted cars',
    you_have_zero_pending_cars: 'You have 0 pending cars',
    remove_listing:
      'Are you sure you want to delete his listing from Oneautocar?',
    confirm: 'Yes',
    are_you_sure: 'Are you sure you want to',
    block_this_person: 'block this person?',
    report_this_person: 'report this person?',
    delete_this_chat: 'delete this chat?',
    block: 'block',
    report: 'report',
    delete: 'delete',
    report_sent: 'Report Sent',
    submitted_for_review: 'Submitted to One Autocar for review',
    done: 'done',
    edit: 'Edit',
    redirect: 'Redirect',
    at_least_1_photo_video_required: 'At least 1 photo/video is required',
    at_most_8_photo_video_allowed: 'At most 15 photos/videos are allowed',
    convertible: 'Convertible',
    coupe: 'Coupé',
    hatchback: 'Hatchback',
    suv: 'SUV',
    sedan: 'Sedan',
    description: 'Description',
    not_available: 'Not Available',
    no_notifications: "You don't have any Notifications",
    phone_should_be_valid: 'You should input a valid Phone Number',
    phone_at_least_8: 'Phone number should be at least 8 digits',
    number_copied_to_clipboard: 'Number copied to clipboard',
    no_deals_available: 'No deals available',
    test_drive: 'Test Drive',
    rent: 'Rent',
    rent_now: 'Rent Now',
    rental_cars: 'Cars for Rent',
    seat: 'Seat',
    suitcase: 'Suitcase',
    price_per_day: 'Price per day',
    price_per_week: 'Price per week',
    price_per_month: 'Price per month',
    price_per_year: 'Price per year',
    per_day: 'per day',
    company: 'Company',
    gims_qatar: 'GIMS Qatar',
    large_baggage_capacity: 'Large baggage capacity',
    small_baggage_capacity: 'Small baggage capacity',
    submit_form: 'Submit Form',
    select_rent_date: 'And select <b>Rent Date</b>',
    select_location: 'And select <b>Location</b>',
    pick_up_location: 'Pick up location',
    drop_off_location: 'Drop off location',
    go_to_whatsapp: 'Go to Whatsapp',
    same_as_pickup_location: 'Same as pickup',
    start: 'Start',
    end: 'End',
    no_rentals_available: 'No Rentals Available',
    view_all_rentals: 'View All Rentals',
    rental_cars_title: 'Rental Cars',
    Get_One_Autocar_App: 'Get One Autocar App',
    Download_Now: 'Download Now!',
    mercedes_eqs: 'Mercedes-Benz EQS',
    west_walk_ev: 'WestWalk EV',
    seats: 'Seats',
    any_dealer: 'Any Dealer',
    loading: 'Loading...',
    reviews: 'Reviews',
    more_reviews: 'More Reviews',
    brands: 'Brands',
    yearsMinMax: 'Years min ... max',
    dealers1: 'Dealers',
    car_dealers: 'Car Dealers',
    features: 'Features',
    oneautocar: 'ONE AUTOCAR',
    qatar_custom_show: 'Qatar Custom Show',
    language: 'Language',
    submit_inquiry: 'Submit Inquiry',
    ramadan_offers: 'Ramadan Offers',
    get_offer: 'Get Offer',
    used_bikes: 'Used Bikes',
    used_bikes_for_sale: 'Used Bikes For Sale',
    view_bike: 'View Bike',
    colors: 'Colors',
    color: 'Color',
    cylinder: 'Cylinder',
    sellYourBike: 'SELL YOUR BIKE',
    bike_details: 'Bike Details',
    color_required: 'Color is required',
    bikeDetails_required: 'Bike Details are required',
    sell_your_vehicle: 'SELL YOUR VEHICLE',
    new_bikes: 'New Bikes',
    bikes: 'Bikes',
    sell_now: 'SELL NOW',
    coming_soon: 'Coming Soon',
    first: 'First',
    Scan_your_car_id_or_type_it_manually:
      'Scan your car ID or type it manually',
    Scan_your_car_description:
      'you can scan your ID number, or type it bellow, so we can get your car details faster',
    Type_your_vin_Number: 'Enter your Chassis number/VIN number',
    Wrong_car_id: 'Wrong car id',
    sell_confirm: 'Confirm',
    Or_you_can_select_your_car_details: 'Or you can select your car details',
    invalid_vin_scan_error:
      'We can’t find your car! Check your chassis number.',
    Confirm_car_id_Number: 'Confirm car id Number',
    Upload_Vin_Card: 'Upload Vin Card',
    upload: 'Upload',
    carBrand: 'Car Brand',
    mainDetails: 'Main Details',
    carSpecification: 'Car Specification',
    yourCarDetails: 'Your Car Details',
    yourCarMilage: 'Your Car Mileage (km)',
    carMileage: 'Car Mileage',
    youCarPrice: 'Your Car Price (QAR)',
    car_price: 'Car Price',
    upload_photos: 'Upload Photos',
    addit_info: 'Additional Information',
    addit_info_p:
      'Please type here any other additional information would you tell the buyer about your car',
    priceValuation: "We've estimated the price for you!",
    dropImage: 'Drop your image here, or:',
    browse: 'browse',
    Supports: 'Supports: PNG, JPG, JPEG, WEBP',
  };
}
